import eventNames from "../../actions/events";

const initialState = {
	standardCareChartData: {},
	fetchAllStandardCareChartData: false,
	standardCareChartRankUpdationLoading: false,
	stdCareChartModal: false
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case `${eventNames.GET_ALL_STD_CARE_CHART}_FULFILLED`:
			return {
				...state,
				standardCareChartData: action.payload.data.data,
				fetchAllStandardCareChartData: "FULFILLED"
			};

		case `${eventNames.GET_ALL_STD_CARE_CHART}_REJECTED`:
			return {
				...state,
				fetchAllStandardCareChartData: "REJECTED"
			};

		case `${eventNames.CLEAR_GET_ALL_STD_CARE_CHART}`:
			return {
				...state,
				standardCareChartData: {},
				fetchAllStandardCareChartData: false
			};

		case `${eventNames.STD_CARE_CHART_UPDATE}_FULFILLED`:
			return { ...state, updateStandardCareChartDetails: "FULFILLED" };
		case `${eventNames.STD_CARE_CHART_UPDATE}_REJECTED`:
			return { ...state, updateStandardCareChartDetails: "REJECTED" };
		case eventNames.STD_CARE_CHART_UPDATE_COMPLETED:
			return { ...state, updateStandardCareChartDetails: false };

		case `${eventNames.STD_CARE_CHART_RANK_UPDATE}_PENDING`:
			return { ...state, standardCareChartRankUpdationLoading: true };
		case `${eventNames.STD_CARE_CHART_RANK_UPDATE}_FULFILLED`:
			return { ...state, standardCareChartRankUpdation: "FULFILLED", standardCareChartRankUpdationLoading: false };
		case `${eventNames.STD_CARE_CHART_RANK_UPDATE}_REJECTED`:
			return { ...state, standardCareChartRankUpdation: "REJECTED", standardCareChartRankUpdationLoading: false };
		case eventNames.STD_CARE_CHART_RANK_UPDATE_COMPLETED:
			return { ...state, standardCareChartRankUpdation: false };
		case eventNames.TOGGLE_STD_CARE_CHART_UPDATION_MODAL:
			return { ...state, stdCareChartModal: action.payload };

		case `${eventNames.STD_CARE_CHART_SETTINGS_UPDATE}_FULFILLED`:
			return { ...state, updateStandardCareChartSettings: "FULFILLED" };
		case `${eventNames.STD_CARE_CHART_SETTINGS_UPDATE}_REJECTED`:
			return { ...state, updateStandardCareChartSettings: "REJECTED" };
		case eventNames.STD_CARE_CHART_SETTINGS_UPDATE_COMPLETED:
			return { ...state, updateStandardCareChartSettings: false };

		case `${eventNames.FETCH_STD_CARE_CHART_SETTINGS}_FULFILLED`:
			return {
				...state,
				stdCareChartSettings: action.payload.data.data,
				fetchStartTimeAndEndTime: "FULFILLED"
			};
		case `${eventNames.FETCH_STD_CARE_CHART_SETTINGS}_REJECTED`:
			return {
				...state,
				fetchStartTimeAndEndTime: "REJECTED"
			};
		case eventNames.CLEAR_FETCH_STD_CARE_CHART_SETTINGS:
			return {
				...state,
				fetchStartTimeAndEndTime: false
			};
		case `${eventNames.CHECKLIST_STD_CARE_CHART_UPDATE}_FULFILLED`:
			return { ...state, updateCheckListStdCareChartDetails: "FULFILLED" };
		case `${eventNames.CHECKLIST_STD_CARE_CHART_UPDATE}_REJECTED`:
			return { ...state, updateCheckListStdCareChartDetails: "REJECTED" };
		case eventNames.CHECKLIST_STD_CARE_CHART_UPDATE_COMPLETED:
			return { ...state, updateCheckListStdCareChartDetails: false };
		case `${eventNames.STD_CARE_CHART_DURATION_UPDATE}_FULFILLED`:
			return { ...state, updateStdCareChartDuration: "FULFILLED" };
		case `${eventNames.STD_CARE_CHART_DURATION_UPDATE}_REJECTED`:
			return { ...state, updateStdCareChartDuration: "REJECTED" };
		case eventNames.STD_CARE_CHART_DURATION_UPDATE_COMPLETED:
			return { ...state, updateStdCareChartDuration: false };
		default:
			return state;
	}
}
