import { message, Modal } from "antd";
import moment from "moment";
import Config from "../MOM-config";
import { i18Get } from "../i18";
import React from "react";
import { CloseSquareOutlined, InfoCircleOutlined  } from "@ant-design/icons";

let language = "en";
const currency = Config.currency ? Config.currency : "€";
const engCode = "en";
const frCode = "fr";
const imageSize = 5000000;
const imageSupportedFormats = ["png", "jpg", "jpeg", "gif", "bmp"];
const editorMaxLength = 2040;
const importFileType = ["xls", "xlsx", "csv", "text/csv", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "text/comma-separated-values", "application/csv", "application/excel", "application/vnd.msexcel", "text/anytext", "text/x-csv", "application/x-csv", "text/x-comma-separated-value"];
const importFileSize = 20000000;

const getLanguage = () => language;

const setLanguage = (lang) => {
	language = lang;
};

const showSuccess = (msg) => {
	message.success(msg);
};

const showInfo = (msg) => {
	message.info(msg);
};

const ErrorMessageWithClose = ({ content }) => {
	const handleClose = () => {
		message.destroy();
  	};
	return (
		<>
			<div className="display-inline-flex ">
				{content}
				<CloseSquareOutlined  onClick={handleClose} style={{ color: "blue", marginLeft: "10px" }}/>
			</div>
		</>
	);
};

const showError = (content) => {
	message.error(
		<div>
			<InfoCircleOutlined style={{ color: "red" }}/>
			<ErrorMessageWithClose content={content} />
		</div>
		, 0);
};
  


const openMessage = () => {
	message.loading({ content: "Loading...", key: "updatable" });
	setTimeout(() => {
	  message.success({ content: "Loaded!", key: "updatable", duration: 2 });
	}, 1000);
};

const info = (message, title) => {
	Modal.info({
	  title: i18Get(title ? title : "Info", getLanguage()),
	  content: (<div>{message}</div>),
	  onOk() {}
	});
};

const showTitleElement = (title) => {
	if (title === "STERILIZATION") {
		return "Sterilization";
	} if (title === "MEDICAL_DEVICE") {
		return "Medical Device";
	} if (title === "PHARMACY") {
		return "Pharmacy";
	} if (title === "PROSTHESIS") {
		return "Prosthesis";
	} if (title === "risk") {
		return "Risk";
	} if (title === "preparation") {
		return "Preparation";
	}
};

const asciiMappings = {
	146: 39,
	8217: 39
};

const regexInvalidStringContent = /[%\|\&"]/;

const regexName = /^[a-zA-ZÀ-ž0-9\w ()+ \_\-\'\’\,\.\:\;]*$/;
const regexConsoName = /^[a-zA-ZÀ-ž0-9\w ()+ \_\-\'\,\.\\\/\°]*$/;
const regexCode = /^[a-zA-ZÀ-ž0-9\w]*$/;
const regexSearch = /^[a-zA-ZÀ-ž0-9\w + \_\-\'\,\.]*$/;
const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const regexPass = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{5,}$/;
const regexPassVal = /^(?=.*\d)(?=.*[!@#$%^*_])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
const regexPassValEcp = /[;'"()&]/g;
const regexPhone = /^[\+]?[(]?[0-9][)]?[-\s\.]?[0-9][-\s\.]?[0-9]{1,17}$/;

const regexSpecialityCode = /^[A-Z]{3}$/;

const regexFamilyCode = /^[A-Z]{2}$/;

const capitalizeValidation = /^[A-Z]{0,3}$/;

const NumericValidation = /^[0-9]{0,3}$/;

const setLocaleForCurrency = (value, lang, integerOnly) => {
	const localeLanguage = lang || language;
	switch (localeLanguage) {
		case "fr": return (value || 0.00).toLocaleString((Config.frenchLocaleCurrencyCode ? Config.frenchLocaleCurrencyCode : "fr-FR"), { minimumFractionDigits: (integerOnly ? 0 : 2), maximumFractionDigits: (integerOnly ? 0 : 2) });
		case "en": return (value || 0.00).toLocaleString((Config.defaultLocaleCurrencyCode ? Config.defaultLocaleCurrencyCode : "en-IN"), { minimumFractionDigits: (integerOnly ? 0 : 2), maximumFractionDigits: (integerOnly ? 0 : 2) });
		default: return (value || 0.00).toLocaleString((Config.frenchLocaleCurrencyCode ? Config.frenchLocaleCurrencyCode : "fr-FR"), { minimumFractionDigits: (integerOnly ? 0 : 2), maximumFractionDigits: (integerOnly ? 0 : 2) });
	}
};

const numericRegex = () => {
	switch (language) {
		case "fr": return /^[0-9]+([,][0-9]{0,2})?$/;
		case "en": return /^[0-9]+([\.][0-9]{0,2})?$/;
		default: return /^[0-9]+([\.][0-9]{0,2})?$/;
	}
};

const negativePossitiveRegex = () => {
	switch (language) {
		case "fr": return /^-?[0-9]+([,][0-9]{0,2})?$/;
		case "en": return /^-?[0-9]+([\.][0-9]{0,2})?$/;
		default: return /^-?[0-9]+([\.][0-9]{0,2})?$/;
	}
};

const calculateFloatValue = (value, lang) => {
	try {
		const selectedLanguage = lang || language;
		switch (selectedLanguage) {
			case "fr": return parseFloat(value.toString().replace(/\s/g, "").replace(/,/g, "."));
			case "en": return parseFloat(value.toString().replace(/\s/g, ""));
			default: return parseFloat(value.toString().replace(/\s/g, ""));
		}
	} catch (e) {
		//console.log(e);
	}
};

const checkNameRegex = (value) => (value ? (!!regexName.test(value)) : false);

const checkConsomableName = (value) => (value ? (!!regexConsoName.test(value)) : false);

const checkCodeRegex = (value) => (value ? (!!regexCode.test(value)) : false);
const toPascalCase = (text) => {
	if (!text) {
		return text;
	}
	return text.replace(
		/(\w)(\w*)/g,
		(g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
	);
};

const replaceAndToPascalCase = (text) => {
	if (!text) {
		return text;
	}

	return text.replace(/_/g, " ").replace(
		/(\w)(\w*)/g,
		(g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase()
	);
};

const checkForParameter = (parameterName, location) => {
	let result = null;
	let tmp = [];
	location.search
		.substr(1)
		.split("&")
		.forEach((item) => {
			tmp = item.split("=");
			if (tmp[0] === parameterName) {result = decodeURIComponent(tmp[1]);}
		});
	return result;
};

const getMonthLabel = (monthValue) => {
	if (monthValue === 0) {
		return "January";
	} if (monthValue === 1) {
		return "February";
	} if (monthValue === 2) {
		return "March";
	} if (monthValue === 3) {
		return "April";
	} if (monthValue === 4) {
		return "May";
	} if (monthValue === 5) {
		return "June";
	} if (monthValue === 6) {
		return "July";
	} if (monthValue === 7) {
		return "August";
	} if (monthValue === 8) {
		return "September";
	} if (monthValue === 9) {
		return "October";
	} else if (monthValue === 10) {
		return "November";
	} else if (monthValue === 11) {
		return "December";
	}
};

const userDefaultRoles = [
	{
		value: "BLOCK_ADMIN",
		name: "Block Administrator"
	}, {
		value: "GENERAL_ADMIN",
		name: "General administrator"
	}, {
		value: "HOSPITAL_ADMIN",
		name: "Hospital Administrator"
	}, {
		value: "PATHWAY_USER",
		name: "Pathway User"
	}, {
		value: "TMA",
		name: "TMA"
	}, {
		value: "USER_ADMIN",
		name: "User Administrator"
	}, {
		value: "USER",
		name: "User"
	}
];

const entityColumnType = (type) => {
	if (type === "STRING") {
		return "TEXT";
	}
	return type;
};

const getPropsForReactTable = (list, pageIndex, totalPages, loading, loaderComponent, multiSort, defaultPageSize, manual, onFetchData, getCols, showPagination, showPageSizeOptions, onPageChange, label, className, onPageSizeChange) => ({
	data: list && list.length ? list : [],
	page: pageIndex || 0,
	pages: totalPages || 1,
	loading: !!loading,
	LoadingComponent: loaderComponent,
	multiSort: !!multiSort,
	defaultPageSize: localStorage.getItem("PAGE_SIZE") ? localStorage.getItem("PAGE_SIZE") : (defaultPageSize || 3),
	manual: !!manual,
	onFetchData: onFetchData || (() => null),
	columns: getCols,
	className: className || "-highlight report_react_table rt-body-x_scroll-hidden",
	showPagination: !!showPagination,
	onPageChange: onPageChange || sameDropper(),
	noDataText: loading ? "" : i18Get(`NO ${label ? label.toUpperCase() : "DATA"} FOUND`, language),
	previousText: i18Get("Previous", language),
	nextText: i18Get("Next", language),
	pageText: i18Get("Page", language),
	ofText: i18Get("of", language),
	rowsText: i18Get("rows", language),
	showPageSizeOptions: true,
	pageSizeOptions: [10, 15, 20, 25, 50, 100],
	onPageSizeChange
});

const calculateMinMaxRank = (list) => {
	let rank = { min: 1, max: 1 };
	if (list.length) {
		rank = { min: parseInt(list[0].rank), max: parseInt(list[0].rank) };
		list.map((element) => {
			if (element.rank) {
				if (parseInt(element.rank) < rank.min) {
					rank.min = parseInt(element.rank);
				}
				if (parseInt(element.rank) > rank.max) {
					rank.max = parseInt(element.rank);
				}
			}
		});
	}
	return rank;
};

const getDefaultPageSize = () => {
	const pageSize = localStorage.getItem("PAGE_SIZE") ? localStorage.getItem("PAGE_SIZE") : 20;
	return parseInt(pageSize);
};

const setQueryParams = (history, location, params) => {
	let search = location.search ? location.search.substr(1, location.search.length) : "";
	const arr = search ? search.split("&") : [];
	for (const key in params) {
		const index = arr.findIndex((el) => new RegExp(`${key}=`).test(el));
		if (index !== -1) {
			if (params[key]) {
				arr[index] = `${key}=${params[key]}`;
			} else {
				arr.splice(index, 1);
			}
		} else if (params[key]) {
			arr.push(`${key}=${params[key]}`);
		}
	}
	search = arr.join("&");
	history.push(`${location.pathname}?${search}`);
};

const dateFormat = "DD/MM/YYYY";
const dateTimeFormat = "DD/MM/YYYY HH:mm";

const formatDate = (date, isDateOnly) => {
	const format = isDateOnly ? dateFormat : dateTimeFormat;
	return moment(date).format(format);
};

const setFilterInLocalStorage = (label, filter) => {
	if (!label) {
		return;
	}
	let storedFilter;
	try {
		storedFilter = JSON.parse(localStorage.getItem(label) ? localStorage.getItem(label) : "{}");
		storedFilter = { ...storedFilter, ...filter };
	} catch (e) {
		storedFilter = filter;
	}
	localStorage.setItem(label, JSON.stringify(storedFilter));
};

const getFilterInLocalStorage = (label) => {
	let storedFilter = localStorage.getItem(label) ? localStorage.getItem(label) : "{}";
	try {
		storedFilter = JSON.parse(storedFilter);
	} catch (error) {
		// console.log(error);
	}
	return storedFilter;
};

/*
@data : all available option list from API 
@func : If any function to trigger the data to set
@labe : Whisch column needs to be label, by default it will be 'name'
@value : Which column needs to be value, by default it will be 'id' 
*/
const setSingleOneAsDefaultOption = (data, func, multiselect) => {

	const setLabel = "label";
	const setValue = "value";
	const arrayData = [];
	let option = null;

	if (data.length === 0) {
		return null;
	} else {
		const firstOption = data[0];

		if (multiselect) {

			arrayData.push({ ...firstOption, label: firstOption[setLabel], value: firstOption[setValue] });
			if (typeof func === "function") { func(arrayData); }
			return arrayData;

		} else {

			option = { ...firstOption, label: firstOption[setLabel], value: firstOption[setValue] };
			if (typeof func === "function") { func(option); }
			return option;
		}
	}

};

const convertMaxAndMinDurationToMinutes = (value, valueType) => {
	if (!value || !valueType) { 
		return parseInt(value) || 0;
	}

	switch (valueType) {
		case "Minutes":
			return parseInt(value);
		case "Hours":
			return parseInt(value) * 60;
		case "Days":
			return parseInt(value) * 24 * 60;
		default: 0;
			break;
	}
};

const setSingleDataDefault = (data, func, multiselect, setSingleDataDefault = true, oneAsDefaultOption = false) => {
	const setLabel = "label";
	const setValue = "value";
	const arrayData = [];
	let option = null;
	
	if (oneAsDefaultOption) {
		return setSingleOneAsDefaultOption(data, func, multiselect);
	}

	if (data && data.length !== 1 ) {
		return null;
	} else if (setSingleDataDefault) {
		const firstOption = data[0];

		if (multiselect) {

			arrayData.push({ ...firstOption, label: firstOption[setLabel], value: firstOption[setValue] });
			if (typeof func === "function") { func(arrayData); }
			return arrayData;

		} else {
			option = { ...firstOption, label: firstOption[setLabel], value: firstOption[setValue] };
			if (typeof func === "function") { func(option); }
			return option;
		}
	}

};

const sameDropper = (value) => value;

const fakeRowsGenerator = (sourceofCol, returnData, minCount, isCopy = false) => {

	if (minCount > returnData.length) {
		let newData = [];
		if (isCopy) {
			newData = JSON.parse(JSON.stringify(returnData));
		} else {
			newData = returnData;
		}
		const columnName = sourceofCol.map((data) => { return data.key; });
		const blankArrya = Object.keys(columnName).reduce(function (obj, key) { obj[columnName[key]] = ""; return obj; }, {});
		const difference = minCount - newData.length;
		for (let i = 0; difference > i; i++) {
			newData.push(blankArrya);
		}
		return newData;
	} else {
		return returnData;
	}
};

const randomAlphaNumeric = (length = 6) => {
	let result = "";
	const RANDOM_CHARS = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";
	for (let index = length; index > 0; --index) {
		result += RANDOM_CHARS[Math.floor(Math.random() * RANDOM_CHARS.length)];
	}

	return result;
};

const showColumn = (selectedColumn, column) => {
	if (Object.keys(selectedColumn).length === 0 || (selectedColumn !== undefined && selectedColumn && typeof(selectedColumn.check[column]) !== "undefined" && selectedColumn.check[column]) || selectedColumn.checkAll)
	{
		return true;
	}
	return false;
};

const copyToclipboard = (copyText) => {

	// Copy the text inside the text field
	navigator.clipboard.writeText(copyText);
	// Alert the copied text
	showSuccess("Copied to clipboard");
};

const sortByRank = (a, b) => { const x = parseInt(a.rank) < parseInt(b.rank) ? -1 : 1; return x; };

const clpTasksReplaceCharsBasedOnAscii = (str) => {
	let result = "";

	for (let i = 0; i < str.length; i++) {
		const char = str[i];
		const charCode = char.charCodeAt(0);

		if (asciiMappings[charCode]) {
			result += String.fromCharCode(asciiMappings[charCode]);
		} else {
			result += char;
		}
	}

	return result;
};

export default {
	regexPass,
	regexEmail,
	regexPassVal,
	regexPassValEcp,
	showSuccess,
	showInfo,
	showError,
	openMessage,
	info,
	getLanguage,
	setLanguage,
	showTitleElement,
	regexPhone,
	currency,
	setLocaleForCurrency,
	numericRegex,
	calculateFloatValue,
	engCode,
	frCode,
	toPascalCase,
	replaceAndToPascalCase,
	checkForParameter,
	imageSize,
	imageSupportedFormats,
	regexInvalidStringContent,
	regexName,
	regexSearch,
	editorMaxLength,
	getMonthLabel,
	importFileType,
	importFileSize,
	regexSpecialityCode,
	regexFamilyCode,
	capitalizeValidation,
	userDefaultRoles,
	entityColumnType,
	NumericValidation,
	getPropsForReactTable,
	calculateMinMaxRank,
	negativePossitiveRegex,
	getDefaultPageSize,
	checkNameRegex,
	checkCodeRegex,
	setQueryParams,
	dateFormat,
	dateTimeFormat,
	formatDate,
	setFilterInLocalStorage,
	getFilterInLocalStorage,
	checkConsomableName,
	sameDropper,
	setSingleOneAsDefaultOption,
	setSingleDataDefault,
	fakeRowsGenerator,
	randomAlphaNumeric,
	showColumn,
	copyToclipboard,
	sortByRank,
	convertMaxAndMinDurationToMinutes,
	clpTasksReplaceCharsBasedOnAscii
};
