import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../i18";
import clinicalPathwayProblemAction from "../../../../../../../actions/clinical-pathway-problems";
import utils from "../../../../../../../utils/utils";
import permissions from "../../../../../../../utils/permission";
import "../../../../../../../plug/reactTable/react-table.css";
import { Modal, Tooltip } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, RightCircleOutlined, DownCircleOutlined, CheckCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import Parser from "html-react-parser";
import AdditionalInfoEdit from "./additional-Info/edit";
import AdditionalInfoCreate from "./additional-Info/create";
import ColumnResizer from "../../../../../../../plug/react-table-column-resizer";
import Loader from "../../../../../../../plug/loader";
import locations from "../../../../../../../router/locations";
import { DoctorEdit, DocEdit, NurseEdit, NursePenEdit, ViewNotePad, DoctorNurseEdit, DocNurseEdit, Delete, Edit } from "../../../../../../../utils/icons";

class ProblemList extends Component {
	constructor(props) {
		super(props);
		this.columnResizers;
		this.state = {
			loading: true,
			totalPages: 1,
			key: 1,
			list: [],
			sorted: [],
			search: "",
			draggedOverData: {},
			isFullScreen: false,
			colWidths: {},
			scrollStatus: true,
			expandedProblemAndCheckListIds: [],
			sectionTypeHeaderTopPadding: 0,
			targetElement: {},
			deleteModelProblem: false,
			editRestrictedProblems: [],
			ModelHospital: {},
			metaData: "delete problem",
			code: null,
			successWaiting: 0
		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.fetchDataFromServer();
		this.state.colWidths = JSON.parse(localStorage.getItem("PROBLEM_LIST_COL_WIDTHS")) || {};
	}

	componentWillUnmount() {
		this.saveColumnWidths();
	}

	componentDidMount() {
		this.columnResizers = document.getElementsByClassName("columnResizer");
	}

	componentDidUpdate(prevProps) {
		if (prevProps.clpStepFilter !== this.props.clpStepFilter)
		{this.fetchDataFromServer();}

		const topHeaderHeight = document.getElementById("pathway_problem_table_header") && document.getElementById("pathway_problem_table_header").offsetHeight;
		if (this.state.sectionTypeHeaderTopPadding != topHeaderHeight) {
			this.setState({ sectionTypeHeaderTopPadding: topHeaderHeight });
		}

		if (this.state.successWaiting == 2) {
			setTimeout(() => { this.setState({ successWaiting: 0 }); }, 2000);
		}
	}

	saveColumnWidths() {
		const table = document.getElementById("pathway_problem");
		for (const cell of table.rows[0].cells) {
			if (cell.nodeName === "TH" && cell.innerText) {
				this.state.colWidths[cell.innerText] = cell.offsetWidth;
			}
		}
		localStorage.setItem("PROBLEM_LIST_COL_WIDTHS", JSON.stringify(this.state.colWidths));
	}

	reloadData() {
		this.fetchDataFromServer();
	}

	updateProblemsRank = () => {
		if (this.state.list && this.state.list.length < 1) {return;}
		const payload = this.state.list.map((problem, index) => {
			return { problemId: problem.problemId, rank: problem.rank || index + 1 };
		});
		this.props.dispatch(clinicalPathwayProblemAction.updateClpProblemsRank(this.props.match.params.id, { reOrderedProblems: payload }));
	};

	convertString(input) {
		return input.split("<a").join("<a target=\"_blank\"");
	}

	formatSteps = data => {
		const steps = [];
		data.map((step) => {
			if (step.clpSubStepId) {
				steps.push(`${i18Get(step.stepName, utils.getLanguage())} -> 
                ${step.day ? `${i18Get("DAY", utils.getLanguage())} ${i18Get(step.startPrefix, utils.getLanguage())} 
                ${step.endPrefix ? `${i18Get("To", utils.getLanguage())} ${i18Get("DAY", utils.getLanguage())} ${i18Get(step.endPrefix, utils.getLanguage())}`
		: ""}` : i18Get(step.subStepName.trim(), utils.getLanguage())}`);
			}
		});
		return steps.join(", ");
	};

	fetchDataFromServer() {
		this.setState({ scrollStatus: true, loading: true });
		let queryString = "";
		queryString += "offset=0&";
		queryString += "numResults=1000&";
		queryString += `${this.state.sort ? `sort=${this.state.sort}&` : ""}${this.state.desc ? `desc=${this.state.desc}&` : ""}`;
		queryString += this.props.search ? `value=${encodeURIComponent(this.props.search)}&` : "";
		queryString += this.props.field && this.props.field !== "SEARCH_ALL_FIELDS" ? `field=${this.props.field}&` : "";
		queryString += this.props.type && this.props.type.value ? `clpHealthProblemId=${this.props.type.value}&` : "";
		queryString += this.props.intervenants && this.props.intervenants.value ? `clpIntervenantId=${this.props.intervenants.value}&` : "";
		queryString += this.props.clinicalPathwayData.clinicalPathwayType ? `clinicalPathwayType=${this.props.clinicalPathwayData.clinicalPathwayType}&` : "";
		queryString += this.props.clpStepFilter ? `clpStepFilter=${this.props.clpStepFilter}&` : "";
		this.props.dispatch(clinicalPathwayProblemAction.getClpProblemList(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, this.props.match.params.organization, queryString));
	}

	deleteClicked = (row) => {
		//console.log('delete', row);
		const { confirm } = Modal;
		confirm({
			title: i18Get("Deleting this will remove all the corresponding additional info belonging to the problem.Are you sure you want to delete ?", utils.getLanguage()),
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: i18Get("Delete", utils.getLanguage()),
			cancelText: i18Get("Cancel", utils.getLanguage()),
			onOk: () => {
				if (row && row.problemId) {
					this.props.dispatch(clinicalPathwayProblemAction.removeClpProblem(this.props.match.params.id, this.props.match.params.organization, row.problemId));
				}
			},
			onCancel: () => {
				//console.log('Cancel');
			}
		});
	};

	unLinkCheckList = (item, data) => {
		const { confirm } = Modal;
		confirm({
			title: i18Get("Are you sure you want to remove checklist ?", utils.getLanguage()),
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: i18Get("Yes", utils.getLanguage()),
			cancelText: i18Get("No", utils.getLanguage()),
			onOk: () => {
				if (item && item.id) {
					const payload = {
						checkListTreatmentId: item.checkListTreatmentId,
						checkListProblemId: item.checkListProblemId
					};
					this.props.dispatch(clinicalPathwayProblemAction.unlinkCheckList(this.props.match.params.id, data.problemId, payload));
				}
			},
			onCancel: () => {
				//console.log('Cancel');
			}
		});
	};

	expandClicked = (data, item) => {
		const prevState = this.state;
		prevState.expandedProblemAndCheckListIds.push({ problemId: data.problemId, checkListTreatmentId: item.checkListTreatmentId });
		this.setState(prevState);
	};

	collapseClicked = (data, item) => {
		const prevState = this.state;
		const index = prevState.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === item.checkListTreatmentId);
		if (index >= 0) {
			prevState.expandedProblemAndCheckListIds.splice(index, 1);
		}
		this.setState(prevState);
	};

	deleteAdditionalInfo = (item, data) => {
		//console.log('delete', item);
		const { confirm } = Modal;
		confirm({
			title: i18Get("Are you sure you want to delete ?", utils.getLanguage()),
			icon: <ExclamationCircleOutlined />,
			content: "",
			okText: i18Get("Delete", utils.getLanguage()),
			cancelText: i18Get("Cancel", utils.getLanguage()),
			onOk: () => {
				if (item && item.id) {
					this.props.dispatch(clinicalPathwayProblemAction.removeAdditionalInfo(this.props.match.params.id, data.problemId, item.id));
				}
			},
			onCancel: () => {
				//console.log('Cancel');
			}
		});
	};

	editClicked(value) {
		return (e) => {
			e.preventDefault();
			this.props.editClicked(value);
		};
	}

	editAdditionalInfo(value, data, index) {
		return (e) => {
			//if (this.props.clinicalPathwayData.isApproved) {
			e.preventDefault();
			data["editAdditionalInfoIndex"] = index;
			this.setState({ editAdditionalInfo: true, selectedAdditionalInfo: value, problemId: data.problemId, problemDetails: data });
			//}
		};
	}

	onResize = (event, { element, size, handle }) => {
		this.setState({ width: size.width, height: size.height, targetElement: { element, handle } });
	};

	dragStart = (e, problemIndex) => {
		e.dataTransfer.setDragImage(document.createElement("div"), 0, 0);
		e.dataTransfer.setData("problemIndex", problemIndex);
	};

	rearrange = (e) => {
		let { list, draggedOverData } = this.state;
		const problemIndex = e.dataTransfer.getData("problemIndex");
		const problem = list[problemIndex];
		if (!draggedOverData || !problem) {return;}
		const index = list.findIndex(el => el.problemId === draggedOverData.problemId);
		if (index !== -1) {
			list.splice(problemIndex, 1);
			list = [...list.slice(0, index), problem, ...list.slice(index)];
			list = list.map((prob, i) => {
				return { ...prob, rank: i + 1 };
			});
			const prevState = this.state;
			prevState.list = list;
			this.setState(prevState);
		}
	};

	widthSetter(columnName) {
		const name = i18Get(columnName ? columnName : "", utils.getLanguage());
		if (!this.state.colWidths[name])
		{return {
			minWidth: 150
		};}
		return {
			minWidth: this.state.colWidths[name],
			width: this.state.colWidths[name],
			maxWidth: this.state.colWidths[name]
		};
	}

	getTable(list) {
		return (
			<table id="pathway_problem" className="margin-bottom-15" data-resizable="true">
				<thead className="thead" id='pathway_problem_table_header'>
					<tr>
						<th>
							{this.props.enableReorder ? <div className="empty_head_reorder"> </div> : ""}
						</th>
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.TYPE ?
							<React.Fragment>
								<th style={this.widthSetter("TYPE")}>
									<I18 tkey='TYPE' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						<th style={this.widthSetter("PROBLEM")}>
							<I18 tkey='PROBLEM' />
						</th>
						<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.DATA ?
							<React.Fragment>
								<th style={this.widthSetter("DATA")}>
									<I18 tkey='DATA' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{/* {!this.props.treatmentId ? */}
						<th></th> 
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.CODE ?
							<React.Fragment>
								<th style={this.widthSetter("CODE")}>
									<I18 tkey='CODE' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.INTERVENTIONS_SUMMARY_NAME ?
							<React.Fragment>
								<th style={this.widthSetter("INTERVENTIONS SUMMARY NAME")}>
									<I18 tkey='INTERVENTIONS SUMMARY NAME' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.DURATION ?
							<React.Fragment>
								<th>
									<I18 tkey='DURATION' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.STEPS ?
							<React.Fragment>
								<th style={this.widthSetter("STEPS")}>
									<I18 tkey='STEPS' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.INTERVENANTS ?
							<React.Fragment>
								<th style={this.widthSetter("INTERVENANTS")}>
									<I18 tkey='INTERVENANTS' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check["INTERVENTION_DETAILS"] ?
							<React.Fragment>
								<th style={this.widthSetter("INTERVENTION_DETAILS")}>
									<I18 tkey='INTERVENTION DETAILS' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.EXPECTED_RESULTS ?
							<React.Fragment>
								<th style={this.widthSetter("EXPECTED RESULTS")}>
									<I18 tkey='EXPECTED RESULTS' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.ARGUMENTATION_AND_FOUNDATIONS ?
							<React.Fragment>
								<th style={this.widthSetter("ARGUMENTATION AND FOUNDATIONS")}>
									<I18 tkey='ARGUMENTATION AND FOUNDATIONS' />
								</th>
								<ColumnResizer className="columnResizer resizable_td" minWidth={0} />
							</React.Fragment>
							: ""}
					</tr>
				</thead>
				<Loader loading={this.state.loading} isTable={true} />
				{this.getTableContent(list)}
			</table>
		);
	}

	alertError = () => {
		const { info } = Modal;
		info({
			title: i18Get("Can't Process While Reorder Enabled", utils.getLanguage()),
			icon: <ExclamationCircleOutlined />,
			content: "",
			onCancel: () => {
				//console.log('Cancel');
			}
		});
	};

	filterCheckList = (data, item) => {
		const index = this.state.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === item.checkListTreatmentId);
		if (index < 0) {
			return false;
		}
		return true;
	};

	rowHider = (data, item, index) => {
		if (index === data.additionalInfo.length || (data.problem && item && !item.isCheckList)) {
			return true;
		} else if (item && item.isCheckList && item.isHeading) {
			return true;
		} else if (item.checkListTreatmentId && this.filterCheckList(data, item)) {
			return true;
		}
	};
	getAdditionalInfo(data) {
		const templist = [];
		if (data.stepSectionType !== this.state.stepSectionType) {
			this.state.stepSectionType = data.stepSectionType;
			templist.push(this.sectionTypeHeader(data.stepSectionType));
		}
		for (let index = 0; index <= data.additionalInfo.length; index++) {
			const item = data.additionalInfo[index];

			//==============EDIT PROBLEM RESTRICTING=====================
			this.state.editRestrictedProblems = [...new Set(this.state.editRestrictedProblems)];
			if (data.problemLinkedModelTreatmentId || data.modelTreatmentId || data.isModelAdditionalInfoExists) {

				if (data.parentId != null) {
					this.state.editRestrictedProblems.push(data.parentId);
					this.state.ModelHospital[data.parentId] = (data.modelHospitalName ? data.modelHospitalName + " " : "") + data.modelTreatmentName ? data.modelTreatmentName : "";
				}
				this.state.editRestrictedProblems.push(data.problemId);
				this.state.ModelHospital[data.problemId] = (data.modelHospitalName ? data.modelHospitalName + " " : "") + data.modelTreatmentName ? data.modelTreatmentName : "";

			} else if (this.state.editRestrictedProblems.includes(data.parentId) && data.parentId != null) {
				this.state.editRestrictedProblems.push(data.problemId);
				this.state.ModelHospital[data.problemId] = this.state.ModelHospital[data.parentId];
			} else {
				this.state.ModelHospital = {};
			}

			data["additionalData"] = this.state.editRestrictedProblems;
			data["ModelHospital"] = this.state.ModelHospital;
			if (this.rowHider(data, item, index)) {// Remove unwanted rows depends on the Checklist
				templist.push(
					<tr >
						{index === 0 ?
							<React.Fragment>

								<td className={data.modelTreatmentId ? "model_attached_td" : ""} rowSpan={data.additionalInfo.length + 1}>{permissions(this.props.clinicalPathwayData.isReadOnly, "READ") ?
									<div className="test_align_center margin-bottom-0 margin-top-0 margin-left-0 margin-right-0 d-flex">
										{!data.modelTreatmentId && permissions(this.props.clinicalPathwayData.isReadOnly, "EDIT") && <a className="margin-right-10" type="edit" onClick={this.editClicked(data).bind(this)} ><Edit /></a>}
										{data.isParent && permissions(this.props.clinicalPathwayData.isReadOnly, "DELETE") &&
											<a className="margin-right-10" type="delete" onClick={() => this.deleteClicked(data)} ><Delete /></a>}
									</div> : ""
								}
								</td> 
								{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.TYPE ?
									<React.Fragment>
										<td className={data.modelTreatmentId ? "model_attached_td" : ""} rowSpan={data.additionalInfo.length + 1}>
											{ data.modelTreatmentId && data.modelTreatmentName ? <Tooltip placement="top" title={"Model Name: " + (data.modelHospitalName ? data.modelHospitalName + " " : "") + data.modelTreatmentName}>{data.type ? i18Get(data.type, utils.getLanguage()) : ""}</Tooltip> : (data.type ? i18Get(data.type, utils.getLanguage()) : "")}

										</td>
										<td rowSpan={data.additionalInfo.length + 1} className="resizable_td" />
									</React.Fragment> : ""
								}
								<td className={data.modelTreatmentId ? "model_attached_td" : ""}>
									{ data.modelTreatmentId && data.modelTreatmentName ? <Tooltip placement="top" title={"Model Name: " + (data.modelHospitalName ? data.modelHospitalName + " " : "") + data.modelTreatmentName}>{data.problem ? data.problem : ""}</Tooltip> : (data.problem ? data.problem : "")}
								</td>
								<td className="resizable_td" />
								{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.DATA ?
									<React.Fragment>
										<td rowSpan={data.additionalInfo.length + 1} className={data.modelTreatmentId ? "td_padding_left model_attached_td" : "td_padding_left"} >{data.data ? Parser(this.convertString(data.data)) : ""}</td>
										<td rowSpan={data.additionalInfo.length + 1} className="resizable_td" />
									</React.Fragment> : ""
								}
							</React.Fragment> :
							<React.Fragment>
								<td className={data.modelTreatmentId ? "model_attached_td" : ""}>
									{ data.modelTreatmentId && data.modelTreatmentName ? <Tooltip placement="top" title={"Model Name: " + (data.modelHospitalName ? data.modelHospitalName + " " : "") + data.modelTreatmentName}>
										{data.problem && item && !item.isCheckList ? data.problem : data.problem && item && item.checkListTreatmentId && this.filterCheckList(data, item) ? data.problem : ""}</Tooltip> : (data.problem && item && !item.isCheckList ? data.problem : data.problem && item && item.checkListTreatmentId && this.filterCheckList(data, item) ? data.problem : "")}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
						}
						{/* {!this.props.treatmentId ? */}
						<td className={`text_align_center ${item && item.modelTreatmentId ? "model_attached_td" : ""} `}>
							{index === data.additionalInfo.length ?
								<span>{permissions(this.props.clinicalPathwayData.isReadOnly, "CREATE") && ((item && !item.modelTreatmentId || !item) && !data.modelTreatmentId ) && <PlusOutlined onClick={this.createAdditionalInfo(data).bind(this)} />}</span>
								:
								<div className='drop-round-arrow'>
									<div className="text_align_center margin-bottom-0 margin-top-0 margin-left-0 margin-right-0 d-flex align-items-center">
										{item.isCheckList && item.isHeading ? this.filterCheckList(data, item) ?
											<React.Fragment>
												{permissions(this.props.clinicalPathwayData.isReadOnly, "DELETE") && <a className="margin-right-10" type="delete" onClick={() => this.unLinkCheckList(item, data)} ><Delete /></a>}
												<DownCircleOutlined style={{ color: "#2016b8" }} className="check_list" onClick={() => this.collapseClicked(data, item)} />
											</React.Fragment>
											:
											<React.Fragment>
												{permissions(this.props.clinicalPathwayData.isReadOnly, "DELETE") && <a className="margin-right-10" type="delete" onClick={() => this.unLinkCheckList(item, data)} ><Delete /></a>}
												<RightCircleOutlined style={{ color: "#2016b8" }} className="align-items-center check_list" onClick={() => this.expandClicked(data, item)} />
											</React.Fragment>
											: ""}

										{ permissions(this.props.clinicalPathwayData.isReadOnly, "EDIT") ?
											item.nurseAndDoctor === "DOCTOR" && ((item.isCheckList && !item.modelTreatmentId && this.filterCheckList(data, item) && !item.isHeading) || !item.isCheckList) ?
												<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Medical interventions or on medical prescription.", utils.getLanguage())}>
													<a className="margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)} >
														{!item.modelTreatmentId ? <DoctorEdit /> : <DocEdit />}
													</a>
												</Tooltip>
												:
												item.nurseAndDoctor === "NURSE" && ((item.isCheckList && !item.modelTreatmentId && this.filterCheckList(data, item) && !item.isHeading) || !item.isCheckList) ?
													<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Multi-professional paramedical interventions.", utils.getLanguage())}>
														<a className="margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)} >
															{!item.modelTreatmentId ? <NurseEdit /> : <NursePenEdit />}
														</a>
													</Tooltip>
													:
													item.nurseAndDoctor === "ALL" && ((item.isCheckList && !item.modelTreatmentId && this.filterCheckList(data, item) && !item.isHeading) || !item.isCheckList) ?
														<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Medical interventions or on medical prescription and Interventions By Nurses Or Professionals", utils.getLanguage())}>
															<a className="margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)} >
																{!item.modelTreatmentId ? <DoctorNurseEdit /> : <DocNurseEdit />}
															</a>
														</Tooltip>
														:
														(this.filterCheckList(data, item) && !item.isHeading) ?
															!item.modelTreatmentId ? <a className="margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)} ><Edit /></a> :
																<a className="margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)}  >
																	<ViewNotePad />
																</a>
															:
															!item.nurseAndDoctor && ((item.isCheckList && !item.modelTreatmentId && this.filterCheckList(data, item) && !item.isHeading) || !item.isCheckList) ?
																<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Medical interventions or on medical prescription and Interventions By Nurses Or Professionals", utils.getLanguage())}>
																	{/* <Icon className="edit_color margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)} /> */}
																	<a className="margin-right-10" type="edit" onClick={this.editAdditionalInfo(item, data, index).bind(this)}  ><Edit /></a>
																</Tooltip> : ""
											: ""}
										{!item.isCheckList && permissions(this.props.clinicalPathwayData.isReadOnly, "DELETE") &&
											<a className="margin-right-10" type="delete" onClick={() => this.deleteAdditionalInfo(item, data)} ><Delete /></a>}
									</div>
								</div>
							}
						</td> 
						
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.CODE ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										""
										:
										item.modelTreatmentName ?
											<Tooltip placement="top" title={"Model Name: " + (item.modelHospitalName ? item.modelHospitalName + " " : "") + item.modelTreatmentName}>
												{!item.isCheckList ? item.itemId : item.isHeading || this.filterCheckList(data, item) ? <span className={`check_list ${item.isHeading ? "check_list_bold" : ""}`}>{item.itemId}</span> : "" } </Tooltip> :
										 !item.isCheckList ? item.itemId : item.isHeading || this.filterCheckList(data, item) ? <span className={`check_list ${item.isHeading ? "check_list_bold" : ""}`}>{item.itemId}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.INTERVENTIONS_SUMMARY_NAME ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										""
										:
										item.modelTreatmentName ?
											<Tooltip placement="top" title={"Model Name: " + (item.modelHospitalName ? item.modelHospitalName + " " : "") + item.modelTreatmentName}>
												{!item.isCheckList ? item.interventionSummaryName : item.isHeading || this.filterCheckList(data, item) ? <span className={`check_list ${item.isHeading ? "check_list_bold" : ""}`}>{item.interventionSummaryName}</span> : ""}
											</Tooltip> :
											!item.isCheckList ? item.interventionSummaryName : item.isHeading || this.filterCheckList(data, item) ? <span className={`check_list ${item.isHeading ? "check_list_bold" : ""}`}>{item.interventionSummaryName}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.DURATION ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`} style={{ minWidth: "100px" }}>
									{item?.isCheckList && item?.isHeading ? " " : item?.duration}
									{/* {index === data.additionalInfo.length ?
										"" : !item.isCheckList && item.duration ? item.duration : this.filterCheckList(data, item) && item.duration ? <span className="check_list">{item.duration}</span> : ""
									} */}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.STEPS ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										""
										:
										!item.isCheckList && item.subStepMapping ? this.formatSteps(item.subStepMapping) : item.subStepMapping && this.filterCheckList(data, item) ? <span className="check_list">{this.formatSteps(item.subStepMapping)}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.INTERVENANTS ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										"" : !item.isCheckList && item.categoryPersonnelName ? item.categoryPersonnelName : this.filterCheckList(data, item) && item.categoryPersonnelName ? <span className="check_list">{item.categoryPersonnelName}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check["INTERVENTION_DETAILS"] ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										""
										:
										!item.isCheckList && item.interventionDetails ?
											Parser(this.convertString(item.interventionDetails)) :
											this.filterCheckList(data, item) && item.interventionDetails ?
												<span className="check_list">{Parser(this.convertString(item.interventionDetails))}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.EXPECTED_RESULTS ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										""
										:
										!item.isCheckList && item.expectedResults ? Parser(this.convertString(item.expectedResults)) : this.filterCheckList(data, item) && item.expectedResults ? <span className="check_list">{Parser(this.convertString(item.expectedResults))}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
						{this.props.selectedPblmColumns && this.props.selectedPblmColumns.check && this.props.selectedPblmColumns.check.ARGUMENTATION_AND_FOUNDATIONS ?
							<React.Fragment>
								<td className={`td_padding_left ${item && item.modelTreatmentId ? "model_attached_td" : ""}`}>
									{index === data.additionalInfo.length ?
										""
										:
										!item.isCheckList && item.argumentationAndFoundations ? Parser(this.convertString(item.argumentationAndFoundations)) : this.filterCheckList(data, item) && item.argumentationAndFoundations ? <span className="check_list">{Parser(this.convertString(item.argumentationAndFoundations))}</span> : ""
									}
								</td>
								<td className="resizable_td" />
							</React.Fragment>
							: ""
						}
					</tr>
				);
			}
		}
		return templist;
	}

	sectionTypeHeader(stepSectionType) {
		return (
			<tr>
				<td className="section_type_td" colSpan={23} style={{ top: this.state.sectionTypeHeaderTopPadding }}>
					<div className="text_align_center w_100">
						<I18 tkey={stepSectionType === "CLINICAL_PATHWAY" ? "HOSPITALISATION" : stepSectionType.replace(/_/g, " ").toUpperCase()} />
					</div>
				</td>
			</tr>
		);
	}

	getTableContent(list) {
		if (list && list.length > 0) {
			this.state.editRestrictedProblems = [];
			const data = list.map((data, dataIndex) => {
				return (
					<tbody key={dataIndex} id={data.problemId} onDrop={e => { this.rearrange(e); }} onDragOver={e => { e.preventDefault(); }}>
						{this.getAdditionalInfo(data, dataIndex)}
					</tbody>);
			});
			this.state.scrollStatus ? this.findScrollPosition() : "";
			return data;
		}
		else {
			return (
				<tr>
					<td colSpan={18}>
						<div className="text_align_center w_100">
							<I18 tkey={"NO HEALTH PROBLEM FOUND"} />
						</div>
					</td>
				</tr>
			);
		}
	}

	createAdditionalInfo(data) {
		return (e) => {
			e.preventDefault();
			this.setState({ createAdditionalInfo: true, problemId: data.problemId, problemDetails: data });
		};
	}

	onClickCancel() {
		this.setState({ editAdditionalInfo: false, createAdditionalInfo: false, selectedAdditionalInfo: {}, problemDetails: {}, problemId: null });
	}

	findScrollPosition() {
		const params = new URLSearchParams(window.location.href.split("?")[1]);
		const position = params.get("position");
		if (position) {
			const divPosition = document.getElementById(position);
			if (divPosition && divPosition.offsetTop) {
				const y = divPosition.offsetTop - 40;
				document.getElementById("problem_scroll_positions").scrollTop = y;
				this.state.scrollStatus = false;
				this.props.history.push(`${locations.clinical_pathway_plain}/${this.props.match.params.organization}/${this.props.match.params.id}`);
			}
		}
	}

	getModelStatus = () => {
		if (this.state.code) {
			this.timer = setTimeout(() =>
				this.props.dispatch(clinicalPathwayProblemAction.getDeleteModelProblemObserver(this.state.code, this.state.metaData)), 3000);

		}
	};

	isRefreshed = (status) => {
		switch (status) {
			case "PROCESSED":
				return true;
			case "ERRORED":
				return true;
			default: return false;
		}
	};

	render() {
		this.state.stepSectionType = "";
		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwayProblemList === "FULFILLED") {
			this.state.list = this.props.clinicalPathwayProblems.problemList.mapping;
			this.props.setProblemCount(this.props.clinicalPathwayProblems.problemList.problemCount);
			this.props.setTaskCount(this.props.clinicalPathwayProblems.problemList.totalTasksCount, this.props.clinicalPathwayProblems.problemList.tasksCount);
			this.setState({ loading: false, stepSectionType: "" });
			this.props.dispatch(clinicalPathwayProblemAction.clearGetClpProblemList());
			if (this.props.enableReorder) {this.props.onReorderCompleted();}
			this.props.fetchProblemListQuickOrder();

			if (this.props.clinicalPathwayData.isModel)
			{this.props.onFetchData();}
		}
		if (this.props.clinicalPathwayProblems.fetchAllClinicalPathwayProblemList === "REJECTED") {
			if (this.props.enableReorder) {this.props.onReorderCompleted();}
		}
		if (this.props.clinicalPathwayProblems.deleteClpProblemStatus === "FULFILLED") {
			this.props.dispatch(clinicalPathwayProblemAction.removeClpProblemCompleted());
			if (this.props.clinicalPathwayProblems.attachedModelProblem && this.props.clinicalPathwayProblems.attachedModelProblem.code) {
				this.setState({ code: this.props.clinicalPathwayProblems.attachedModelProblem.code, successWaiting: 1 }, () => { this.getModelStatus(); });
			} else {
				this.fetchDataFromServer();
				this.props.onFetchData();
			}

		}
		if (this.props.clinicalPathwayProblems.deleteClpProblemStatus === "REJECTED") {
			this.props.dispatch(clinicalPathwayProblemAction.removeClpProblemCompleted());
		}
		if (this.props.clinicalPathwayProblems.deleteAdditionalInfoStatus === "FULFILLED") {
			this.props.dispatch(clinicalPathwayProblemAction.removeAdditionalInfoCompleted());
			if (this.props.clinicalPathwayProblems.modelProblemTaskStatus && this.props.clinicalPathwayProblems.modelProblemTaskStatus.code) {
				this.setState({ code: this.props.clinicalPathwayProblems.modelProblemTaskStatus.code, successWaiting: 1 }, () => { this.getModelStatus(); });
			} else {
				this.fetchDataFromServer();
			}
		}
		if (this.props.clinicalPathwayProblems.deleteAdditionalInfoStatus === "REJECTED") {
			this.props.dispatch(clinicalPathwayProblemAction.removeAdditionalInfoCompleted());
		}
		if (this.props.clinicalPathwayProblems.unlinkCheckListStatus === "FULFILLED") {
			this.props.dispatch(clinicalPathwayProblemAction.unlinkCheckListCompleted());
			this.fetchDataFromServer();
		}
		if (this.props.clinicalPathwayProblems.unlinkCheckListStatus === "REJECTED") {
			this.props.dispatch(clinicalPathwayProblemAction.unlinkCheckListCompleted());
		}
		if (this.props.clinicalPathwayProblems.updateClpProblemsRankStatus === "FULFILLED"
			|| this.props.clinicalPathwayProblems.updateClpProblemsRankStatus === "REJECTED") {
			this.props.onReorderCompleted();
			this.fetchDataFromServer();
			this.props.dispatch(clinicalPathwayProblemAction.clearUpdateClpProblemsRank());
		}

		if (this.state.search != this.props.search || this.state.field != this.props.field || this.state.type != this.props.type || this.state.treatmentId != this.props.treatmentId || this.state.intervenants != this.props.intervenants ) {
			const prevState = this.state;
			prevState.search = this.props.search;
			prevState.type = this.props.type;
			prevState.field = this.props.field;
			prevState.treatmentId = this.props.treatmentId;
			prevState.intervenants = this.props.intervenants;
			this.setState(prevState);
			this.fetchDataFromServer();
		}

		if (this.props.clinicalPathwayProblems.modelProblemDeleteStatus == "FULFILLED" && this.props.clinicalPathwayProblems.meta == this.state.metaData) {
			this.props.dispatch(clinicalPathwayProblemAction.clearDeleteModelProblemObserver());
			if (this.props.clinicalPathwayProblems.modelProblemStatus && this.props.clinicalPathwayProblems.modelProblemStatus.status && this.isRefreshed(this.props.clinicalPathwayProblems.modelProblemStatus.status)) {
				if (this.props.clinicalPathwayProblems.modelProblemStatus.status === "PROCESSED") {
					//utils.showSuccess(i18Get('Health mapping removed successfully', utils.getLanguage()));
					this.state.successWaiting = 2;
					this.fetchDataFromServer();
					this.props.onFetchData();
				}
				if (this.props.clinicalPathwayProblems.modelProblemStatus.status === "ERRORED") {
					utils.showError(i18Get("Failed to remove health mapping", utils.getLanguage()));
					this.state.loading = false;
				}
			} else {
				this.getModelStatus();
			}
		}

		return (
			<div className={"position-relative h-100"}>
				<div style={{ height: `${this.props.isFullScreen ? "100%" : ""}` }} id="problem_scroll_positions" className="table_div resizable_table ">{this.getTable(this.state.list)}</div>
				{this.state.editAdditionalInfo ?
					<AdditionalInfoEdit
						selectedAdditionalInfo={this.state.selectedAdditionalInfo}
						editAdditionalInfo={this.state.editAdditionalInfo}
						problemId={this.state.problemId}
						problemDetails={this.state.problemDetails}
						onClickCancel={this.onClickCancel.bind(this)}
						reloadData={this.reloadData.bind(this)}
						clinicalPathwayData={this.props.clinicalPathwayData}
					/>
					: ""}
				{this.state.createAdditionalInfo ?
					<AdditionalInfoCreate
						createAdditionalInfo={this.state.createAdditionalInfo}
						onClickCancel={this.onClickCancel.bind(this)}
						problemDetails={this.state.problemDetails}
						problemId={this.state.problemId}
						reloadData={this.reloadData.bind(this)}
						clinicalPathwayData={this.props.clinicalPathwayData}
					/>
					: ""}

				{this.state.successWaiting ? <Modal
					className='succss_model'
					title="Basic Modal"
					visible={this.state.successWaiting}
					style={{ maxWidth: 300 }}
				>
					<div>
						{this.state.successWaiting == 1 ? <LoadingOutlined /> : <CheckCircleOutlined />}

						<div className='message'><b><I18 tkey={`${this.state.successWaiting == 2 ? "Successful" : "Please wait..."}`} /></b></div>
					</div>
				</Modal> : ""}
			</div>
		);
	}
}

ProblemList.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequire
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	translations: store.translations,
	permissions: store.permissions
}))(ProblemList));