import axios from "axios";
import Config from "../../MOM-config";
import events from "../events";

const getAll = (id, query) => ({
	type: events.GET_ALL_STD_CARE_CHART,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/standardCareChart${query ? `?${query}` : ""}`)
});

const clearGetAll = () => ({
	type: events.CLEAR_GET_ALL_STD_CARE_CHART,
	payload: {}
});

const updateStandardCareChartDetails = (id, props, payload) => ({
	type: events.STD_CARE_CHART_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${props.problemId}/additionalInfo/${props.additionalInfoId}/standardCareChart`, { ...payload })
});

const updateStandardCareChartDetailsCompleted = () => ({
	type: events.STD_CARE_CHART_UPDATE_COMPLETED,
	payload: {}
});

const standardCareChartRankUpdation = (id, payload) => ({
	type: events.STD_CARE_CHART_RANK_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/additionalInfo/updateRank`, { ...payload })
});

const standardCareChartRankUpdationCompleted = () => ({
	type: events.STD_CARE_CHART_RANK_UPDATE_COMPLETED,
	payload: {}
});

const toggleStandardCareChartRankUpdationModal = (modalState) => ({
	type: events.TOGGLE_STD_CARE_CHART_UPDATION_MODAL,
	payload: modalState
});

const updateStandardCareChartSettings = (id, payload) => ({
	type: events.STD_CARE_CHART_SETTINGS_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/standardCareChart/hours`, { ...payload })
});

const updateStandardCareChartSettingsCompleted = () => ({
	type: events.STD_CARE_CHART_SETTINGS_UPDATE_COMPLETED,
	payload: {}
});

const fetchStartTimeAndEndTime = (id) => ({
	type: events.FETCH_STD_CARE_CHART_SETTINGS,
	payload: axios.get(`${Config.apiRootSecure}/clinicalPathway/${id}/standardCareChart/hours`)
});

const clearFetchStartTimeAndEndTime = () => ({
	type: events.CLEAR_FETCH_STD_CARE_CHART_SETTINGS,
	payload: {}
});

const updateCheckListStdCareChartDetails = (id, props, payload) => ({
	type: events.CHECKLIST_STD_CARE_CHART_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/healthMapping/${props.problemId}/standardCareChart/checkList`, { ...payload })
});

const updateCheckListStdCareChartDetailsCompleted = () => ({
	type: events.CHECKLIST_STD_CARE_CHART_UPDATE_COMPLETED,
	payload: {}
});

const updateStdCareChartDuration = (id, problemId, payload) => ({
	type: events.STD_CARE_CHART_DURATION_UPDATE,
	payload: axios.put(`${Config.apiRootSecure}/clinicalPathway/${id}/standardCareChart/${problemId}/duration`, { ...payload })
});

const updateStdCareChartDurationCompleted = () => ({
	type: events.STD_CARE_CHART_DURATION_UPDATE_COMPLETED,
	payload: {}
});

export default {
	getAll,
	clearGetAll,
	updateStandardCareChartDetails,
	updateStandardCareChartDetailsCompleted,
	standardCareChartRankUpdation,
	standardCareChartRankUpdationCompleted,
	updateStandardCareChartSettings,
	updateStandardCareChartSettingsCompleted,
	fetchStartTimeAndEndTime,
	clearFetchStartTimeAndEndTime,
	updateCheckListStdCareChartDetails,
	updateCheckListStdCareChartDetailsCompleted,
	toggleStandardCareChartRankUpdationModal,
	updateStdCareChartDuration,
	updateStdCareChartDurationCompleted
};
