/* eslint-disable no-nested-ternary */
import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import ClinicalPathwayProblemAction from "../../../../../../../../actions/clinical-pathway-problems";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Modal, Button, Tooltip, Checkbox } from "antd";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import striptags from "striptags";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import InterventionLocationDropDown from "./interventionLocationDropDown";
import CategoryPersonnelDropdown from "../../../../administration/personnels/category-personnels/categoryPersonnelDropdown";
import ChartControlTypeDropdown from "./chart-control-types";
import DragWindow from "../../drag-window";
import CheckList from "./check-list/index";
import SelectTypeList from "./type-list";
import StartDayDropdown from "./startDay-dropdown";
import EndDayDropdown from "./endDay-dropdown";
import TextEditorSection from "./text-editor-section";
import AdditionalInfoView from "./edit-view";
import AdditionalInfoEditChecklist from "./edit-checklist-items";
class AdditionalInfoEdit extends Component {
	constructor(props) {
		super(props);
		this.state = {
			problemDetails: {
				interventionDetails: EditorState.createEmpty(),
				expectedResults: EditorState.createEmpty(),
				argumentationAndFoundations: EditorState.createEmpty(),
				chartControlType: "NONE"
			},
			category: "additionalInfo",
			invalidData: false,
			stepsAndSubsteps: [],
			isDoctorSelected: null,
			isNurseSelected: null,
			checkList: [],
			startDayOptions: [],
			endDayOptions: [],
			filterData: false,
			loading: true,
			infoText: "",
			addButton: true,
			windowType: "Edit",
			applyStepsToAll: false,
			invalidDuration: false,
			totalTasks: this.props.problemDetails.additionalInfoCount

		};
	}

	UNSAFE_componentWillMount() {
		const props = {
			id: this.props.match.params.id,
			organization: this.props.match.params.organization,
			problemId: this.props.problemId,
			problemMappingId: this.props.selectedAdditionalInfo.id
		};
		this.props.dispatch(ClinicalPathwayProblemAction.getOneAdditionalInfo(props));

		setTimeout(() => {
			this.setState((prevState) => ({ ...prevState, eidtorVisible: true }));
		}, 700);
	}

	onEditorStateChange(value, editorState) {
		const prevState = this.state;
		prevState.invalidData = false;
		let interventionSummaryName;
		if (value === "interventionDetails") {
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.interventionDetails = editorState;
				if (!this.state.isInterventionSummaryName) {
					interventionSummaryName = striptags(draftToHtml(convertToRaw(prevState.problemDetails.interventionDetails.getCurrentContent())));
					prevState.problemDetails.interventionSummaryName = interventionSummaryName.replace(/\&nbsp;/g, " ").replace(/\n/g, "").slice(0, 128).trim();
					prevState.invalidSummaryName = false;
				}
			}
		}
		if (value === "expectedResults") {
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.expectedResults = editorState;
			}
		}
		if (value === "argumentationAndFoundations") {
			if (this.getLength(editorState) <= utils.editorMaxLength) {
				prevState.problemDetails.argumentationAndFoundations = editorState;
			}
		}
		prevState.invalidMedicalPrescription = false;
		this.setState(prevState);
	}

	getLength(editorState) {
		return (draftToHtml(convertToRaw(editorState.getCurrentContent())).length);
	}

	medicalPrescriptionActive() {
		const prevState = this.state;
		if (this.state.problemDetails.interventionDetails && this.state.problemDetails.interventionDetails.getCurrentContent().hasText() && this.state.selectedRadio != null) {
			prevState.invalidMedicalPrescription = false;
		}
		this.setState(prevState);
	}

	init(data, type) {
		const contentBlock = htmlToDraft(data ? data : "");
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			this.state.problemDetails[type] = EditorState.createWithContent(contentState);
		}
	}

	validate() {
		const prevState = this.state;
		if (!(this.state.problemDetails.interventionSummaryName && this.state.problemDetails.interventionSummaryName.trim())) {
			prevState.invalidSummaryName = true;
		} else if (!utils.checkNameRegex(this.state.problemDetails.interventionSummaryName)) {
			prevState.invalidSummaryName = true;
		}
		if (!this.props.clinicalPathwayData.isCheckList && this.state.problemDetails.interventionLocation.length < 1) {
			prevState.invalidLocation = true;
		}
		if (!this.state.problemDetails.categoryPersonnelName || this.state.problemDetails.categoryPersonnelName.length < 1) {
			prevState.invalidCategoryPersonnelName = true;
		}
		// if (this.state.problemDetails.interventionDetails && this.state.problemDetails.interventionDetails.getCurrentContent().hasText() && !this.state.isNurseSelected && !this.state.isDoctorSelected) {
		// 	prevState.invalidMedicalPrescription = true;
		// }
		if (this.state.windowType != "Partially" & !this.state.isNurseSelected && !this.state.isDoctorSelected) {
			prevState.invalidMedicalPrescription = true;
		}
		if (this.state.problemDetails.duration.length < 1) {
			prevState.invalidDuration = true;
		}
		this.setState(prevState);
		if (this.state.invalidSummaryName || this.state.invalidLocation || this.state.invalidCategoryPersonnelName || this.state.invalidMedicalPrescription || this.state.invalidDuration) {
			return false;
		}
		return true;
	}

	selectedSubStep() {
		const clpSubStepIds = [];
		this.state.problemDetails.interventionLocation.map(data => {
			clpSubStepIds.push({ clpSubStepId: data.clpSubStepId, day: data.day ? data.day : null });
		});
		return clpSubStepIds;
	}

	selectedCategoryPersonnelIds() {
		const categoryPersonnelIds = [];
		this.state.problemDetails.categoryPersonnelName.map(data => {
			categoryPersonnelIds.push(data.value);
		});
		return categoryPersonnelIds;
	}

	chartControlTypeChanged(e) {
		const prevState = this.state;
		prevState.problemDetails.chartControlType = e;
		this.setState(prevState);
	}

	stripData(dataObj) {
		let flag = false;
		dataObj.blocks.map((obj, index) => {
			if (flag == false) {
				if (obj.text.replace(/\n/g, "").replace("<br>", "").trim() != "") {
					dataObj.blocks[index].text = obj.text.replace(/\n/g, "").replace("<br>", "");
					flag = true;
					return dataObj;
				} else {
					delete dataObj.blocks[index];
				}
			}
		});

		return dataObj;
	}

	selectedCheckList() {
		const checkListIds = [];
		this.state.checkList.map(data => {
			checkListIds.push({ checkListTreatmentId: data.value, checkListProblemId: data.checkListProblemId });
		});
		return checkListIds;
	}

	onClickSave() {
		if (this.state.checkList.length > 0) {
			const props = {
				id: this.props.match.params.id,
				organization: this.props.match.params.organization,
				problemId: this.props.problemId,
				problemMappingId: this.props.selectedAdditionalInfo.id
			};
			const payload = {
				checkListLinkData: this.selectedCheckList(),
				subStepMapping: this.selectedSubStep()
			};
			this.setState({ loading: true });
			this.props.dispatch(ClinicalPathwayProblemAction.replaceByCheckList(payload, props));
		}
		else if (this.validate()) {
			const props = {
				id: this.props.match.params.id,
				organization: this.props.match.params.organization,
				problemId: this.props.problemId,
				problemMappingId: this.props.selectedAdditionalInfo.id
			};
			const payload = {
				interventionSummaryName: this.state.problemDetails.interventionSummaryName ? this.state.problemDetails.interventionSummaryName.trim().charAt(0).toUpperCase() + this.state.problemDetails.interventionSummaryName.trim().slice(1) : "",
				interventionDetails: this.state.problemDetails.interventionDetails && this.state.problemDetails.interventionDetails.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.interventionDetails.getCurrentContent()))) : "",
				expectedResults: this.state.problemDetails.expectedResults && this.state.problemDetails.expectedResults.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.expectedResults.getCurrentContent()))) : "",
				argumentationAndFoundations: this.state.problemDetails.argumentationAndFoundations && this.state.problemDetails.argumentationAndFoundations.getCurrentContent().hasText() ? draftToHtml(this.stripData(convertToRaw(this.state.problemDetails.argumentationAndFoundations.getCurrentContent()))) : "",
				subStepMapping: this.selectedSubStep(),
				categoryPersonnelIds: this.selectedCategoryPersonnelIds(),
				chartControlType: this.state.problemDetails.chartControlType,
				nurseAndDoctor: this.state.isNurseSelected && this.state.isDoctorSelected ? "ALL" : this.state.isNurseSelected ? "NURSE" : this.state.isDoctorSelected ? "DOCTOR" : "",
				sectionType: this.props.problemDetails.stepSectionType,
				applyStepsToAll: this.state.applyStepsToAll,
				duration: this.state.problemDetails.duration
			};
			this.setState({ loading: true });
			this.props.dispatch(ClinicalPathwayProblemAction.updateAdditionalInfo(payload, props));
		} else if (this.state.windowType == "Partially") {
			utils.showInfo("Select location of the intervention");
			this.setState({ loading: false });
		}
	}

	onSummaryChange(value) {
		const prevState = this.state;
		prevState.problemDetails.interventionSummaryName = value;
		if (value) {
			prevState.invalidSummaryName = false;
			prevState.isInterventionSummaryName = true;
		}
		else {
			prevState.isInterventionSummaryName = false;
		}
		this.setState(prevState);
	}

	categoryPersonnelNameChanged(e, filterData) {
		const prevState = this.state;
		prevState.filterData = filterData === true ? filterData : false;
		if (e && e.length < 6) {
			prevState.problemDetails.categoryPersonnelName = e;
			prevState.invalidCategoryPersonnelName = false;
			this.setState(prevState);
		}
	}

	onLocationChange(value) {
		const prevState = this.state;
		prevState.problemDetails["interventionLocation"] = value;
		if (!value.length) {
			prevState.startDay = null;
			prevState.endDayOptions = [];
			prevState.endDay = null;
		}
		prevState.invalidLocation = false;
		this.setState(prevState);
	}

	formatSteps = data => {
		return `${i18Get(data.stepName, utils.getLanguage())} -> ${data.day ? i18Get("DAY", utils.getLanguage()) : ""} ${data.subStepName}`;
	};

	onCheckboxChanged(value) {
		const prevState = this.state;
		prevState.invalidMedicalPrescription = false;
		if (value === "interventionsMedicalPrescription") {
			prevState.isDoctorSelected = !prevState.isDoctorSelected;
		}
		if (value === "interventionsByNursesOrProfessionals") {
			prevState.isNurseSelected = !prevState.isNurseSelected;
		}
		// prevState.problemDetails.interventionDetails = prevState.problemDetails.interventionDetails;
		if (!this.state.isInterventionSummaryName) {
			const interventionSummaryName = prevState.problemDetails.interventionDetails ? striptags(draftToHtml(convertToRaw(prevState.problemDetails.interventionDetails.getCurrentContent()))) : "";
			if (interventionSummaryName) {
				prevState.problemDetails.interventionSummaryName = interventionSummaryName.replace(/\&nbsp;/g, " ").replace(/\n/g, "").slice(0, 128).trim();
			}
			else {
				prevState.problemDetails.interventionSummaryName = "";
			}
		}
		this.medicalPrescriptionActive();
		this.setState(prevState);
	}

	renderDayOptions = e => {
		this.state.startDayOptions = e;
		this.setState(this.state);
	};
	startDayChanged(e) {
		let filteredData = [];
		const prevState = this.state;
		if (e) {
			const list = JSON.parse(JSON.stringify(this.state.startDayOptions));
			prevState.endDayOptions = list.splice(list.findIndex(x => x.value === e.value));
		}
		else {
			prevState.endDayOptions = [];
			filteredData = prevState.problemDetails.interventionLocation.filter(item1 => item1.clpSubStepId !== prevState.startDay.clpSubStepId);
			prevState.problemDetails.interventionLocation = [...filteredData];
		}
		prevState.startDay = e;
		prevState.endDay = null;
		this.setState(prevState);
	}

	endDayChanged(e) {
		let filteredData = [];
		const prevState = this.state;
		prevState.endDay = e;
		if (prevState.startDay && prevState.endDay) {
			const list = JSON.parse(JSON.stringify(this.state.startDayOptions));
			const startDayIndex = list.findIndex(x => x.value === prevState.startDay.value);
			const endDayIndex = list.findIndex(x => x.value === prevState.endDay.value);
			const data = list.slice(startDayIndex, endDayIndex + 1);
			const interventionLocationList = JSON.parse(JSON.stringify(prevState.problemDetails.interventionLocation));
			filteredData = interventionLocationList.filter(item1 => item1.clpSubStepId !== prevState.endDay.clpSubStepId);
			prevState.problemDetails.interventionLocation = [...filteredData, ...data];
		}
		this.setState(prevState);
	}

	onCheckListChange = e => {
		this.state.checkList = e;
		this.state.invalidCheckList = false;
		this.setState(this.state);
	};

	tabChanged(category) {
		if (this.state.category !== category) {
			const prevState = this.state;
			prevState.category = category;
			this.setState(prevState);
		}
	}

	dataChanged(e) {
		if (e.length <= 255) {
			const data = utils.clpTasksReplaceCharsBasedOnAscii(e);
			this.onSummaryChange(data);
			this.setState({ filterData: true });
		}
	}

	itemChanged(e) {
		const prevState = this.state;
		this.categoryPersonnelNameChanged(e.responsible, true);
		this.setState(prevState);
	}

	onApplyStepsToAll(e) {
		const prevState = this.state;
		prevState.applyStepsToAll = e.target.checked;
		this.setState(prevState);
	}

	modelBodyHandler() {
		if (this.props.clinicalPathwayData.isModel && this.state.problemDetails.checkListTreatmentId) {
			//view only
			this.state.windowType = "View";
			this.state.infoText = "This task can't be edited as this is the part of a Modal";
			this.state.addButton = false;

		} else if (this.props.clinicalPathwayData.isModel && !this.state.problemDetails.checkListTreatmentId) {
			//Edit window
			this.state.windowType = "Edit";
			this.state.infoText = "";
			this.state.addButton = true;
		} else if (this.state.problemDetails.modelTreatmentId && this.state.problemDetails.checkListTreatmentId) {
			//Partially Editable
			this.state.windowType = "Partially";
			this.state.infoText = "You can set/update only the steps of tasks as it is part of the checklist";
			this.state.addButton = true;
		} else if (this.state.problemDetails.modelTreatmentId && !this.state.problemDetails.checkListTreatmentId) {
			//view only
			this.state.windowType = "View";
			this.state.infoText = "This task can't be edited as this is the part of a Modal";
			this.state.addButton = false;
		} else if (!this.state.problemDetails.modelTreatmentId && this.state.problemDetails.checkListTreatmentId) {
			//view only
			this.state.windowType = "Partially";
			this.state.infoText = "You can set/update only the steps of tasks as it is part of the checklist";
			this.state.addButton = true;
		}
	}

	disableButtons() {
		return this.state.category === "checkList" && this.state.totalTasks == 1 && !this.props.problemDetails.isCheckList ? true : false;
	}

	durationChanged(e) {
		const prevState = this.state;
		if (/^[0-9]*$/.test(e)) {
			prevState.problemDetails.duration = e;
			prevState.invalidDuration = false;
		}
		this.setState(prevState);
	}

	render() {
		if (this.props.clinicalPathwayProblems.getOneAdditionalInfo === "FULFILLED") {
			this.state.problemDetails = this.props.clinicalPathwayProblems.additionalInfoDetails;
			if (this.state.problemDetails.interventionSummaryName) {
				this.state.isInterventionSummaryName = true;
			}
			this.state.loading = false;
			let data = {};
			this.state.problemDetails["interventionLocation"] = [];
			this.state.filterData = true;
			this.state.problemDetails.subStepMapping && this.state.problemDetails.subStepMapping.map((value) => {
				if (value.clpSubStepId) {
					data = {
						day: value.day,
						clpSubStepId: value.clpSubStepId,
						value: value.clpSubStepId + value.day,
						label: this.formatSteps(value)
					};
					this.state.problemDetails["interventionLocation"].push(data);
				}
			});
			this.state.problemDetails["categoryPersonnelName"] = [];
			this.state.problemDetails.categoryPersonnels && this.state.problemDetails.categoryPersonnels.map((value) => {
				data = {
					value: value.categoryPersonnelId,
					label: value.categoryPersonnelName
				};
				this.state.problemDetails["categoryPersonnelName"].push(data);
			});
			if (this.state.problemDetails.interventionDetails && typeof this.state.problemDetails.interventionDetails !== "object") {
				this.init(this.state.problemDetails.interventionDetails, "interventionDetails");
				// this.state.problemDetails.interventionDetails = this.state.problemDetails.interventionDetails;
			}

			if (this.state.problemDetails.nurseAndDoctor === "ALL") {
				this.state.isDoctorSelected = true;
				this.state.isNurseSelected = true;
			} else if (this.state.problemDetails.nurseAndDoctor === "DOCTOR") {
				this.state.isDoctorSelected = true;
			} else if (this.state.problemDetails.nurseAndDoctor === "NURSE") {
				this.state.isNurseSelected = true;
			}
			if (this.state.problemDetails.expectedResults && typeof this.state.problemDetails.expectedResults !== "object") {
				this.init(this.state.problemDetails.expectedResults, "expectedResults");
			}
			if (this.state.problemDetails.argumentationAndFoundations && typeof this.state.problemDetails.argumentationAndFoundations !== "object") {
				this.init(this.state.problemDetails.argumentationAndFoundations, "argumentationAndFoundations");
			}
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetOneAdditionalInfo());
		}

		if (this.props.clinicalPathwayProblems.getOneAdditionalInfo === "REJECTED") {
			this.props.dispatch(ClinicalPathwayProblemAction.clearGetOneAdditionalInfo());
		}

		if (this.props.clinicalPathwayProblems.editAdditionalProblemStatus === "FULFILLED") {
			this.setState({ loading: false });
			this.props.onClickCancel();
			this.props.reloadData();
			this.props.dispatch(ClinicalPathwayProblemAction.clearUpdateAdditionalInfo());
		}
		if (this.props.clinicalPathwayProblems.editAdditionalProblemStatus === "REJECTED") {
			this.setState({ loading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearUpdateAdditionalInfo());
		}

		if (this.props.clinicalPathwayProblems.replaceByCheckListStatus === "FULFILLED") {
			this.setState({ loading: false });
			this.props.onClickCancel();
			this.props.reloadData();
			this.props.dispatch(ClinicalPathwayProblemAction.clearReplaceByCheckList());
		}

		if (this.props.clinicalPathwayProblems.replaceByCheckListStatus === "REJECTED") {
			this.setState({ loading: false });
			this.props.dispatch(ClinicalPathwayProblemAction.clearReplaceByCheckList());
		}

		const title = <div className="position_relative"><DragWindow title={i18Get("Edit Additional Info of", utils.getLanguage()) + " " + i18Get(this.props.problemDetails.type ? `${this.props.problemDetails.type} ` : "", utils.getLanguage()) + `( ${this.props.problemDetails.problem} )`} /> </div>;
		this.modelBodyHandler();
		return (
			<div>
				<Modal
					className="re_assign_modal create_problem create_additional"
					title={title}
					visible={this.props.editAdditionalInfo}
					onCancel={this.props.onClickCancel}
					wrapClassName="drag_modal"
					footer={[
						(this.state.problemDetails.modelTreatmentId && this.state.windowType == "View") && <span className='information w_80_max'><b><I18 tkey={"Model Name: "} /></b><I18 tkey={`${this.state.problemDetails.modelHospitalName ? this.state.problemDetails.modelHospitalName + " " : ""}${this.state.problemDetails.modelTreatmentName}`} /><i>(<I18 tkey={this.state.infoText} />)!</i></span>,
						(this.state.infoText && this.state.windowType == "Partially") && <span className='information w_80_max'><i><I18 tkey={this.state.infoText} />!</i></span>,
						this.state.addButton && <Button key="submit" type="primary" loading={this.state.loading} onClick={this.onClickSave.bind(this)} disabled={this.disableButtons()}>
							<span className="margin-left-10" >
								<I18 tkey="Save" />
							</span>
						</Button>,
						<Button key="back" onClick={this.props.onClickCancel} disabled={this.disableButtons()} >
							<I18 tkey="Cancel" />
						</Button>
					]}>
					{
						this.state.windowType == "Partially" ? <AdditionalInfoEditChecklist
							clinicalPathwayData={this.props.clinicalPathwayData}
							category={this.state.category}
							problemDetails={this.state.problemDetails}
							startDayOptions={this.state.startDayOptions}
							startDay={this.state.startDay}
							endDayOptions={this.state.endDayOptions}
							endDay={this.state.endDay}
							isNurseSelected={this.state.isNurseSelected}
							isDoctorSelected={this.state.isDoctorSelected}
							problemId={this.props.problemId}
							checkList={this.state.checkList}
							onApplyStepsToAll={this.onApplyStepsToAll.bind(this)}
							intervention={
								<InterventionLocationDropDown
									data={this.state.problemDetails.interventionLocation ? this.state.problemDetails.interventionLocation : null}
									onChange={this.onLocationChange.bind(this)}
									isEdit={true}
									additionalInfoId={this.state.problemDetails.id ? this.state.problemDetails.id : ""}
									stepSectionType={this.props.problemDetails.stepSectionType}
									problemId={this.props.problemId}
									renderDayOptions={this.renderDayOptions}
									isCreate={true}
								/>
							}

						/>
							:
							this.state.windowType == "View" ? <AdditionalInfoView
								clinicalPathwayData={this.props.clinicalPathwayData}
								category={this.state.category}
								problemDetails={this.state.problemDetails}
								startDayOptions={this.state.startDayOptions}
								startDay={this.state.startDay}
								endDayOptions={this.state.endDayOptions}
								endDay={this.state.endDay}
								isNurseSelected={this.state.isNurseSelected}
								isDoctorSelected={this.state.isDoctorSelected}
								problemId={this.props.problemId}
								checkList={this.state.checkList}

							/> :
								<div className="">
									{!this.props.clinicalPathwayData.isCheckList && !this.props.clinicalPathwayData.isModel &&
										<ul className="nav nav-tabs clinical_pathway_tabs">
											<li className={this.state.category === "additionalInfo" ? "active" : ""}>
												<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "additionalInfo")}>
													<I18 tkey="Additional Info" />
												</a>
											</li>
											<li className={this.state.category === "checkList" ? "active" : ""}>
												<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" onClick={this.tabChanged.bind(this, "checkList")}>
													<I18 tkey="Check List" />
												</a>
											</li>
										</ul>
									}
									{this.state.category === "additionalInfo" &&
										<div className="row">
											<div className="di padding-left-5  col-md-7">
												<div className="">
													<Tooltip placement="topRight" title={i18Get("Choisissez un nom de l’intervention comportant quelques mots.", utils.getLanguage())}>
														<label className='margin-bottom-0'><I18 tkey='Intervention Summary Name' />:</label>
													</Tooltip>
												</div>
												<div className="">
													<div className="w_100 di position_relative">
														<Tooltip placement="topRight" title={i18Get("Choisissez un nom de l’intervention comportant quelques mots.", utils.getLanguage())}>
															<div className="">
																<SelectTypeList
																	data={this.state.problemDetails.interventionSummaryName}
																	dataChanged={this.dataChanged.bind(this)}
																	itemChanged={this.itemChanged.bind(this)}
																/>
															</div>
														</Tooltip>
														{this.state.invalidSummaryName &&
															<span className='invalid_clinical_pathway_bottom invalid_color' style={{ "bottom": "-9px" }}>
																<I18 tkey={"Enter valid intervention summary name (not allowed except ( ) + _ - ' ,. : ;)"} />
															</span>}
														{this.state.invalidDuration &&
															<span className='invalid_clinical_pathway_bottom invalid_color' style={{ "bottom": "-23px" }}>
																<I18 tkey={"Enter valid duration"} />
															</span>}
													</div>
												</div>
											</div>
											<div className="di padding-left-5  col-md-1">
												<div className="">
													<label className='margin-bottom-0'><I18 tkey='Dur (mn)' />:</label>
												</div>
												<div className="">
													<div className="w_100 di position_relative">
														<div className="">
															<input
																className="filter_input_design form-control"
																value={this.state.problemDetails.duration}
																onChange={(e) => { this.durationChanged( e.target.value); }}
																maxLength={3}
																pattern="[0-9]*"
															/>	
														</div>
													</div>
												</div>
											</div>
											<div className="di  col-md-4">
												<div className="">
													<label className='margin-bottom-0'><I18 tkey='Intervenant' />:</label>
												</div>
												<div className="">
													<div className="w_100 di position_relative text_editor_z_index_select">
														<div className="">
															<CategoryPersonnelDropdown
																data={this.state.problemDetails.categoryPersonnelName}
																categoryPersonnelChanged={this.categoryPersonnelNameChanged.bind(this)}
																clinicalPathway={true}
																filterData={this.state.filterData}
																autoSetSingleOne={true}
															/>
														</div>

														{this.state.invalidCategoryPersonnelName ?
															<span className='invalid_clinical_pathway invalid_color'>
																<I18 tkey={"Select Intervenant"} />
															</span>
															:
															<span className='info_text'>
																<I18 tkey={"Please check the type of intervenant"} />
															</span>
														}

													</div>
												</div>
											</div>
											{!this.props.clinicalPathwayData.isCheckList &&
												<div className="di padding-left-5 padding-bottom-5 col-md-8 z_index_100 margin-top--7">
													<div className={this.state.invalidLocation ? "padding_bottom_20" : ""}>
														<label className={`margin-bottom-0 ${!this.props.problemDetails.stepSectionType ? "margin-top-5" : ""}`}><I18 tkey='Location of the intervention' /></label>
														{!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" && <table className='float_right_i margin-top--5'>
															<tr>
																<td className='padding-right-5 w_45_px'><label>&nbsp;<I18 tkey='From' />:</label></td>
																<td className='padding-right-5'>
																	<div className="w_145_px di position_relative">
																		<StartDayDropdown
																			startDayOptions={this.state.startDayOptions}
																			startDay={this.state.startDay}
																			startDayChanged={this.startDayChanged.bind(this)}
																		/>
																	</div>
																</td>
																<td className='padding-left-5 padding-right-5 w_30_px'><label><I18 tkey='To' />:</label></td>
																<td className="w_145_px">
																	<div className="w_145_px di position_relative float_right">
																		<EndDayDropdown
																			endDayOptions={this.state.endDayOptions}
																			endDay={this.state.endDay}
																			endDayChanged={this.endDayChanged.bind(this)}
																		/>
																	</div>
																</td>
															</tr>
														</table>}
													</div>
													<div className="" >
														{!this.props.clinicalPathwayData.isCheckList &&
															<div className="w_100 di position_relative">
																<div className={`${!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" ? "margin-top-5" : ""}`}>
																	<InterventionLocationDropDown
																		data={this.state.problemDetails.interventionLocation ? this.state.problemDetails.interventionLocation : null}
																		onChange={this.onLocationChange.bind(this)}
																		isEdit={true}
																		additionalInfoId={this.state.problemDetails.id ? this.state.problemDetails.id : ""}
																		stepSectionType={this.props.problemDetails.stepSectionType}
																		problemId={this.props.problemId}
																		renderDayOptions={this.renderDayOptions}
																		isCreate={true}
																	/>
																</div>
																{this.state.invalidLocation && <span className='invalid_clinical_pathway invalid_color' ><I18 tkey={"Select a valid location of the intervention"} /></span>}
															</div>}
													</div>
												</div>}
											<div className={`di padding-bottom-5 ${this.props.clinicalPathwayData.isCheckList ? "col-md-12 padding-left-5 margin-top--20" : " col-md-4"}`}>
												<div className="">
													<label className='margin-bottom-0'><I18 tkey='Chart Control Type' />:</label>
												</div>
												<div className="">
													<div className="w_100 di position_relative">
														<ChartControlTypeDropdown
															createClass="no_class"
															data={this.state.problemDetails.chartControlType}
															chartControlTypeChanged={this.chartControlTypeChanged.bind(this)}
														/>
													</div>
												</div>
											</div>
											<div className="padding-left-5 col-md-6">
												<div className="w_100 di d-flex align-items-center">
													<Checkbox checked={this.state.isDoctorSelected} onChange={this.onCheckboxChanged.bind(this, "interventionsMedicalPrescription")}>
														<I18 tkey={"Medical interventions or on medical prescription"} />
														<img className="interventions_icon_doctor" src="/images/doctor.png" />
													</Checkbox>
												</div>
											</div>
											<div className="padding-left-5 col-md-6">
												<div className="w_100 di d-flex align-items-center">
													<Checkbox checked={this.state.isNurseSelected} onChange={this.onCheckboxChanged.bind(this, "interventionsByNursesOrProfessionals")}>
														<I18 tkey={"Interventions By Nurses Or Professionals"} />
														<img className="interventions_icon_nurses" src="/images/nurses.png" />
													</Checkbox>
												</div>
											</div>
											{this.state.eidtorVisible ? 
											<TextEditorSection
												onEditorStateChange={this.onEditorStateChange.bind(this)}
												problemDetails={this.state.problemDetails}
												isDoctorSelected={this.state.isDoctorSelected}
												invalidMedicalPrescription={this.state.invalidMedicalPrescription}
											/> : "" }
										</div>}
									{this.state.category === "checkList" ?
										(this.props.problemDetails.editAdditionalInfoIndex == 0 || this.state.totalTasks == 1) && !this.props.problemDetails.isCheckList ?
											<span className='information w_80_max notice_message'><I18 tkey="Sorry, you can't add a checklist as the first task in a problem" /></span>
											:
											<div>
												<div className="di padding-left-5 padding-bottom-20 col-md-8">
													<div className="">
														<label className='margin-bottom-0'><I18 tkey='Check List' />:</label>
													</div>
													<div className="">
														<div className="w_100 di position_relative text_editor_z_index_select">
															<CheckList
																data={this.state.checkList ? this.state.checkList : null}
																onChange={this.onCheckListChange}
																problemId={this.props.problemId}
																onRef={ref => (this.child = ref)}
																clinicalPathwayData={this.props.clinicalPathwayData}
															/>
														</div>
													</div>
												</div>
												<div className="di padding-left-5 padding-bottom-5 col-md-8 z_index_100 margin-top--7 z-index-1 min-height-260">
													<div className={this.state.invalidLocation ? "padding_bottom_20" : ""}>
														<label className={` margin-bottom-0 ${!this.props.problemDetails.stepSectionType ? "margin-top-5" : ""}`}>{!this.props.clinicalPathwayData.isCheckList ? <I18 tkey='Location of the intervention' /> : ""}</label>
														{!this.props.clinicalPathwayData.isCheckList && this.props.problemDetails.stepSectionType === "CLINICAL_PATHWAY" && <table className='float_right_i margin-top--5'>
															<tr>
																<td className='padding-right-5 w_45_px'><label>&nbsp;<I18 tkey='From' />:</label></td>
																<td className='padding-right-5'>
																	<div className="w_145_px di position_relative">
																		<StartDayDropdown
																			startDayOptions={this.state.startDayOptions}
																			startDay={this.state.startDay}
																			startDayChanged={this.startDayChanged.bind(this)}
																		/>
																	</div>
																</td>
																<td className='padding-left-5 padding-right-5 w_30_px'><label><I18 tkey='To' />:</label></td>
																<td className="w_145_px">
																	<div className="w_145_px di position_relative float_right">
																		<EndDayDropdown
																			endDayOptions={this.state.endDayOptions}
																			endDay={this.state.endDay}
																			endDayChanged={this.endDayChanged.bind(this)}
																		/>
																	</div>
																</td>
															</tr>
														</table>}
													</div>
													<div className="">
														<div className="w_100 di position_relative">
															<div className="margin-top-5">
																{!this.props.clinicalPathwayData.isCheckList &&
																	<InterventionLocationDropDown
																		data={this.state.problemDetails.interventionLocation ? this.state.problemDetails.interventionLocation : null}
																		onChange={this.onLocationChange.bind(this)}
																		stepSectionType={this.props.problemDetails.stepSectionType}
																		problemId={this.props.problemId}
																		renderDayOptions={this.renderDayOptions}
																		isCreate={true}
																	/>
																}
															</div>
															{this.state.invalidLocation && <span className='invalid_clinical_pathway invalid_color' >
																<I18 tkey={"Select a valid location of the intervention"} />
															</span>}
														</div>
													</div>
												</div>
											</div> : ""}
								</div>
					}
				</Modal>
			</div>
		);
	}
}

AdditionalInfoEdit.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(AdditionalInfoEdit));