import { connect } from "react-redux";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import I18, { i18Get } from "../../../../../../../../i18";
import utils from "../../../../../../../../utils/utils";
import { Tooltip, Checkbox, Input } from "antd";
import TextEditorSection from "./text-editor-section";
import Select from "react-select";

class AdditionalInfoEditFromChecklist extends Component {
	constructor(props) {
		super(props);
		this.state = {
			category: "additionalInfo"
		};
	}
	render() {
		return (

			<div className="">

				<ul className="nav nav-tabs clinical_pathway_tabs">
					<li className={this.state.category === "additionalInfo" ? "active" : ""}>
						<a className="tab_section padding-top-15 di w_100" data-toggle="tab" href="#home" >
							<I18 tkey="Additional Info" />
						</a>
					</li>
				</ul>
				<div className="row">
					<div className="di padding-left-5  col-md-7">
						<div className="">
							<Tooltip placement="topRight" title={i18Get("Choisissez un nom de l’intervention comportant quelques mots.", utils.getLanguage())}>
								<label className='margin-bottom-0'><I18 tkey='Intervention Summary Name' />:</label>
							</Tooltip>
						</div>
						<div className="">
							<div className="w_100 di position_relative">
								<Tooltip placement="topRight" title={i18Get("Choisissez un nom de l’intervention comportant quelques mots.", utils.getLanguage())}>
									<div className="">
										<div className="w_100 di  position_relative text_editor_z_index_select">
											<Input
												className="w_100 di"
												value={this.props.problemDetails.interventionSummaryName}
												placeholder={i18Get("No Data Found", utils.getLanguage())}
												disabled={true}
											/>
										</div>
									</div>
								</Tooltip>
								{this.props.invalidSummaryName &&
									<span className='invalid_clinical_pathway invalid_color'>
										<I18 tkey={"Enter valid intervention summary name (not allowed except ( ) + _ - ' ,.)"} />
									</span>}
							</div>
						</div>
					</div>
					<div className="di padding-left-5  col-md-1">
						<div className="">
							<label className='margin-bottom-0'><I18 tkey='Dur (mn)' />:</label>
						</div>
						<div className="">
							<div className="w_100 di position_relative">
								<div className="">
									<input
										className="filter_input_design form-control"
										value={this.props.problemDetails.duration}
										disabled={true}
									/>	
								</div>
							</div>
						</div>
					</div>
					<div className="di col-md-4">
						<div className="">
							<label className='margin-bottom-0'><I18 tkey='Intervenant' />:</label>
						</div>
						<div className="">
							<div className="w_100 di position_relative text_editor_z_index_select">
								<div className="">
									<div className="w_100 di  position_relative text_editor_z_index_select">
										<Select
											className="w_100 di"
											options={this.props.problemDetails.categoryPersonnelName ? this.props.problemDetails.categoryPersonnelName : []}
											disabled
											value={null}
											placeholder={i18Get(`${this.props.problemDetails.categoryPersonnelName ? this.props.problemDetails.categoryPersonnelName.length + "-Item Selected" : "No Data Found"}`, utils.getLanguage())}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="di padding-left-5  col-md-8 z_index_100">
						<div className={this.props.invalidLocation ? "padding_bottom_20" : ""}>
							<label className='margin-bottom-0'><I18 tkey='Location of the intervention' />:</label>
						</div>
						<div className="" >
							<div className="w_100 di position_relative">
								<div className="w_100 checkList-edit-apply-to-all-option margin-top--22">
									<I18 tkey={"Apply this steps to all taskes in this checklist"} />
									<Checkbox onChange={(e) => { this.props.onApplyStepsToAll(e); }}></Checkbox>
								</div>

								<div className="">
									<div className="w_100 di  position_relative  text_editor_z_index_select">
										{this.props.intervention}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="di col-md-4">
						<div className="">
							<label className='margin-bottom-0'><I18 tkey='Chart Control Type' />:</label>
						</div>
						<div className="">
							<div className="w_100 di position_relative">
								<div className="w_100 di ">
									<Input
										disabled={true}
										className="w_100 di"
										value={this.props.problemDetails.chartControlType}
										placeholder={i18Get("No Data Found", utils.getLanguage())}
									/>
								</div>
							</div>
						</div>
					</div>
					<div className="padding-left-5 col-md-6">
						<div className="w_100 di margin-top-5 d-flex align-items-center">
							<Checkbox checked={this.props.isDoctorSelected} disabled >
								<I18 tkey={"Medical interventions or on medical prescription"} />
								<img className="interventions_icon_doctor" src="/images/doctor.png" />
							</Checkbox>
						</div>
					</div>
					<div className="padding-left-5 col-md-6">
						<div className="w_100 di margin-top-5 d-flex align-items-center">
							<Checkbox checked={this.props.isNurseSelected} disabled>
								<I18 tkey={"Interventions By Nurses Or Professionals"} />
								<img className="interventions_icon_nurses" src="/images/nurses.png" />
							</Checkbox>
						</div>
					</div>
					<span className='disabled'>
						<TextEditorSection
							problemDetails={this.props.problemDetails}
							isDoctorSelected={this.props.isDoctorSelected}
							disabled={true}
						/>
					</span>
				</div>
			</div>
		);
	}
}

AdditionalInfoEditFromChecklist.propTypes = {
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	clinicalPathwayProblems: PropTypes.object.isRequired,
	actions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	clinicalPathwayProblems: store.clinicalPathwayProblems,
	actions: store.actions
}))(AdditionalInfoEditFromChecklist));