import PropTypes from "prop-types";
import { Dropdown, Menu, Checkbox, Button, Tooltip } from "antd";
import React, { Component } from "react";
import "./standard-care-chart.css";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import standardCareChartActions from "../../../../../../../actions/standard-care-chart";
import I18, { i18Get } from "../../../../../../../i18";
import Loader from "../../../../../../../plug/loader";
import { ExpandOutlined, FullscreenExitOutlined, RightCircleOutlined, DownCircleOutlined, ArrowDownOutlined } from "@ant-design/icons";
import utils from "../../../../../../../utils/utils";
import ClpStepFilter from "../clp-step-filter";
import QuickReorderData from "./quick-reorder-data";
import TopSection from "../top-section";
import isPermissionGranted from "../../../../../../../utils/permission";
import { statusList } from "../../../../../../../constants";
import { DoctorEdit, DocEdit, NurseEdit, NursePenEdit, ViewNotePad, DoctorNurseEdit, DocNurseEdit, Edit } from "../../../../../../../utils/icons";
import permissions from "../../../../../../../utils/permission";
import AdditionalInfoEdit from "../problem-section/additional-Info/edit";


const columns = ["PROBLEM", "INTERVENTION SUMMARY NAME", "INTERVENANTS", "CODE", "DURATION"];

class StandardCareChart extends Component {
	constructor(props) {
		super(props);
		this.state = {
			standardCareChartData: {},
			previousData: {},
			draggedOverData: {},
			loading: false,
			isFullScreen: false,
			treatmentId: null,
			stepSectionType: "",
			hoursTotalList: "",
			expandedProblemAndCheckListIds: [],
			editAdditionalInfo: false,
			selectedAdditionalInfo: {}, 
			problemId: 0, 
			problemDetails: [],
			editingRowId: null,
			editedDuration: ""

		};
	}

	UNSAFE_componentWillMount() {
		this.props.onRef(this);
		this.setState( { treatmentId: this.props.treatmentId });
	}

	toggleIsLoaded() {
		if (this.props.category === "standardCareChart") {
			this.fetchDataFromServer();
		}
	}

	componentDidMount() {
		this.fetchDataFromServer();
	}

	componentDidUpdate(prevProps) {
		if (prevProps.clpStepFilter !== this.props.clpStepFilter || this.props.treatmentId !== this.state.treatmentId) {
			this.setState( { treatmentId: this.props.treatmentId }, () => { this.fetchDataFromServer(); } );
		}
	}

	fetchDataFromServer() {
		let queryString = "";
		queryString += this.props.clpStepFilter ? `clpStepFilter=${this.props.clpStepFilter}` : "";
		this.setState({ loading: true });
		this.props.dispatch(standardCareChartActions.getAll(this.props.treatmentId ? this.props.treatmentId : this.props.match.params.id, queryString));
	}

	onCheckboxChange = (problemIndex, checked, type, sectionType) => {
		const prevState = this.state;
		prevState.previousData = JSON.parse(JSON.stringify(prevState.standardCareChartData));
		prevState.standardCareChartData[sectionType].problems[problemIndex].chartControlType = type;
		if (type !== "PERMANENTLY") {
			prevState.standardCareChartData[sectionType].problems[problemIndex].chartSession = new Array(prevState.standardCareChartData[sectionType].problems[problemIndex].chartSession.length).fill(0);
		}
		if (type === "PERMANENTLY") {
			prevState.standardCareChartData[sectionType].problems[problemIndex].chartSession = new Array(prevState.standardCareChartData[sectionType].problems[problemIndex].chartSession.length).fill(1);
		}
		const props = {
			additionalInfoId: prevState.standardCareChartData[sectionType].problems[problemIndex].additionalInfoId,
			problemId: prevState.standardCareChartData[sectionType].problems[problemIndex].problemId
		};
		const payload = {
			chartControlType: prevState.standardCareChartData[sectionType].problems[problemIndex].chartControlType ? prevState.standardCareChartData[sectionType].problems[problemIndex].chartControlType : "",
			chartSession: prevState.standardCareChartData[sectionType].problems[problemIndex] && prevState.standardCareChartData[sectionType].problems[problemIndex].chartSession ? prevState.standardCareChartData[sectionType].problems[problemIndex].chartSession : "",
			sectionType: prevState.standardCareChartData[sectionType].problems[problemIndex] && prevState.standardCareChartData[sectionType].problems[problemIndex].stepSectionType ? prevState.standardCareChartData[sectionType].problems[problemIndex].stepSectionType : "",
			isStandardCareChart: true
		};
		this.props.dispatch(standardCareChartActions.updateStandardCareChartDetails(this.props.match.params.id, props, payload));
		this.setState(prevState);
	};

	getMenu = (chartControlType, problemIndex, type) => (
		<Menu>
			<Menu.Item key="0">
				<Checkbox checked={chartControlType === "NONE"} onChange={e => this.onCheckboxChange(problemIndex, e.target.checked, "NONE", type)}><I18 tkey="NONE" /></Checkbox>
			</Menu.Item>
			<Menu.Item key="1">
				<Checkbox checked={chartControlType === "AS_PRESCRIBED"} onChange={e => this.onCheckboxChange(problemIndex, e.target.checked, "AS_PRESCRIBED", type)}><I18 tkey="AS PRESCRIBED" /></Checkbox>
			</Menu.Item>
			<Menu.Item key="3">
				<Checkbox checked={chartControlType === "IF_REQUIRED"} onChange={e => this.onCheckboxChange(problemIndex, e.target.checked, "IF_REQUIRED", type)}><I18 tkey="IF REQUIRED" /></Checkbox>
			</Menu.Item>
			<Menu.Item key="4">
				<Checkbox checked={chartControlType === "PERMANENTLY"} onChange={e => this.onCheckboxChange(problemIndex, e.target.checked, "PERMANENTLY", type)}><I18 tkey="PERMANENTLY" /></Checkbox>
			</Menu.Item>
		</Menu>
	);

	getDropdown = (chartControlType, problemIndex, type) => (
		<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Fréquence", utils.getLanguage())}>
			<Dropdown overlay={this.getMenu(chartControlType, problemIndex, type)} trigger={["click"]}>
				<ArrowDownOutlined />
			</Dropdown>
		</Tooltip>
	);

	styleCellBorder(data, sessionIndex) {
		if (data.headers.morning && data.headers.morning.length === sessionIndex) {
			return true;
		}
		if (data.headers.afterNoon && data.headers.afterNoon.length + data.headers.morning.length === sessionIndex) {
			return true;
		}
		if (data.headers.night && data.headers.afterNoon && data.headers.afterNoon.length + data.headers.morning.length + data.headers.night.length === sessionIndex) {
			return true;
		}
		return false;
	}

	tdClickHandler = (problem, sessionIndex, problemIndex, type) => {
		if (!this.state.enableReorder && this.isPermissionGranted("EDIT")) {
			const prevState = this.state;
			prevState.previousData = JSON.parse(JSON.stringify(prevState.standardCareChartData));
			if (prevState.standardCareChartData[type].problems[problemIndex].chartSession[sessionIndex] !== utils.sameDropper()) {
				prevState.standardCareChartData[type].problems[problemIndex].chartSession[sessionIndex] = 1 - prevState.standardCareChartData[type].problems[problemIndex].chartSession[sessionIndex];
				const props = {
					additionalInfoId: prevState.standardCareChartData[type].problems[problemIndex].additionalInfoId,
					problemId: prevState.standardCareChartData[type].problems[problemIndex].problemId
				};
				const payload = {
					chartControlType: prevState.standardCareChartData[type].problems[problemIndex].chartControlType ? prevState.standardCareChartData[type].problems[problemIndex].chartControlType : "",
					chartSession: prevState.standardCareChartData[type].problems[problemIndex] && prevState.standardCareChartData[type].problems[problemIndex].chartSession ? prevState.standardCareChartData[type].problems[problemIndex].chartSession : "",
					sectionType: prevState.standardCareChartData[type].problems[problemIndex] && prevState.standardCareChartData[type].problems[problemIndex].stepSectionType ? prevState.standardCareChartData[type].problems[problemIndex].stepSectionType : "",
					isStandardCareChart: true
				};
				this.props.dispatch(standardCareChartActions.updateStandardCareChartDetails(this.props.match.params.id, props, payload));
				this.setState({ prevState });
			}
		}
	};

	checkListTdHandler = (problem, sessionIndex, problemIndex, type, isHeading, chartSession) => {
		
		if (isHeading && this.isPermissionGranted("EDIT")) {
			const prevState = this.state;
			const checkList = [];
			prevState.previousData = JSON.parse(JSON.stringify(prevState.standardCareChartData));
			prevState.standardCareChartData[type].problems.map(data => {
				if (data.problemId === problem.problemId && data.checkListTreatmentId === problem.checkListTreatmentId && !data.isHeading) {
					if (data.chartControlType === "NONE" && chartSession === 0) {
						data.chartSession[sessionIndex] = 1;
					}
					else if (data.chartControlType === "NONE" && chartSession === 1) {
						data.chartSession[sessionIndex] = 0;
					}
					else if (data.chartControlType === "NONE" && chartSession === 2) {
						const array = [];
						prevState.previousData[type].problems.map(e => {
							if (e.problemId === problem.problemId && e.checkListTreatmentId === problem.checkListTreatmentId && !e.isHeading && e.chartControlType === "NONE") {
								array.push(JSON.parse(JSON.stringify(e.chartSession[sessionIndex])));
							}
						});
						if (array.every(e => e === 1)) {
							data.chartSession[sessionIndex] = 0;
						}
						else {
							data.chartSession[sessionIndex] = 1;
						}
					}
					checkList.push({ additionalInfoId: data.additionalInfoId, chartSession: data.chartSession, chartControlType: data.chartControlType });
				}
			});
			if (checkList.some(e => e.chartControlType === "NONE")) {
				const data = [];
				checkList.forEach(e => {
					data.push(e.chartSession[sessionIndex]);
				});
				if (data.every(e => e === 1)) {
					prevState.standardCareChartData[type].problems[problemIndex].chartSession[sessionIndex] = 1;
				}
				else if (data.every(e => e === 0)) {
					prevState.standardCareChartData[type].problems[problemIndex].chartSession[sessionIndex] = 0;
				}
				else {
					prevState.standardCareChartData[type].problems[problemIndex].chartSession[sessionIndex] = 2;
				}
				checkList.push({ additionalInfoId: prevState.standardCareChartData[type].problems[problemIndex].additionalInfoId, chartSession: prevState.standardCareChartData[type].problems[problemIndex].chartSession });
				const props = {
					problemId: prevState.standardCareChartData[type].problems[problemIndex].problemId
				};
				const payload = {
					checkListAdditionalInfoList: checkList,
					sectionType: type
				};
				this.props.dispatch(standardCareChartActions.updateCheckListStdCareChartDetails(this.props.match.params.id, props, payload));
				this.setState({ prevState });
			}
		}
	};

	toggleFullScreen = () => {
		this.setState({ ...this.state, isFullScreen: !this.state.isFullScreen });
	};

	dayHeader = (data) => {
		const list = [];
		let modeItems = 0;
		let arrayMaxLength = this.state.standardCareChartData.maxChartSessionLength / 3;
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			arrayMaxLength += Math.floor((this.state.hoursTotalList - this.state.standardCareChartData.maxChartSessionLength) / 3);
			const modParentVal = (this.state.hoursTotalList - this.state.standardCareChartData.maxChartSessionLength);
			if (modParentVal < 3) {
				modeItems = modParentVal;
			} else {
				modeItems = (this.state.hoursTotalList - this.state.standardCareChartData.maxChartSessionLength) % 3;
			}
			arrayMaxLength += modeItems;
		}
		for (let index = 0; index < arrayMaxLength; index++) {
			if (data.headers && data.headers.dayPrefix && data.headers.dayPrefix[index]) {
				list.push(<th colSpan={3} className="border_highlight"><I18 tkey="J" />{data.headers.dayPrefix[index]}</th>);
			} else {
				list.push(<th colSpan={(arrayMaxLength - index) > modeItems ? 3 : 1} className={`standard_care_chart_disable_col ${(arrayMaxLength - index) >= modeItems ? "" : "empty_cell_width"}`}><div className='disabler_chart_col'></div></th>);
			}
		}
		return list;
	};

	sectionDayHeader = (data) => {
		const day = [];
		let modeItems = 0;
		let arrayMaxLength = this.state.standardCareChartData.maxChartSessionLength / 3;
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			arrayMaxLength += Math.floor((this.state.hoursTotalList - this.state.standardCareChartData.maxChartSessionLength) / 3);
			modeItems = (this.state.hoursTotalList - this.state.standardCareChartData.maxChartSessionLength) % 3;
			arrayMaxLength += modeItems;
		}
		for (let index = 0; index < arrayMaxLength; index++) {
			if (data.headers && data.headers.dayPrefix && data.headers.dayPrefix[index]) {
				day.push(
					<React.Fragment>
						<th className="sub_header"><I18 tkey="M" /></th>
						<th className="sub_header"><I18 tkey="AM" /></th>
						<th className="border_highlight sub_header"><I18 tkey="N" /></th>
					</React.Fragment>
				);
			}
			else {
				day.push(
					<React.Fragment>
						<th className="sub_header standard_care_chart_disable_col">{(arrayMaxLength - index) >= modeItems}<div className='disabler_chart_col'></div></th>
						{(arrayMaxLength - index) > modeItems ?
							<React.Fragment>
								<th className="sub_header standard_care_chart_disable_col"><div className='disabler_chart_col'></div></th>
								<th className="sub_header standard_care_chart_disable_col"><div className='disabler_chart_col'></div></th>
							</React.Fragment>
							: ""}
					</React.Fragment>
				);
			}
		}
		return day;
	};

	// colspan condition is according to the current data in showHide list, if the list changes need to update the condition for colspan accordingly
	getDayHeader = (data) => {
		return <React.Fragment>
			<tr>
				<th className="no_cell" rowSpan={this.props.selectedColumns.check && this.props.selectedColumns.checkAll ? 4 : Object.keys(this.props.selectedColumns.check).length > 0 ? 3 : 2} colSpan={this.props.selectedColumns.check && this.props.selectedColumns.checkAll ? 5 : Object.keys(this.props.selectedColumns.check).length > 0 ? Object.keys(this.props.selectedColumns.check).length === 2 ?  (columns.length - Object.keys(this.props.selectedColumns.check).length) + 1 : (columns.length - Object.keys(this.props.selectedColumns.check).length) - 1 : 2}></th>
				{this.dayHeader(data)}
			</tr>
			<tr>
				{this.sectionDayHeader(data)}
			</tr>
		</React.Fragment>;
	};

	getHourHeader = (data) => {
		let arrayMaxLength = this.state.standardCareChartData.maxChartSessionLength;
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			arrayMaxLength = this.state.hoursTotalList;
		}
		let totalHourLength = 0;
		if (data.headers.morning) {
			totalHourLength += data.headers.morning.length;
		}
		if (data.headers.afterNoon) {
			totalHourLength += data.headers.afterNoon.length;
		}
		if (data.headers.night) {
			totalHourLength += data.headers.night.length;
		}
		return (<React.Fragment>
			<tr>
				<th className="no_cell" rowSpan={this.props.selectedColumns.check && this.props.selectedColumns.checkAll ? 4 : Object.keys(this.props.selectedColumns.check).length > 0 ? 3 : 2} colSpan={this.props.selectedColumns.check && this.props.selectedColumns.checkAll ? 5 : Object.keys(this.props.selectedColumns.check).length > 0 ? 4 : 2}></th>
				{
					data.headers.morning &&
					<th colSpan={data.headers.morning.length} className="border_highlight"><I18 tkey="Morning" /></th>
				}
				{
					data.headers.afterNoon &&
					<th colSpan={data.headers.afterNoon.length} className="border_highlight"><I18 tkey="After Noon" /></th>
				}
				{
					data.headers.night &&
					<th colSpan={data.headers.night.length} className="border_highlight"><I18 tkey="Night" /></th>
				}
				{arrayMaxLength > totalHourLength &&
					<th colSpan={arrayMaxLength - totalHourLength} className="border_highlight standard_care_chart_disable_col"><div className='disabler_chart_col'></div></th>
				}
			</tr>
			<tr>
				{this.hoursHeader(data)}
			</tr>
		</React.Fragment>);
	};

	hoursHeader = (data) => {
		const list = [];
		const sectionList = [];
		let arrayLength = "";
		if (data.headers.morning) {
			sectionList.push(...data.headers.morning);
		}
		if (data.headers.afterNoon) {
			sectionList.push(...data.headers.afterNoon);
		}
		if (data.headers.night) {
			sectionList.push(...data.headers.night);
		}
		if (sectionList.length > this.state.hoursTotalList) {
			this.setState({ ...this.state, hoursTotalList: sectionList.length });
		}
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			arrayLength = this.state.hoursTotalList;
		} else {
			arrayLength = this.state.standardCareChartData.maxChartSessionLength;
		}
		for (let index = 0; index < arrayLength; index++) {
			if (sectionList[index]) {
				list.push(<th className={sectionList.length === index + 1 ? "border_highlight sub_header" : "sub_header"}>
					{sectionList[index]}
				</th>);
			} else {
				list.push(<th className='sub_header standard_care_chart_disable_col'><div className='disabler_chart_col'></div></th>);
			}
		}
		return list;
	};

	getColumSpanCount = () => {
		let arrayMaxLength = 0;
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			arrayMaxLength = this.state.hoursTotalList;
		} else {
			arrayMaxLength = this.state.standardCareChartData.maxChartSessionLength;
		}
		return arrayMaxLength;
	};
	createHoursDivision = (data) => {
		const list = [];
		const session = Object.keys(data.headers);
		let ColmCount = 0;
		let arrayMaxLength = 0;
		for (let i = 0; session.length > i; i++) {
			const hoursSession = Object.keys(data.headers[session[i]]);
			for (let j = 0; hoursSession.length > j; j++) {
				list.push(<th colSpan={4} className="border_highlight" ><I18 tkey={hoursSession[j]} /></th>);
				ColmCount += 4;
			}
		}
		arrayMaxLength = this.getColumSpanCount();
		const colmSpan = arrayMaxLength - ColmCount;
		if (colmSpan > 0) {
			list.push(<th colSpan={colmSpan} className="border_highlight standard_care_chart_disable_col"><div className='disabler_chart_col'></div></th>);
		}
		return list;
	};

	getSubMinHeadet = (data) => {
		const list = [];
		const headerObj = Object.keys(data.headers);
		let arrayMaxLength = 0;
		let ColmCount = 0;
		for (let i = 0; headerObj.length > i; i++) {
			const colmSpan = Object.keys(data.headers[headerObj[i]]).length;
			let name = (Object.keys(data.headers)[i]);
			name = name.charAt(0).toUpperCase() + name.slice(1);
			list.push(<th className="border_highlight" colSpan={(4 * colmSpan)}  ><I18 tkey={name} /></th>);
			ColmCount += (4 * colmSpan);
		}
		arrayMaxLength = this.getColumSpanCount();
		const colmSpanCount = arrayMaxLength - ColmCount;
		if (colmSpanCount > 0) {
			list.push(<th colSpan={colmSpanCount} className="border_highlight standard_care_chart_disable_col"><div className='disabler_chart_col'></div></th>);
		}
		return list;
	};

	minHeader = (data) => {
		const list = [];
		let divission = 0;
		const session = Object.keys(data.headers);
		for (let i = 0; session.length > i; i++) {
			const hoursSession = Object.keys(data.headers[session[i]]);
			for (let j = 0; hoursSession.length > j; j++) {
				const minSession = data.headers[session[i]][hoursSession[j]];
				for (let k = 0; minSession.length > k; k++) {
					list.push(<th className="border_highlight" ><I18 tkey={minSession[k] + "M"} /></th>);
					divission++;
				}
			}
		}
		const arrayMaxLength = this.getColumSpanCount();
		const colmSpanCount = arrayMaxLength - divission;
		if (colmSpanCount > 0) {
			for (let fCount = 0; colmSpanCount > fCount; fCount++) {
				list.push(<th className="border_highlight standard_care_chart_disable_col"><div className='disabler_chart_col'></div></th>);
			}
		}
		return list;
	};
	getMinutHeader = (data) => {
		let colSpan = 2;
		if (this.props.selectedColumns.check && Object.keys(this.props.selectedColumns.check).length > 0) {
			colSpan += Object.keys(this.props.selectedColumns.check).length;
		}
		return (<React.Fragment>
			<tr>
				<th className="no_cell" rowSpan={4} colSpan={colSpan}  ></th>
			</tr>
			<tr>
				{this.getSubMinHeadet(data)}
			</tr>
			<tr>
				{this.createHoursDivision(data)}
			</tr>
			<tr>
				{this.minHeader(data)}
			</tr>
		</React.Fragment>);
	};

	getTableHead = (data) => {
		if (data) {
			if (data.isMinute)
			{return this.getMinutHeader(data);}
			else {
				return data.isHours
					? this.getHourHeader(data)
					: this.getDayHeader(data);
			}
		}
	};

	filterCheckList = (data) => {
		const index = this.state.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === data.checkListTreatmentId);
		if (index < 0) {
			return false;
		}
		return true;
	};

	getTable = (data, type) => {
		const { check, checkAll } = this.props.selectedColumns; const checkLength = Object.keys(check).length;
		
		const className = `standard_care_chart_table ${checkAll ? "forth_col_sticky third_col_sticky" : ""} ${checkLength === 1 && check.CODE ? "third_col_sticky" : ""} ${checkLength === 2 && check.CODE && check.INTERVENANTS ? "third_col_sticky_intervenants" : ""} ${checkLength === 2 && check.INTERVENANTS && check.DURATION ? "third_col_stickypp" : ""} ${checkLength === 2 && check.CODE && check.DURATION ? "third_col_sticky_code_duration" : ""} ${checkLength === 1 && check.INTERVENANTS ? "third_col_sticky_intervenants_duration" : ""} ${checkLength === 1 && check.DURATION ? "third_col_sticky_duration_only" : ""}` .trim();

		if (data.headers) {
			return (
				// <table className={`standard_care_chart_table ${this.props.selectedColumns.check && this.props.selectedColumns.checkAll ? "forth_col_sticky third_col_sticky" : ""} ${this.props.selectedColumns.check.CODE ? "standard_care_chart_table_code" : ""} ${Object.keys(this.props.selectedColumns.check).length == 1 ? "third_col_sticky" : ""}`}>
				<table className={className}>
					<thead>
						{this.sectionTypeHeader(data, type)}
						{this.getTableHead(data)}
					</thead>
					<Loader loading={this.state.loading} isTable={true} />
					<tbody>
						{data && data.problems && data.problems.map((problem, index) => {
							return <tr key={index} className='transform_scale_relative'>{this.getTableBody(problem, index, type, data.isHours, data.isMinute)}</tr>;
						})}
					</tbody>
				</table>);
		}

		if (type == "maxChartSessionLength" && data == 0) {
			return (<div className="text_align_center w_100 margin-top-30vh">NO DATA FOUND</div>);
		}
	};

	sectionTypeHeader(data, type) {
		let colSpan = "";
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			colSpan = this.state.hoursTotalList;
		}
		else {
			colSpan = this.state.standardCareChartData.maxChartSessionLength;
		}
		if (data && data.problems) {
			return (
				<tr>
					<td className="section_type_td_standard_chart" colSpan={colSpan + 2 + Object.keys(this.props.selectedColumns.check).length}>
						<div className="text-align-left w_100">
							<I18 tkey={type === "CLINICAL_PATHWAY" ? "HOSPITALISATION" : type && type.replace(/_/g, " ").toUpperCase()} />
						</div>
					</td>
				</tr>
			);
		}
	}

	expandClicked = (data) => {
		const prevState = this.state;
		prevState.expandedProblemAndCheckListIds.push({ problemId: data.problemId, checkListTreatmentId: data.checkListTreatmentId });
		this.setState(prevState);
	};

	collapseClicked = (data) => {
		const prevState = this.state;
		const index = prevState.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === data.checkListTreatmentId);
		if (index >= 0) {
			prevState.expandedProblemAndCheckListIds.splice(index, 1);
		}
		this.setState(prevState);
	};

	handleEditDuration = (uniqueId, duration) => {
		this.setState({
			editingRowId: uniqueId,
			editedDuration: duration,
			originalDuration: duration
		});
	};
	
	handleDurationChange = (e) => {
		const value = e.target.value;
		if (/^\d*$/.test(value)) {
			this.setState({ editedDuration: value });
		}
	};
	
	handleDurationBlur = (data, type) => {
		const { editedDuration } = this.state;
		const finalDuration = editedDuration === "" ? "0" : editedDuration;
		const prevState = JSON.parse(JSON.stringify(this.state));
		if (data.duration === finalDuration) {
			this.setState({ editingRowId: null });
			return;
		}
	
		if (/^\d+$/.test(finalDuration)) {
			prevState.previousData = JSON.parse(JSON.stringify(prevState.standardCareChartData));
			prevState.standardCareChartData[type]?.problems?.forEach((problem) => {

				if (problem.additionalInfoId === data.additionalInfoId) {
					problem.duration = finalDuration;
					
				}
			});
			this.setState({ standardCareChartData: prevState.standardCareChartData, editingRowId: null });
	
			const payload = {
				additionalInfoId: data.additionalInfoId,
				duration: finalDuration
			};
	
			this.props.dispatch(
				standardCareChartActions.updateStdCareChartDuration(
					this.props.match.params.id,
					data.problemId,
					payload
				)
			);
		} else {
			this.setState({ editingRowId: null });
		}
	};
	
	getTableBody = (data, problemIndex, type, isHours) => {
		const problemList = this.state.standardCareChartData[type];
		const list = [];
		let arrayLength = "";
		if (this.state.hoursTotalList > this.state.standardCareChartData.maxChartSessionLength) {
			arrayLength = this.state.hoursTotalList;
		} else {
			arrayLength = this.state.standardCareChartData.maxChartSessionLength;
		}
		for (let index = 0; index < arrayLength; index++) {
			list.push(
				<React.Fragment>
					{index === 0 &&
						<React.Fragment>
							{!data.isCheckList ?
								<td className={`${data.modelTreatmentId ? "model_attached_td" : ""}`}>
									<div className="d-flex justify-content-between align-items-center">
										<div className="text-align-left">{data.problem}</div>
									</div>
								</td>
								:
								data.isHeading || this.filterCheckList(data) ?
									<td className={`${data.modelTreatmentId ? "model_attached_td" : ""}`}>
										<div className="d-flex justify-content-between align-items-center">
											<div className="text-align-left"><span className={`check_list ${data.isHeading ? "check_list_bold" : ""}`}>{data.problem}</span></div>
										</div>
									</td>

									: ""}
							{this.props.selectedColumns && this.props.selectedColumns.check && this.props.selectedColumns.check.CODE ?
								!data.isCheckList ?
									<td className={`td_hide max_width_80 ${data.modelTreatmentId ? "model_attached_td" : ""}`}>
										<div className="d-flex justify-content-between align-items-center">
											<div className="text-align-left">{data.itemId}</div>
										</div>
									</td>
									:
									data.isHeading || this.filterCheckList(data) ?
										<td className={`td_hide max_width_80 ${data.modelTreatmentId ? "model_attached_td" : ""}`}>
											<div className="d-flex justify-content-between align-items-center">
												<div className="text-align-left"><span className={`check_list ${data.isHeading ? "check_list_bold" : ""}`}>{data.itemId}</span></div>
											</div>
										</td> : ""
								: ""}
							{!data.isCheckList ?
								<td className={` ${data.modelTreatmentId ? "model_attached_td" : "td_max_width_380"}`}>
									<div className="d-flex justify-content-between align-items-center">
										<div className="text-align-left">{data.interventionSummaryName}</div>
										<div>{data.interventionSummaryName && this.isPermissionGranted("EDIT") && !data.isCheckList ? this.getDropdown(data.chartControlType, problemIndex, type) : ""}</div>
									</div>
								</td>
								:
								(data.isHeading || this.filterCheckList(data)) ?
									<td className={` ${data.modelTreatmentId ? "model_attached_td" : "td_max_width_380"}`}>
										<div className="d-flex justify-content-between align-items-center">
											<div className="text-align-left"><span className={`check_list ${data.isHeading ? "check_list_bold" : ""}`}>{data.interventionSummaryName}</span></div>
											{data.isHeading ? this.filterCheckList(data) ? <DownCircleOutlined onClick={() => this.collapseClicked(data)} /> : <RightCircleOutlined style={{ color: "#2016b8" }} onClick={() => this.expandClicked(data)} /> : ""}
										</div>
									</td>
									: ""}
							{this.props.selectedColumns && this.props.selectedColumns.check && this.props.selectedColumns.check.INTERVENANTS ?
								!data.isCheckList ?
									<td className={`td_hide ${data.modelTreatmentId ? "model_attached_td" : ""}`}>
										<div className="d-flex justify-content-between align-items-center">
											<div className="text-align-left">{data.categoryPersonnelName}</div>
											{this.actionButtons(data)}
										</div>
									</td>
									:
									data.isHeading || this.filterCheckList(data) ?
										<td className={`td_hide ${data.modelTreatmentId ? "model_attached_td" : ""}`}>
											<div className="d-flex justify-content-between align-items-center">
												<div className="text-align-left"><span className={`check_list ${data.isHeading ? "check_list_bold" : ""}`}>{data.categoryPersonnelName}</span></div>
												{this.actionButtons(data)}
											</div>
										</td> : ""
								: ""}
							{this.props.selectedColumns && this.props.selectedColumns.check && this.props.selectedColumns.check.DURATION ?
								!data.isCheckList ?										
									<td className={`td_hide max_width_60 ${data.modelTreatmentId ? "model_attached_td" : ""}`} 		onClick={() => this.handleEditDuration(data.uniqueId, data.duration)}>
										{this.state.editingRowId === data.uniqueId ? (
                  <input
				  className={`editable-input ${data.modelTreatmentId && !data.isCheckList ? "disabled-input" : ""}`}
                  	value={this.state.editedDuration}
                  	onChange={this.handleDurationChange}
                  	onBlur={() => this.handleDurationBlur(data, type)}
                  	autoFocus
					  disabled={data.modelTreatmentId && !data.isCheckList}
					  maxLength={3}
                  />) :
										<div className="d-flex justify-content-between align-items-center">
											<div className="text-align-left">{data?.duration}</div>
										</div>}
									</td>
									:
									data.isHeading || this.filterCheckList(data) ?
											<td className={`td_hide max_width_60 ${data.modelTreatmentId ? "model_attached_td" : ""}`} 		onClick={() => this.handleEditDuration(data.uniqueId, data.duration)}>
												{!data.isHeading ? 

												this.state.editingRowId === data.uniqueId ? (
                  <input
				  className={`editable-input ${data.isCheckList && !data.isHeading ? "disabled-input" : ""}`}
                  	value={this.state.editedDuration}
                  	onChange={this.handleDurationChange}
                  	onBlur={() => this.handleDurationBlur(data)}
                  	autoFocus
                  	disabled={data.isCheckList && !data.isHeading}
                  	maxLength={3}
                  />) :
										<div className="d-flex justify-content-between align-items-center">
						  	<div className="text-align-left"><span className={`check_list ${data.isHeading ? "check_list_bold" : ""}`}>{data?.duration}</span></div>
							  </div> : ""}
											</td> : ""
								: ""}
						</React.Fragment>
					}
					<React.Fragment>
						{data.chartControlType === "AS_PRESCRIBED" && index === 0 ? (
    (this.filterCheckList(data) || !data.isCheckList) ? ( 
      <td className="cell_tmp_grey" colSpan={data.chartSession.length} style={{ backgroundColor: "#f2f0f1" }}>
      	<div className="floating_label">
      		<I18 tkey="AS PRESCRIBED" />
      	</div>
      </td>
    ) : null
  ) : data.chartControlType === "PERMANENTLY" && index === 0 ? (
    (this.filterCheckList(data) || !data.isCheckList) ? (
      <td className="cell_tmp_green" colSpan={data.chartSession.length} style={{ backgroundColor: "#87caad" }}>
      	<div className="floating_label">
      		<I18 tkey="PERMANENTLY" />
      	</div>
      </td>
    ) : null
  ) : data.chartControlType === "IF_REQUIRED" && index === 0 ? (
    (this.filterCheckList(data) || !data.isCheckList) ? (
      <td className="cell_tmp_light_blue" colSpan={data.chartSession.length} style={{ backgroundColor: "#e6fafa" }}>
      	<div>
      		<I18 tkey="IF REQUIRED" />
      	</div>
      </td>
    ) : null
  ) : data.chartControlType === "NONE" && data.chartSession.length > index ? (
    !data.isCheckList ? (
      <td
      	className={`dey
          ${data.chartSession[index] === 1 ? "cell_tmp_green" : ""}
          ${data.chartSession[index] === 2 ? "cell_tmp_orange" : ""}
          ${isHours && this.styleCellBorder(problemList, index + 1) ? "border_highlight" : !isHours && index % 3 === 2 ? "border_highlight" : ""}
        `}
      	onClick={this.tdClickHandler.bind(this, data, index, problemIndex, type)}
      />
    ) : data.isHeading || this.filterCheckList(data) ? (
      <td
      	className={`dey
          ${data.chartSession[index] === 1 ? "cell_tmp_green" : ""}
          ${data.chartSession[index] === 2 ? "cell_tmp_orange" : ""}
          ${isHours && this.styleCellBorder(problemList, index + 1) ? "border_highlight" : !isHours && index % 3 === 2 ? "border_highlight" : ""}
        `}
      	onClick={this.checkListTdHandler.bind(this, data, index, problemIndex, type, data.isHeading, data.chartSession[index])}
      />
    ) : null
  ) : null}
					</React.Fragment>
				</React.Fragment>
			);
		}
		return list;
	};

	filterCheckList = (data) => {
		const index = this.state.expandedProblemAndCheckListIds.findIndex(value => value.problemId === data.problemId && value.checkListTreatmentId === data.checkListTreatmentId);
		if (index < 0) {
			return false;
		}
		return true;
	};

	reloadData() {
		this.fetchDataFromServer();
	}

	onClickCancel() {
		this.setState({ editAdditionalInfo: false, createAdditionalInfo: false, selectedAdditionalInfo: {}, problemDetails: {}, problemId: null });
	}

	editAdditionalInfo(value) {

		console.log("This action did not actived");
		return (e) => {
			if (this.props.clinicalPathwayData.isApproved) {
				//e.preventDefault();
				//this.setState({ editAdditionalInfo: true, selectedAdditionalInfo: { id: value.additionalInfoId }, problemId: value.problemId, problemDetails: value });
			}
		};
	}

	actionButtons(item) {
		return (<div>
			
			{ permissions(this.props.clinicalPathwayData.isReadOnly, "EDIT") ? item.nurseAndDoctor === "DOCTOR" && ((item.isCheckList && !item.modelTreatmentId  && this.filterCheckList(item) && !item.isHeading) || !item.isCheckList) ?
				<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Medical interventions or on medical prescription.", utils.getLanguage())}>
					<a  type="edit" onClick={this.editAdditionalInfo(item).bind(this)} >
						{!item.modelTreatmentId ? <DoctorEdit /> : <DocEdit />}
					</a>
				</Tooltip>
				:
				item.nurseAndDoctor === "NURSE" && ((item.isCheckList && !item.modelTreatmentId   && this.filterCheckList(item)  && !item.isHeading) || !item.isCheckList) ?
					<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Multi-professional paramedical interventions.", utils.getLanguage())}>
						<a  type="edit" onClick={this.editAdditionalInfo(item).bind(this)} >
							{!item.modelTreatmentId ? <NurseEdit /> : <NursePenEdit />}
						</a>
					</Tooltip>
					:
					item.nurseAndDoctor === "ALL" && ((item.isCheckList && !item.modelTreatmentId  && this.filterCheckList(item)  && !item.isHeading) || !item.isCheckList) ?
						<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Medical interventions or on medical prescription and Interventions By Nurses Or Professionals", utils.getLanguage())}>
							<a  type="edit" onClick={this.editAdditionalInfo(item).bind(this)} >
								{!item.modelTreatmentId ? <DoctorNurseEdit /> : <DocNurseEdit />}
							</a>
						</Tooltip>
						:
						(this.filterCheckList(item) && !item.isHeading) ?
							!item.modelTreatmentId ? <a  type="edit" onClick={this.editAdditionalInfo(item).bind(this)} ><Edit /></a> :
								<a  type="edit" onClick={this.editAdditionalInfo(item).bind(this)}  >
									<ViewNotePad />
								</a>
							:
							!item.nurseAndDoctor && ((item.isCheckList && !item.modelTreatmentId && this.filterCheckList(item) && !item.isHeading) || !item.isCheckList) ?
								<Tooltip overlayClassName="tool_tip_pathway " title={i18Get("Medical interventions or on medical prescription and Interventions By Nurses Or Professionals", utils.getLanguage())}>
									<a  type="edit" onClick={this.editAdditionalInfo(item).bind(this)}  ><Edit /></a>
								</Tooltip> : ""
				: ""}
		</div>);
	}
	onClickCancelReorder() {
		return (e) => {
			e.preventDefault();
			this.fetchDataFromServer();
			this.setState({ enableReorder: false, enableDrag: false });
		};
	}

	onClickSaveOrder() {
		return () => {
			const reOrderedProblems = [];
			const newData = JSON.parse(JSON.stringify(this.state.standardCareChartData.problems));
			if (newData && newData.length > 0) {
				newData.map(function (data, index) {
					reOrderedProblems.push({ additionalInfoId: data.additionalInfoId, rank: index + 1, interventionSummaryName: data.interventionSummaryName, problemId: data.problemId });
				});
				const payload = {
					reOrderedProblems: reOrderedProblems,
					isChart: true
				};
				this.setState({ enableDrag: true });
				this.props.dispatch(standardCareChartActions.standardCareChartRankUpdation(this.props.match.params.id, payload));
			} else {
				this.setState({ enableReorder: false });
			}
		};
	}

	isPermissionGranted = (method) => {
		return isPermissionGranted(this.props.clinicalPathwayData.isReadOnly, method);
	};

	render() {
		this.state.stepSectionType = "";
		if (this.props.standardCareChart.fetchAllStandardCareChartData === "FULFILLED") {
			this.state.standardCareChartData = this.props.standardCareChart.standardCareChartData;			
			this.setState({ loading: false, stepSectionType: "", hoursTotalList: "", expandedProblemAndCheckListIds: [] });
			this.props.setTaskCount(this.state.standardCareChartData.totalTasksCount, this.state.standardCareChartData.tasksCount);
			this.props.dispatch(standardCareChartActions.clearGetAll());
		}
		if (this.props.standardCareChart.updateStandardCareChartDetails === "FULFILLED") {
			this.props.dispatch(standardCareChartActions.updateStandardCareChartDetailsCompleted());
		}
		if (this.props.standardCareChart.updateCheckListStdCareChartDetails === "REJECTED") {
			this.setState({ standardCareChartData: this.state.previousData });
			this.props.dispatch(standardCareChartActions.updateCheckListStdCareChartDetailsCompleted());
		}
		if (this.props.standardCareChart.updateCheckListStdCareChartDetails === "FULFILLED") {
			this.props.dispatch(standardCareChartActions.updateCheckListStdCareChartDetailsCompleted());
		}
		if (this.props.standardCareChart.updateStdCareChartDuration === "REJECTED") {
			this.setState({ standardCareChartData: this.state.previousData });
			this.props.dispatch(standardCareChartActions.updateStdCareChartDurationCompleted());
		}
		if (this.props.standardCareChart.updateStdCareChartDuration === "FULFILLED") {
			this.props.dispatch(standardCareChartActions.updateStdCareChartDurationCompleted());
		}
		if (this.props.standardCareChart.updateStandardCareChartDetails === "REJECTED") {
			this.setState({ standardCareChartData: this.state.previousData });
			this.props.dispatch(standardCareChartActions.updateStandardCareChartDetailsCompleted());
		}
		if (this.props.standardCareChart.standardCareChartRankUpdation === "FULFILLED") {
			this.fetchDataFromServer();
			this.setState({ enableReorder: false, enableDrag: false });
			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdationCompleted());
			this.props.dispatch(standardCareChartActions.toggleStandardCareChartRankUpdationModal());
		}
		if (this.props.standardCareChart.standardCareChartRankUpdation === "REJECTED") {
			this.fetchDataFromServer();
			this.setState({ enableReorder: false, enableDrag: false });
			this.props.dispatch(standardCareChartActions.standardCareChartRankUpdationCompleted());
		}

		return (
			<div className="padding-top-7 position-relative clinical_pathway_table">
				<div className={`${this.props.treatmentId ? "standard_care_chart_table_div margin-top-33" : "standard_care_chart_table_div"} ${!this.props.treatmentId && this.state.enableReorder ? "standard_care_chart_table_div_reorder" : ""}`}>
					<div
						className= "table_div w_100 margin-top-7 padding-bottom-35 customized-scrollbar scrollbar standard_carechart_height">
						{this.state.standardCareChartData &&
					Object.keys(this.state.standardCareChartData).map((key) => {
						return this.getTable(this.state.standardCareChartData[key], key);
					})
						}
					</div>
				</div>
				{this.state.editAdditionalInfo ?
					<AdditionalInfoEdit
						selectedAdditionalInfo={this.state.selectedAdditionalInfo}
						editAdditionalInfo={this.state.editAdditionalInfo}
						problemId={this.state.problemId}
						problemDetails={this.state.problemDetails}
						onClickCancel={this.onClickCancel.bind(this)}
						reloadData={this.reloadData.bind(this)}
						clinicalPathwayData={this.props.clinicalPathwayData}
					/>
					: ""}
			</div>
		);
	}
}

StandardCareChart.propTypes = {
	standardCareChart: PropTypes.object.isRequired,
	dispatch: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	permissions: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	standardCareChart: store.standardCareChart,
	permissions: store.permissions
}))(StandardCareChart));