export default {
	ACTION_ONE: "ACTION_ONE",
	ACTION_ONE_CLEAR: "ACTION_ONE_CLEAR",
	HTTP_REQUEST_LOST: "HTTP_REQUEST_LOST",
	HTTP_REQUEST_LOST_CLEAR: "HTTP_REQUEST_LOST_CLEAR",
	//HIDE/SHOW SIDE BAR
	HIDE_SHOW_SIDE_BAR: "HIDE_SHOW_SIDE_BAR",

	//USER
	USER_LOGIN: "USER_LOGIN",
	SET_LOGIN_DATA: "SET_LOGIN_DATA",
	CLEAR_USER_LOGIN_MINIMAL: "CLEAR_USER_LOGIN_MINIMAL",
	CLEAR_USER_LOGIN: "CLEAR_USER_LOGIN",
	FORGOT_PASSWORD: "FORGOT_PASSWORD",
	CLEAR_FORGOT_PASSWORD: "CLEAR_FORGOT_PASSWORD",
	VALIDATE_RESET_TOKEN: "VALIDATE_RESET_TOKEN",
	CLEAR_VALIDATE_RESET_TOKEN: "CLEAR_VALIDATE_RESET_TOKEN",
	RESET_PASSWORD: "RESET_PASSWORD",
	CLEAR_RESET_PASSWORD: "CLEAR_RESET_PASSWORD",
	USER_LOGOUT: "USER_LOGOUT",
	CLEAR_USER_FETCH: "CLEAR_USER_FETCH",
	FETCH_ALL_USER: "FETCH_ALL_USER",
	CREATE_USER: "CREATE_USER",
	CLEAR_CREATE_USER: "CLEAR_CREATE_USER",
	DELETE_USER: "DELETE_USER",
	CLEAR_DELETE_USER: "CLEAR_DELETE_USER",
	FETCH_ONE_USER: "FETCH_ONE_USER",
	CLEAR_FETCH_ONE_USER: "CLEAR_FETCH_ONE_USER",
	UPDATE_USER: "UPDATE_USER",
	CLEAR_UPDATE_USER: "CLEAR_UPDATE_USER",
	UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
	CLEAR_UPDATE_USER_PASSWORD: "CLEAR_UPDATE_USER_PASSWORD",
	LOGIN_BLOCKED: "LOGIN_BLOCKED",
	LOGIN_BLOCKE_CLEAR: "LOGIN_BLOCKE_CLEAR",
	LOGIN_LIMIT_INFO: "LOGIN_LIMIT_INFO",
	LOGIN_LIMIT_INFO_CLEAR: "LOGIN_LIMIT_INFO_CLEAR",

	//PROFILE
	FETCH_USER_PROFILE: "FETCH_USER_PROFILE",
	CLEAR_PROFILE: "CLEAR_PROFILE",
	UPDATE_PROFILE: "UPDATE_PROFILE",
	CLEAR_UPDATE_PROFILE: "CLEAR_UPDATE_PROFILE",
	UPDATE_PROFILE_PIC: "UPDATE_PROFILE_PIC",
	CHANGE_PASSWORD_PROFILE: "CHANGE_PASSWORD_PROFILE",
	CLEAR_CHANGE_PASSWORD_PROFILE: "CLEAR_CHANGE_PASSWORD_PROFILE",
	GET_SUPPORT_PORTAL_LINK: "GET_SUPPORT_PORTAL_LINK",
	CLEAR_GET_SUPPORT_PORTAL_LINK: "CLEAR_GET_SUPPORT_PORTAL_LINK",

	//TRANSLATION
	TRANSLATION_FETCH: "TRANSLATION_FETCH",
	RESET_TRANSLATION_LANGUAGE: "RESET_TRANSLATION_LANGUAGE",
	CLEAR_RESET_FLAG: "CLEAR_RESET_FLAG",
	TRIGGER_TRANSLATION_JSON_GET: "TRIGGER_TRANSLATION_JSON_GET",
	CLEAR_TRIGGER_NUMERIC_CHANGE: "CLEAR_TRIGGER_NUMERIC_CHANGE",
	GET_ALL_TRANSLATION_FOR_LANGUAGE: "GET_ALL_TRANSLATION_FOR_LANGUAGE",
	CLEAR_GET_ALL_TRANSLATION_FOR_LANGUAGE: "CLEAR_GET_ALL_TRANSLATION_FOR_LANGUAGE",
	DELETE_TRANSLATION: "DELETE_TRANSLATION",
	CLEAR_DELETE_TRANSLATION: "CLEAR_DELETE_TRANSLATION",
	CREATE_TRANSLATION: "CREATE_TRANSLATION",
	CLEAR_CREATE_TRANSLATION: "CLEAR_CREATE_TRANSLATION",
	UPDATE_TRANSLATION: "UPDATE_TRANSLATION",
	CLEAR_UPDATE_TRANSLATION: "CLEAR_UPDATE_TRANSLATION",
	SYNC_TRANSLATION: "SYNC_TRANSLATION",
	CLEAR_SYNC_TRANSLATION: "CLEAR_SYNC_TRANSLATION",

	//INTERVENTION
	INTERVENTION_FILTER: "INTERVENTION_FILTER",
	INTERVENTION_GET_ALL: "INTERVENTION_GET_ALL",
	CLEAR_INTERVENTION_GET_ALL: "CLEAR_INTERVENTION_GET_ALL",
	INTERVENTION_GET_ONE: "INTERVENTION_GET_ONE",
	INTERVENTION_UPDATE: "INTERVENTION_UPDATE",
	CLEAR_INTERVENTION_UPDATE: "CLEAR_INTERVENTION_UPDATE",
	COST_GET: "COST_GET",
	CONSUMMABLES_GET: "CONSUMMABLES_GET",
	INTERVENTION_CREATE: "INTERVENTION_CREATE",
	CLEAR_INTERVENTION_CREATE: "CLEAR_INTERVENTION_CREATE",
	COPY_INTERVENTION: "COPY_INTERVENTION",
	CLEAR_COPY_INTERVENTION: "CLEAR_COPY_INTERVENTION",
	CLEAR_FILTER: "CLEAR_FILTER",
	CLEAR_INTERVENTION_GETONE: "CLEAR_INTERVENTION_GETONE",
	ASSIGN_NEW_MOM: "ASSIGN_NEW_MOM",
	CLEAR_ASSIGN_NEW_MOM: "CLEAR_ASSIGN_NEW_MOM",
	INTERVENTION_REPORT_EXPRORT: "INTERVENTION_REPORT_EXPRORT",
	INTERVENTION_REPORT_EXPRORT_COMPLETED: "INTERVENTION_REPORT_EXPRORT_COMPLETED",

	CHECK_INTERVENTION_REPORT_STATUS: "CHECK_INTERVENTION_REPORT_STATUS",
	CHECK_INTERVENTION_REPORT_STATUS_COMPLETED: "CHECK_INTERVENTION_REPORT_STATUS_COMPLETED",

	SCANNED_TREATMENTS_EXPORT: "SCANNED_TREATMENTS_EXPORT",
	SCANNED_TREATMENTS_EXPORT_COMPLETED: "SCANNED_TREATMENTS_EXPORT_COMPLETED",
	CHECK_SCANNED_TREATMENTS_EXPORT_STATUS: "CHECK_SCANNED_TREATMENTS_EXPORT_STATUS",
	CHECK_SCANNED_TREATMENTS_EXPORT_STATUS_COMPLETED: "CHECK_SCANNED_TREATMENTS_EXPORT_STATUS_COMPLETED",

	GET_RECOMMENDATION_COUNT: "GET_RECOMMENDATION_COUNT",
	CLEAR_RECOMMENDATION_COUNT: "CLEAR_RECOMMENDATION_COUNT",

	GET_ONE_INTERVENTION: "GET_ONE_INTERVENTION",
	CLEAR_GET_ONE_INTERVENTION: "CLEAR_GET_ONE_INTERVENTION",
	GET_APPROVED_INTERVENTIONS: "GET_APPROVED_INTERVENTIONS",
	GET_LINKED_ITEMS_FOR_INTERVENTION_COMPLETED: "GET_LINKED_ITEMS_FOR_INTERVENTION_COMPLETED",
	GET_LINKED_ITEMS_FOR_INTERVENTION: "GET_LINKED_ITEMS_FOR_INTERVENTION",
	GET_APPROVED_INTERVENTIONS_COMPLETED: "GET_APPROVED_INTERVENTIONS_COMPLETED",
	ASSIGN_AND_APPROVE_INTERVENTION: "ASSIGN_AND_APPROVE_INTERVENTION",
	CLEAR_ASSIGN_AND_APPROVE_INTERVENTION: "CLEAR_ASSIGN_AND_APPROVE_INTERVENTION",
	SAVE_AND_APPROVE_INTERVENTION: "SAVE_AND_APPROVE_INTERVENTION",
	CLEAR_SAVE_AND_APPROVE_INTERVENTION: "CLEAR_SAVE_AND_APPROVE_INTERVENTION",
	EDIT_INTERVENTION: "EDIT_INTERVENTION",
	CLEAR_EDIT_INTERVENTION: "CLEAR_EDIT_INTERVENTION",
	DELETE_INTERVENTION: "DELETE_INTERVENTION",
	DELETE_INTERVENTION_COMPLETED: "DELETE_INTERVENTION_COMPLETED",
	ASSIGN_AND_APPROVE_CONSUMABLE: "ASSIGN_AND_APPROVE_CONSUMABLE",
	CLEAR_ASSIGN_AND_APPROVE_CONSUMABLE: "CLEAR_ASSIGN_AND_APPROVE_CONSUMABLE",
	SAVE_AND_APPROVE_CONSUMABLE: "SAVE_AND_APPROVE_CONSUMABLE",
	CLEAR_SAVE_AND_APPROVE_CONSUMABLE: "CLEAR_SAVE_AND_APPROVE_CONSUMABLE",
	GET_TREATMENT_COST_LIST: "GET_TREATMENT_COST_LIST",
	GET_TREATMENT_COST_LIST_COMPLETED: "GET_TREATMENT_COST_LIST_COMPLETED",
	UPDATE_TOTAL_DEFAULT_COST: "UPDATE_TOTAL_DEFAULT_COST",
	CLEAR_UPDATE_TOTAL_DEFAULT_COST: "CLEAR_UPDATE_TOTAL_DEFAULT_COST",
	GET_ONE_DEFAULT_VALUE: "GET_ONE_DEFAULT_VALUE",
	CLEAR_GET_ONE_DEFAULT_VALUE: "CLEAR_GET_ONE_DEFAULT_VALUE",
	APPROVE_DEFAULT_VALUE: "APPROVE_DEFAULT_VALUE",
	CLEAR_APPROVE_DEFAULT_VALUE: "CLEAR_APPROVE_DEFAULT_VALUE",
	UPDATE_INTERVENTION_DESCRIPTION: "UPDATE_INTERVENTION_DESCRIPTION",
	CLEAR_UPDATE_INTERVENTION_DESCRIPTION: "CLEAR_UPDATE_INTERVENTION_DESCRIPTION",

	INTERVENTION_SUB_TREATMENT_ADD: "INTERVENTION_SUB_TREATMENT_ADD",
	INTERVENTION_SUB_TREATMENT_ADD_COMPLETED: "INTERVENTION_SUB_TREATMENT_ADD_COMPLETED",
	INTERVENTION_SUB_TREATMENT_DELETE: "INTERVENTION_SUB_TREATMENT_DELETE",
	INTERVENTION_SUB_TREATMENT_DELETE_COMPLETED: "INTERVENTION_SUB_TREATMENT_DELETE_COMPLETED",
	INTERVENTION_SUB_TREATMENT_UPDATE: "INTERVENTION_SUB_TREATMENT_UPDATE",
	INTERVENTION_SUB_TREATMENT_UPDATE_COMPLETED: "INTERVENTION_SUB_TREATMENT_UPDATE_COMPLETED",
	INTERVENTION_SUB_TREATMENT_GET_ONE: "INTERVENTION_SUB_TREATMENT_GET_ONE",
	INTERVENTION_SUB_TREATMENT_GET_ONE_COMPLETED: "INTERVENTION_SUB_TREATMENT_GET_ONE_COMPLETED",

	SELECTED_COLUMN_INTERVENTION_TIMESHEETS: "SELECTED_COLUMN_INTERVENTION_TIMESHEETS",
	SELECTED_COLUMN_INTERVENTION_DEFAULT_VALUES: "SELECTED_COLUMN_INTERVENTION_DEFAULT_VALUES",
	//course 
	COURSE_GET_ONE: "COURSE_GET_ONE",
	COURSE_STEP_GET_ONE: "COURSE_STEP_GET_ONE",
	COURSE_CREATE_UPDATE: "COURSE_CREATE_UPDATE",
	CLEAR_CREATE: "CLEAR_CREATE",
	CLEAR_GET: "CLEAR_GET",
	COURSE_ELEMENTS_FETCH: "COURSE_ELEMENTS_FETCH",
	CLEAR_COURSE_EEMENTS_GET: "CLEAR_COURSE_EEMENTS_GET",
	COURSE_LIEUX_FETCH: "COURSE_LIEUX_FETCH",
	CLEAR_COURSE_LIEUX_GET: "CLEAR_COURSE_LIEUX_GET",
	COURSE_VALUE_CREATE: "COURSE_VALUE_CREATE",
	CLEAR_COURSE_VALUE_CREATE: "CLEAR_COURSE_VALUE_CREATE",
	COURSE_VALUE_EDIT: "COURSE_VALUE_EDIT",
	COURSE_VALUE_DELETE: "COURSE_VALUE_DELETE",
	CLEAR_COURSE_VALUE_DELETE: "CLEAR_COURSE_VALUE_DELETE",
	CLEAR_COURSE_VALUE_EDIT: "CLEAR_COURSE_VALUE_EDIT",
	COURSE_VALUE_RANK_UPDATE: "COURSE_VALUE_RANK_UPDATE",
	CLEAR_COURSE_RANK_UPDATE: "CLEAR_COURSE_RANK_UPDATE",
	GET_ALL_COURSE_STEPS_MINIMAL: "GET_ALL_COURSE_STEPS_MINIMAL",
	CLEAR_GET_ALL_COURSE_STEPS_MINIMAL: "CLEAR_GET_ALL_COURSE_STEPS_MINIMAL",
	COURSE_STEPS_COPY: "COURSE_STEPS_COPY",
	CLEAR_COURSE_STEPS_COPY: "CLEAR_COURSE_STEPS_COPY",

	GET_LOCAL_TREATMENT_LIST: "GET_LOCAL_TREATMENT_LIST",
	CLEAR_LOCAL_TREATMENT_LIST: "CLEAR_LOCAL_TREATMENT_LIST",

	COPY_TO_LOCAL: "COPY_TO_LOCAL",
	COPY_TO_LOCAL_CLEAR: "COPY_TO_LOCAL_CLEAR",

	//SPECIALTY
	SPECIALTY_GET_ALL: "SPECIALTY_GET_ALL",
	CLEAR_SPECIALTY_FETCH: "CLEAR_SPECIALTY_FETCH",
	SPECIALTY_GET_ALL_MOM: "SPECIALTY_GET_ALL_MOM",
	CLEAR_SPECIALTY_MOM_FETCH: "CLEAR_SPECIALTY_MOM_FETCH",
	FETCH_ONE_SPECIALTY: "FETCH_ONE_SPECIALTY",
	CLEAR_FETCH_ONE_SPECIALTY: "CLEAR_FETCH_ONE_SPECIALTY",
	UPDATE_SPECIALTY: "UPDATE_SPECIALTY",
	CLEAR_UPDATE_SPECIALTY: "CLEAR_UPDATE_SPECIALTY",
	CREATE_SPECIALTY: "CREATE_SPECIALTY",
	CLEAR_CREATE_SPECIALTY: "CLEAR_CREATE_SPECIALTY",
	DELETE_SPECIALTY: "DELETE_SPECIALTY",
	CLEAR_DELETE_SPECIALTY: "CLEAR_DELETE_SPECIALTY",
	COPY_MOM_SPECIALITY_TO_LOCAL: "COPY_MOM_SPECIALITY_TO_LOCAL",
	CLEAR_COPY_MOM_SPECIALITY_TO_LOCAL: "CLEAR_COPY_MOM_SPECIALITY_TO_LOCAL",
	FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY: "FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY",
	CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY: "CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_SPECIALITY",
	RE_ASSIGN_SPECIALITY: "RE_ASSIGN_SPECIALITY",
	RE_ASSIGN_SPECIALITY_COMPLETED: "RE_ASSIGN_SPECIALITY_COMPLETED",
	HOSPITAL_GET_ALL: "HOSPITAL_GET_ALL",
	CLEAR_HOSPITAL_FETCH: "CLEAR_HOSPITAL_FETCH",

	//SPECIALITY_MODEL
	SPECIALTY_MODEL_GET_ALL: "SPECIALTY_MODEL_GET_ALL",
	CLEAR_SPECIALTY_MODEL_GET_ALL: "CLEAR_SPECIALTY_MODEL_GET_ALL",
	SAVE_AND_APPROVE_SPECIALTY: "SAVE_AND_APPROVE_SPECIALTY",
	CLEAR_SAVE_AND_APPROVE_SPECIALTY: "CLEAR_SAVE_AND_APPROVE_SPECIALTY",
	ASSIGN_AND_APPROVE_SPECIALTY: "ASSIGN_AND_APPROVE_SPECIALTY",
	CLEAR_ASSIGN_AND_APPROVE_SPECIALTY: "CLEAR_ASSIGN_AND_APPROVE_SPECIALTY",
	GET_SPECIALITY_COMMUNITY: "GET_SPECIALITY_COMMUNITY",
	CLEAR_SPECIALITY_COMMUNITY: "CLEAR_SPECIALITY_COMMUNITY",


	//STERILIZATION
	STERILIZATION_GET_ALL: "STERILIZATION_GET_ALL",
	CLEAR_STERILIZATION_FETCH: "CLEAR_STERILIZATION_FETCH",
	STERILIZATION_GET_ONE: "STERILIZATION_GET_ONE",
	CLEAR_STERILIZATION_GET_ONE: "CLEAR_STERILIZATION_GET_ONE",
	CREATE_STERILIZATION_CONSO: "CREATE_STERILIZATION_CONSO",
	COMPLETE_CREATE_STERILIZATION_CONSO: "COMPLETE_CREATE_STERILIZATION_CONSO",
	EDIT_STERILIZATION_CONSO: "EDIT_STERILIZATION_CONSO",
	COMPLETE_EDIT_STERILIZATION_CONSO: "COMPLETE_EDIT_STERILIZATION_CONSO",
	DELETE_STERILIZATION_CONSO: "DELETE_STERILIZATION_CONSO",
	COMPLETED_DELETE_STERILIZATION_CONSO: "COMPLETED_DELETE_STERILIZATION_CONSO",

	STERILIZATION_FAMILY_GET_ALL: "STERILIZATION_FAMILY_GET_ALL",
	CLEAR_STERILIZATION_FAMILY_FETCH: "CLEAR_STERILIZATION_FAMILY_FETCH",
	STERILIZATION_FAMILY_GET_ONE: "STERILIZATION_FAMILY_GET_ONE",
	CLEAR_STERILIZATION_FAMILY_ONE: "CLEAR_STERILIZATION_FAMILY_ONE",
	CREATE_STERILIZATION_FAMILY: "CREATE_STERILIZATION_FAMILY",
	CREATE_STERILIZATION_FAMILY_COMPLETED: "CREATE_STERILIZATION_FAMILY_COMPLETED",
	UPDATE_STERILIZATION_FAMILY: "UPDATE_STERILIZATION_FAMILY",
	UPDATE_STERILIZATION_FAMILY_COMPLETED: "UPDATE_STERILIZATION_FAMILY_COMPLETED",
	DELETE_STERILIZATION_FAMILY: "DELETE_STERILIZATION_FAMILY",
	DELETE_STERILIZATION_FAMILY_COMPLETED: "DELETE_STERILIZATION_FAMILY_COMPLETED",
	GET_ALL_STERILIZATION_FAMILY: "GET_ALL_STERILIZATION_FAMILY",
	GET_ALL_STERILIZATION_FAMILY_COMPLETED: "GET_ALL_STERILIZATION_FAMILY_COMPLETED",
	SAVE_AND_APPROVE_STERILIZATION_FAMILY: "SAVE_AND_APPROVE_STERILIZATION_FAMILY",
	SAVE_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED: "SAVE_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED",
	ASSIGN_AND_APPROVE_STERILIZATION_FAMILY: "ASSIGN_AND_APPROVE_STERILIZATION_FAMILY",
	ASSIGN_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED: "ASSIGN_AND_APPROVE_STERILIZATION_FAMILY_COMPLETED",



	FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_SPECIALITY: "FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_SPECIALITY",
	FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_SPECIALITY_COMPLETED: "FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_SPECIALITY_COMPLETED",
	FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_FAMILY: "FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_FAMILY",
	FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_FAMILY_COMPLETED: "FETCH_ALL_UNASSIGNED_STERILIZATION_LOCAL_MOM_FAMILY_COMPLETED",

	STERILIZATION_COPY_MOM_SPECIALITY_TO_LOCAL: "STERILIZATION_COPY_MOM_SPECIALITY_TO_LOCAL",
	STERILIZATION_COPY_MOM_SPECIALITY_TO_LOCAL_COMPLETED: "STERILIZATION_COPY_MOM_SPECIALITY_TO_LOCAL_COMPLETED",

	GET_MINIMAL_CONSO_SPECIALITY: "GET_MINIMAL_CONSO_SPECIALITY",
	CLEAR_GET_MINIMAL_CONSO_SPECIALITY: "CLEAR_GET_MINIMAL_CONSO_SPECIALITY",

	GET_MINIMAL_CONSO_FAMILY: "GET_MINIMAL_CONSO_FAMILY",
	CLEAR_GET_MINIMAL_CONSO_FAMILY: "CLEAR_GET_MINIMAL_CONSO_FAMILY",


	//TYPE COMPLEXITY
	TYPE_COMPLEXITY_GET_ALL_NAME: "TYPE_COMPLEXITY_GET_ALL_NAME",
	TYPE_COMPLEXITY_GET_ALL_TYPE: "TYPE_COMPLEXITY_GET_ALL_TYPE",
	CLEAR_TYPE_COMPLEXITY_FETCH_NAME: "CLEAR_TYPE_COMPLEXITY_FETCH_NAME",
	CLEAR_TYPE_COMPLEXITY_FETCH_TYPE: "CLEAR_TYPE_COMPLEXITY_FETCH_TYPE",
	FETCH_ONE_TYPE_COMPLEXITY: "FETCH_ONE_TYPE_COMPLEXITY",
	CLEAR_FETCH_ONE_TYPE_COMPLEXITY: "CLEAR_FETCH_ONE_TYPE_COMPLEXITY",
	UPDATE_TYPE_COMPLEXITY: "UPDATE_TYPE_COMPLEXITY",
	CLEAR_UPDATE_TYPE_COMPLEXITY: "CLEAR_UPDATE_TYPE_COMPLEXITY",
	CREATE_TYPE_COMPLEXITY_NAME: "CREATE_TYPE_COMPLEXITY_NAME",
	CLEAR_CREATE_TYPE_COMPLEXITY_NAME: "CLEAR_CREATE_TYPE_COMPLEXITY_NAME",
	CREATE_TYPE_COMPLEXITY_TYPE: "CREATE_TYPE_COMPLEXITY_TYPE",
	CLEAR_CREATE_TYPE_COMPLEXITY_TYPE: "CLEAR_CREATE_TYPE_COMPLEXITY_TYPE",
	DELETE_TYPE_COMPLEXITY_NAME: "DELETE_TYPE_COMPLEXITY_NAME",
	CLEAR_DELETE_TYPE_COMPLEXITY_NAME: "CLEAR_DELETE_TYPE_COMPLEXITY_NAME",
	DELETE_TYPE_COMPLEXITY_TYPE: "DELETE_TYPE_COMPLEXITY_TYPE",
	CLEAR_DELETE_TYPE_COMPLEXITY_TYPE: "CLEAR_DELETE_TYPE_COMPLEXITY_TYPE",
	TYPE_COMPLEXITY_UPDATE_RANK: "TYPE_COMPLEXITY_UPDATE_RANK",
	CLEAR_TYPE_COMPLEXITY_UPDATE_RANK: "CLEAR_TYPE_COMPLEXITY_UPDATE_RANK",

	//ACTIVITY STEPS

	ACTIVITY_STEPS_GET_ALL_NAME: "ACTIVITY_STEPS_GET_ALL_NAME",
	ACTIVITY_STEPS_GET_ALL_TYPE: "ACTIVITY_STEPS_GET_ALL_TYPE",
	CLEAR_ACTIVITY_STEPS_FETCH_NAME: "CLEAR_ACTIVITY_STEPS_FETCH_NAME",
	CLEAR_ACTIVITY_STEPS_FETCH_TYPE: "CLEAR_ACTIVITY_STEPS_FETCH_TYPE",
	FETCH_ONE_ACTIVITY_STEPS: "FETCH_ONE_ACTIVITY_STEPS",
	CLEAR_FETCH_ONE_ACTIVITY_STEPS: "CLEAR_FETCH_ONE_ACTIVITY_STEPS",
	UPDATE_ACTIVITY_STEPS: "UPDATE_ACTIVITY_STEPS",
	CLEAR_UPDATE_ACTIVITY_STEPS: "CLEAR_UPDATE_ACTIVITY_STEPS",
	CREATE_ACTIVITY_STEPS_NAME: "CREATE_ACTIVITY_STEPS_NAME",
	CLEAR_CREATE_ACTIVITY_STEPS_NAME: "CLEAR_CREATE_ACTIVITY_STEPS_NAME",
	CREATE_ACTIVITY_STEPS_TYPE: "CREATE_ACTIVITY_STEPS_TYPE",
	CLEAR_CREATE_ACTIVITY_STEPS_TYPE: "CLEAR_CREATE_ACTIVITY_STEPS_TYPE",
	DELETE_ACTIVITY_STEPS_NAME: "DELETE_ACTIVITY_STEPS_NAME",
	CLEAR_DELETE_ACTIVITY_STEPS_NAME: "CLEAR_DELETE_ACTIVITY_STEPS_NAME",
	DELETE_ACTIVITY_STEPS_TYPE: "DELETE_ACTIVITY_STEPS_TYPE",
	CLEAR_DELETE_ACTIVITY_STEPS_TYPE: "CLEAR_DELETE_ACTIVITY_STEPS_TYPE",

	//TARIF
	FETCH_ALL_TARIF_GHS_GHM: "FETCH_ALL_TARIF_GHS_GHM",
	CLEAR_FETCH_ALL_TARIF_GHS_GHM: "CLEAR_FETCH_ALL_TARIF_GHS_GHM",
	FETCH_ALL_TARIF_SUPPLEMENTS: "FETCH_ALL_TARIF_SUPPLEMENTS",
	CLEAR_FETCH_ALL_TARIF_SUPPLEMENTS: "CLEAR_FETCH_ALL_TARIF_SUPPLEMENTS",
	GET_TARIF_DATES: "GET_TARIF_DATES",
	GET_TARIF_DATES_COMPLETE: "GET_TARIF_DATES_COMPLETE",

	//PERFORMANCE
	FETCH_ALL_PERFORMANCE_INTERVENTION: "FETCH_ALL_PERFORMANCE_INTERVENTION",
	CLEAR_FETCH_ALL_PERFORMANCE_INTERVENTION: "CLEAR_FETCH_ALL_PERFORMANCE_INTERVENTION",
	DELETE_PERFORMANCE_INTERVENTION: "DELETE_PERFORMANCE_INTERVENTION",
	CLEAR_DELETE_PERFORMANCE_INTERVENTION: "CLEAR_DELETE_PERFORMANCE_INTERVENTION",
	DELETE_PERFORMANCE_INTERVENTION_ALL: "DELETE_PERFORMANCE_INTERVENTION_ALL",
	FETCH_ALL_PERFORMANCE_PHARMACY: "FETCH_ALL_PERFORMANCE_PHARMACY",
	CLEAR_FETCH_ALL_PERFORMANCE_PHARMACY: "CLEAR_FETCH_ALL_PERFORMANCE_PHARMACY",
	CLEAR_FETCH_ALL_PERFORMANCE_CONSUMPTION: "CLEAR_FETCH_ALL_PERFORMANCE_CONSUMPTION",
	CLEAR_FETCH_ALL_PERFORMANCE_CAPTURED: "CLEAR_FETCH_ALL_PERFORMANCE_CAPTURED",
	FETCH_ALL_PERFORMANCE_CONSUMPTION: "FETCH_ALL_PERFORMANCE_CONSUMPTION",
	FETCH_ALL_PERFORMANCE_CAPTURED: "FETCH_ALL_PERFORMANCE_CAPTURED",
	FETCH_ALL_PERFORMANCE_EFFECTS: "FETCH_ALL_PERFORMANCE_EFFECTS",
	CLEAR_FETCH_ALL_PERFORMANCE_EFFECTS: "CLEAR_FETCH_ALL_PERFORMANCE_EFFECTS",
	FETCH_ALL_PERFORMANCE_COST_INTERVENTION: "FETCH_ALL_PERFORMANCE_COST_INTERVENTION",
	CLEAR_FETCH_ALL_PERFORMANCE_COST_INTERVENTION: "CLEAR_FETCH_ALL_PERFORMANCE_COST_INTERVENTION",


	//FAMILY

	FETCH_ALL_FAMILY: "FETCH_ALL_FAMILY",
	CLEAR_FETCH_ALL_FAMILY: "CLEAR_FETCH_ALL_FAMILY",
	FETCH_ONE_FAMILY: "FETCH_ONE_FAMILY",
	CLEAR_FETCH_ONE_FAMILY: "CLEAR_FETCH_ONE_FAMILY",
	CREATE_FAMILY: "CREATE_FAMILY",
	CLEAR_CREATE_FAMILY: "CLEAR_CREATE_FAMILY",
	UPDATE_FAMILY: "UPDATE_FAMILY",
	CLEAR_UPDATE_FAMILY: "CLEAR_UPDATE_FAMILY",
	DELETE_FAMILY: "DELETE_FAMILY",
	CLEAR_DELETE_FAMILY: "CLEAR_DELETE_FAMILY",
	FETCH_ALL_FAMILY_FOR_SPECIALITY: "FETCH_ALL_FAMILY_FOR_SPECIALITY",
	CLEAR_FETCH_ALL_FAMILY_FOR_SPECIALITY: "CLEAR_FETCH_ALL_FAMILY_FOR_SPECIALITY",
	COPY_MOM_FAMILY_TO_LOCAL: "COPY_MOM_FAMILY_TO_LOCAL",
	CLEAR_COPY_MOM_FAMILY_TO_LOCAL: "CLEAR_COPY_MOM_FAMILY_TO_LOCAL",
	FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY: "FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY",
	CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY: "CLEAR_FETCH_ALL_UNASSIGNED_LOCAL_MOM_FAMILY",
	RE_ASSIGN_FAMILY: "RE_ASSIGN_FAMILY",
	RE_ASSIGN_FAMILY_COMPLETED: "RE_ASSIGN_FAMILY_COMPLETED",

	//FAMILY MODEL

	FETCH_ALL_FAMILY_MODEL: "FETCH_ALL_FAMILY_MODEL",
	CLEAR_FETCH_ALL_FAMILY_MODEL: "CLEAR_FETCH_ALL_FAMILY_MODEL",
	SAVE_AND_APPROVE_FAMILY: "SAVE_AND_APPROVE_FAMILY",
	CLEAR_SAVE_AND_APPROVE_FAMILY: "CLEAR_SAVE_AND_APPROVE_FAMILY",
	ASSIGN_AND_APPROVE_FAMILY: "ASSIGN_AND_APPROVE_FAMILY",
	CLEAR_ASSIGN_AND_APPROVE_FAMILY: "CLEAR_ASSIGN_AND_APPROVE_FAMILY",

	//ADMINISTRATION
	//SITE
	FETCH_ALL_SITES: "FETCH_ALL_SITES",
	CLEAR_SITE_FETCH: "CLEAR_SITE_FETCH",
	FETCH_MASTER_SITE: "FETCH_MASTER_SITE",
	CLEAR_MASTER_SITE_FETCH: "CLEAR_MASTER_SITE_FETCH",
	FETCH_ONE_SITES: "FETCH_ONE_SITES",
	SET_SITE_NAME: "SET_SITE_NAME",
	CLEAR_FETCH_ONE_SITES: "CLEAR_FETCH_ONE_SITES",
	UPDATE_SITES: "UPDATE_SITES",
	UPDATE_CLEAR_SITES: "UPDATE_CLEAR_SITES",
	CREATE_SITES: "CREATE_SITES",
	CREATE_CLEAR_SITES: "CREATE_CLEAR_SITES",
	DELETE_SITES: "DELETE_SITES",
	CLEAR_SITES_DELETE: "CLEAR_SITES_DELETE",

	//LIEUX
	FETCH_ALL_LIEUX: "FETCH_ALL_LIEUX",
	CLEAR_LIEUX_FETCH: "CLEAR_LIEUX_FETCH",
	FETCH_MASTER_LIEUX: "FETCH_MASTER_LIEUX",
	CLEAR_MASTER_LIEUX_FETCH: "CLEAR_MASTER_LIEUX_FETCH",
	FETCH_ONE_LIEUX: "FETCH_ONE_LIEUX",
	SET_LIEUX_NAME: "SET_LIEUX_NAME",
	CLEAR_FETCH_ONE_LIEUX: "CLEAR_FETCH_ONE_LIEUX",
	UPDATE_LIEUX: "UPDATE_LIEUX",
	UPDATE_CLEAR_LIEUX: "UPDATE_CLEAR_LIEUX",
	CREATE_LIEUX: "CREATE_LIEUX",
	CREATE_CLEAR_LIEUX: "CREATE_CLEAR_LIEUX",
	DELETE_LIEUX: "DELETE_LIEUX",
	CLEAR_LIEUX_DELETE: "CLEAR_LIEUX_DELETE",

	//POLE
	FETCH_ALL_POLE: "FETCH_ALL_POLE",
	CLEAR_FETCH_ALL_POLE: "CLEAR_FETCH_ALL_POLE",
	FETCH_ONE_POLE: "FETCH_ONE_POLE",
	CLEAR_FETCH_ONE_POLE: "CLEAR_FETCH_ONE_POLE",
	UPDATE_POLE: "UPDATE_POLE",
	CLEAR_UPDATE_POLE: "CLEAR_UPDATE_POLE",
	CREATE_POLE: "CREATE_POLE",
	CLEAR_CREATE_POLE: "CLEAR_CREATE_POLE",
	DELETE_POLE: "DELETE_POLE",
	CLEAR_DELETE_POLE: "CLEAR_DELETE_POLE",

	//ROOM
	FETCH_ALL_ROOM: "FETCH_ALL_ROOM",
	CLEAR_FETCH_ALL_ROOM: "CLEAR_FETCH_ALL_ROOM",
	FETCH_ONE_ROOM: "FETCH_ONE_ROOM",
	CLEAR_FETCH_ONE_ROOM: "CLEAR_FETCH_ONE_ROOM",
	UPDATE_ROOM: "UPDATE_ROOM",
	CLEAR_UPDATE_ROOM: "CLEAR_UPDATE_ROOM",
	CREATE_ROOM: "CREATE_ROOM",
	CLEAR_CREATE_ROOM: "CLEAR_CREATE_ROOM",
	DELETE_ROOM: "DELETE_ROOM",
	CLEAR_DELETE_ROOM: "CLEAR_DELETE_ROOM",

	//IMPORT 
	FETCH_ALL_IMPORTS: "FETCH_ALL_IMPORTS",
	CLEAR_IMPORT_FETCH: "CLEAR_IMPORT_FETCH",
	FETCH_ONE_IMPORTS: "FETCH_ONE_IMPORTS",
	CLEAR_FETCH_ONE_IMPORTS: "CLEAR_FETCH_ONE_IMPORTS",
	UPLOAD_FILE: "UPLOAD_FILE",
	CLEAR_UPLOAD_FILE: "CLEAR_UPLOAD_FILE",
	GET_IMPORT_ENTITY_DATA: "GET_IMPORT_ENTITY_DATA",
	CLEAR_GET_IMPORT_ENTITY_DATA: "CLEAR_GET_IMPORT_ENTITY_DATA",
	SUBMIT_MAPPING: "SUBMIT_MAPPING",
	CLEAR_SUBMIT_MAPPING: "CLEAR_SUBMIT_MAPPING",
	RECENT_MAPPING: "RECENT_MAPPING",
	CLEAR_RECENT_MAPPING: "CLEAR_RECENT_MAPPING",
	IMPORTATION_DATA_BY_BATCH: "IMPORTATION_DATA_BY_BATCH",
	CLEAR_IMPORTATION_DATA_BY_BATCH: "CLEAR_IMPORTATION_DATA_BY_BATCH",
	GET_ENTITY_CODE_MAP_LIST: "GET_ENTITY_CODE_MAP_LIST",
	CLEAR_GET_ENTITY_CODE_MAP_LIST: "CLEAR_GET_ENTITY_CODE_MAP_LIST",
	SUBMIT_ENTITY_CODE_MAP: "SUBMIT_ENTITY_CODE_MAP",
	CLEAR_SUBMIT_ENTITY_CODE_MAP: "CLEAR_SUBMIT_ENTITY_CODE_MAP",
	ASSIGN_VALUE_STEPS_GET: "ASSIGN_VALUE_STEPS_GET",
	CLEAR_ASSIGN_VALUE_STEPS_GET: "CLEAR_ASSIGN_VALUE_STEPS_GET",
	GET_STAGED_IMPORT_VALUES: "GET_STAGED_IMPORT_VALUES",
	CLEAR_GET_STAGED_IMPORT_VALUES: "CLEAR_GET_STAGED_IMPORT_VALUES",
	GET_REFERENCE_TABLE_VALUES: "GET_REFERENCE_TABLE_VALUES",
	CLEAR_GET_REFERENCE_TABLE_VALUES: "CLEAR_GET_REFERENCE_TABLE_VALUES",
	ASSIGN_NEW_REFERENCE_TABLE_VALUES: "ASSIGN_NEW_REFERENCE_TABLE_VALUES",
	CLEAR_ASSIGN_NEW_REFERENCE_TABLE_VALUES: "CLEAR_ASSIGN_NEW_REFERENCE_TABLE_VALUES",
	CONTINUE_STAGED_IMPORT: "CONTINUE_STAGED_IMPORT",
	CLEAR_CONTINUE_STAGED_IMPORT: "CONTINUE_STAGED_IMPORT",
	GET_CONSUMABLES_USAGE: "GET_CONSUMABLES_USAGE",
	CLEAR_GET_CONSUMABLES_USAGE: "CLEAR_GET_CONSUMABLES_USAGE",
	DELETE_IMPORT: "DELETE_IMPORT",
	CLEAR_DELETE_IMPORT: "CLEAR_DELETE_IMPORT",
	ENTITY_SELECTED_FOR_IMPORTATION: "ENTITY_SELECTED_FOR_IMPORTATION",
	REVERT_MAPPING: "REVERT_MAPPING",
	CLEAR_REVERT_MAPPING: "CLEAR_REVERT_MAPPING",
	EXCEL_TO_TABLE: "EXCEL_TO_TABLE",
	CLEAR_EXCEL_TO_TABLE: "CLEAR_EXCEL_TO_TABLE",
	FETCH_STAGED_DATA: "FETCH_STAGED_DATA",
	CLEAR_FETCH_STAGED_DATA: "CLEAR_FETCH_STAGED_DATA",
	FETCH_EXCEL_DATA: "FETCH_EXCEL_DATA",
	CLEAR_EXCEL_DATA: "CLEAR_EXCEL_DATA",
	FETCH_ALTER_STATUS: "FETCH_ALTER_STATUS",
	CLEAR_ALTER_STATUS: "CLEAR_ALTER_STATUS",
	GET_PREVIOUS_SELECTION: "GET_PREVIOUS_SELECTION",
	CLEAR_GET_PREVIOUS_SELECTION: "CLEAR_GET_PREVIOUS_SELECTION",
	CONSUMABLE_TYPE_CHANGE_FETCH: "CONSUMABLE_TYPE_CHANGE_FETCH",
	CLEAR_CONSUMABLE_TYPE_CHANGE_FETCH: "CLEAR_CONSUMABLE_TYPE_CHANGE_FETCH",
	SUBMIT_CONSUMABLE_TYPE: "SUBMIT_CONSUMABLE_TYPE",
	CLEAR_SUBMIT_CONSUMABLE_TYPE: "CLEAR_SUBMIT_CONSUMABLE_TYPE",
	DELETE_SAVED_MAPPING: "DELETE_SAVED_MAPPING",
	CLEAR_DELETE_SAVED_MAPPING: "CLEAR_DELETE_SAVED_MAPPING",
	SHOW_FINISH_BUTTON: "SHOW_FINISH_BUTTON",
	SELECT_REFERENCE_TABLE: "SELECT_REFERENCE_TABLE",

	//IMPORT_MAP
	FETCH_ALL_IMPORT_MAP_LIST: "FETCH_ALL_IMPORT_MAP_LIST",
	CLEAR_FETCH_ALL_IMPORT_MAP_LIST: "CLEAR_FETCH_ALL_IMPORT_MAP_LIST",
	DELETE_IMPORT_MAP: "DELETE_IMPORT_MAP",
	CLEAR_DELETE_IMPORT_MAP: "CLEAR_DELETE_IMPORT_MAP",
	FETCH_ALL_TREATMENT_LIST: "FETCH_ALL_TREATMENT_LIST",
	CLEAR_FETCH_ALL_TREATMENT_LIST: "CLEAR_FETCH_ALL_TREATMENT_LIST",
	FETCH_ALL_CODE_LIST: "FETCH_ALL_CODE_LIST",
	CLEAR_FETCH_ALL_CODE_LIST: "CLEAR_FETCH_ALL_CODE_LIST",
	UPDATE_IMPORT_MAP_DATA: "UPDATE_IMPORT_MAP_DATA",
	CLEAR_UPDATE_IMPORT_MAP_DATA: "CLEAR_UPDATE_IMPORT_MAP_DATA",

	//SECTOR
	FETCH_ALL_SECTOR: "FETCH_ALL_SECTOR",
	CLEAR_FETCH_ALL_SECTOR: "CLEAR_FETCH_ALL_SECTOR",
	FETCH_ONE_SECTOR: "FETCH_ONE_SECTOR",
	CLEAR_FETCH_ONE_SECTOR: "CLEAR_FETCH_ONE_SECTOR",
	UPDATE_SECTOR: "UPDATE_SECTOR",
	CLEAR_UPDATE_SECTOR: "CLEAR_UPDATE_SECTOR",
	CREATE_SECTOR: "CREATE_SECTOR",
	CLEAR_CREATE_SECTOR: "CLEAR_CREATE_SECTOR",
	DELETE_SECTOR: "DELETE_SECTOR",
	CLEAR_DELETE_SECTOR: "CLEAR_DELETE_SECTOR",

	//BLOC
	FETCH_ALL_BLOC: "FETCH_ALL_BLOC",
	CLEAR_FETCH_ALL_BLOC: "CLEAR_FETCH_ALL_BLOC",
	FETCH_ONE_BLOC: "FETCH_ONE_BLOC",
	CLEAR_FETCH_ONE_BLOC: "CLEAR_FETCH_ONE_BLOC",
	UPDATE_BLOC: "UPDATE_BLOC",
	CLEAR_UPDATE_BLOC: "CLEAR_UPDATE_BLOC",
	CREATE_BLOC: "CREATE_BLOC",
	CLEAR_CREATE_BLOC: "CLEAR_CREATE_BLOC",
	DELETE_BLOC: "DELETE_BLOC",
	CLEAR_DELETE_BLOC: "CLEAR_DELETE_BLOC",

	//ACTE
	FETCH_ALL_ACTE: "FETCH_ALL_ACTE",
	CLEAR_FETCH_ALL_ACTE: "CLEAR_FETCH_ALL_ACTE",
	FETCH_ALL_ACTE_FOR_TREATMENT: "FETCH_ALL_ACTE_FOR_TREATMENT",
	CLEAR_FETCH_ALL_ACTE_FOR_TREATMENT: "CLEAR_FETCH_ALL_ACTE_FOR_TREATMENT",
	CREATE_ACTE: "CREATE_ACTE",
	CLEAR_CREATE_ACTE: "CLEAR_CREATE_ACTE",
	DELETE_ACTE: "DELETE_ACTE",
	CLEAR_DELETE_ACTE: "CLEAR_DELETE_ACTE",
	ACTE_RANK_UPDATE: "ACTE_RANK_UPDATE",
	CLEAR_ACTE_RANK_UPDATE: "CLEAR_ACTE_RANK_UPDATE",
	RENDER_ACTE_LIST: "RENDER_ACTE_LIST",

	//MEDICAL UNITS
	FETCH_ALL_MEDICAL_UNITS: "FETCH_ALL_MEDICAL_UNITS",
	CLEAR_FETCH_ALL_MEDICAL_UNITS: "CLEAR_FETCH_ALL_MEDICAL_UNITS",
	FETCH_ONE_MEDICAL_UNITS: "FETCH_ONE_MEDICAL_UNITS",
	CLEAR_FETCH_ONE_MEDICAL_UNITS: "CLEAR_FETCH_ONE_MEDICAL_UNITS",
	UPDATE_MEDICAL_UNITS: "UPDATE_MEDICAL_UNITS",
	CLEAR_UPDATE_MEDICAL_UNITS: "CLEAR_UPDATE_MEDICAL_UNITS",
	CREATE_MEDICAL_UNITS: "CREATE_MEDICAL_UNITS",
	CLEAR_CREATE_MEDICAL_UNITS: "CLEAR_CREATE_MEDICAL_UNITS",
	DELETE_MEDICAL_UNITS: "DELETE_MEDICAL_UNITS",
	CLEAR_DELETE_MEDICAL_UNITS: "CLEAR_DELETE_MEDICAL_UNITS",
	FETCH_ALL_MEDICAL_UNITS_FOR_ACCOMMODATION: "FETCH_ALL_MEDICAL_UNITS_FOR_ACCOMMODATION",
	CLEAR_FETCH_ALL_MEDICAL_UNITS_FOR_ACCOMMODATION: "CLEAR_FETCH_ALL_MEDICAL_UNITS_FOR_ACCOMMODATION",
	FETCH_ALL_ACCOMMODATION_TEXTELEMTS_FOR_ADMINISTRATION: "FETCH_ALL_ACCOMMODATION_TEXTELEMTS_FOR_ADMINISTRATION",
	CLEAR_FETCH_ALL_ACCOMMODATION_TEXTELEMTS_FOR_ADMINISTRATION: "CLEAR_FETCH_ALL_ACCOMMODATION_TEXTELEMTS_FOR_ADMINISTRATION",
	FETCH_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION: "FETCH_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION",
	CLEAR_FETCH_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION: "CLEAR_FETCH_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION",
	UPDATE_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION: "UPDATE_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION",
	CLEAR_UPDATE_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION: "CLEAR_UPDATE_ONE_ACCOMMODATION_ACCOUNT_FOR_ADMINISTRATION",
	GET_ACCOMMODATION_SECTIONS: "GET_ACCOMMODATION_SECTIONS",
	CLEAR_GET_ACCOMMODATION_SECTIONS: "CLEAR_GET_ACCOMMODATION_SECTIONS",
	//ACCOMMODATION TYPE
	FETCH_ALL_ACCOMMODATION_TYPE: "FETCH_ALL_ACCOMMODATION_TYPE",
	CLEAR_FETCH_ALL_ACCOMMODATION_TYPE: "CLEAR_FETCH_ALL_ACCOMMODATION_TYPE",
	FETCH_ONE_ACCOMMODATION_TYPE: "FETCH_ONE_ACCOMMODATION_TYPE",
	CLEAR_FETCH_ONE_ACCOMMODATION_TYPE: "CLEAR_FETCH_ONE_ACCOMMODATION_TYPE",
	UPDATE_ACCOMMODATION_TYPE: "UPDATE_ACCOMMODATION_TYPE",
	CLEAR_UPDATE_ACCOMMODATION_TYPE: "CLEAR_UPDATE_ACCOMMODATION_TYPE",
	CREATE_ACCOMMODATION_TYPE: "CREATE_ACCOMMODATION_TYPE",
	CLEAR_CREATE_ACCOMMODATION_TYPE: "CLEAR_CREATE_ACCOMMODATION_TYPE",
	DELETE_ACCOMMODATION_TYPE: "DELETE_ACCOMMODATION_TYPE",
	CLEAR_DELETE_ACCOMMODATION_TYPE: "CLEAR_DELETE_ACCOMMODATION_TYPE",
	FETCH_TEMPLATE_ACCOMMODATION: "FETCH_TEMPLATE_ACCOMMODATION",
	CLEAR_FETCH_TEMPLATE_ACCOMMODATION: "CLEAR_FETCH_TEMPLATE_ACCOMMODATION",
	FETCH_EXISTING_MOM_ACCOMMODATION_DATA: "FETCH_EXISTING_MOM_ACCOMMODATION_DATA",
	CLEAR_FETCH_EXISTING_MOM_ACCOMMODATION_DATA: "CLEAR_FETCH_EXISTING_MOM_ACCOMMODATION_DATA",
	//COST CENTER
	FETCH_ALL_COST_CENTER: "FETCH_ALL_COST_CENTER",
	CLEAR_COST_CENTER_FETCH: "CLEAR_COST_CENTER_FETCH",
	FETCH_ALL_COST_CENTER_FOR_SERVICE: "FETCH_ALL_COST_CENTER_FOR_SERVICE",
	CLEAR_FETCH_ALL_COST_CENTER_FOR_SERVICE: "CLEAR_FETCH_ALL_COST_CENTER_FOR_SERVICE",
	FETCH_ONE_COST_CENTER: "FETCH_ONE_COST_CENTER",
	CLEAR_FETCH_ONE_COST_CENTER: "CLEAR_FETCH_ONE_COST_CENTER",
	UPDATE_COST_CENTER: "UPDATE_COST_CENTER",
	UPDATE_CLEAR_COST_CENTER: "UPDATE_CLEAR_COST_CENTER",
	CREATE_COST_CENTER: "CREATE_COST_CENTER",
	CREATE_CLEAR_COST_CENTER: "CREATE_CLEAR_COST_CENTER",
	DELETE_COST_CENTER: "DELETE_COST_CENTER",
	CLEAR_COST_CENTER_DELETE: "CLEAR_COST_CENTER_DELETE",
	FETCH_ALL_COST_CENTER_FOR_ACCOMMODATION: "FETCH_ALL_COST_CENTER_FOR_ACCOMMODATION",
	CLEAR_FETCH_ALL_COST_CENTER_FOR_ACCOMMODATION: "CLEAR_FETCH_ALL_COST_CENTER_FOR_ACCOMMODATION",

	//TEXT MASTER DATA
	FETCH_ALL_TEXT_MASTER_DATA: "FETCH_ALL_TEXT_MASTER_DATA",
	CLEAR_TEXT_MASTER_DATA_FETCH: "CLEAR_TEXT_MASTER_DATA_FETCH",
	FETCH_ONE_TEXT_MASTER_DATA: "FETCH_ONE_TEXT_MASTER_DATA",
	CLEAR_FETCH_ONE_TEXT_MASTER_DATA: "CLEAR_FETCH_ONE_TEXT_MASTER_DATA",
	UPDATE_TEXT_MASTER_DATA: "UPDATE_TEXT_MASTER_DATA",
	UPDATE_CLEAR_TEXT_MASTER_DATA: "UPDATE_CLEAR_TEXT_MASTER_DATA",
	CREATE_TEXT_MASTER_DATA: "CREATE_TEXT_MASTER_DATA",
	CREATE_CLEAR_TEXT_MASTER_DATA: "CREATE_CLEAR_TEXT_MASTER_DATA",
	DELETE_TEXT_MASTER_DATA: "DELETE_TEXT_MASTER_DATA",
	CLEAR_TEXT_MASTER_DATA_DELETE: "CLEAR_TEXT_MASTER_DATA_DELETE",

	//SERVICE
	FETCH_ALL_SERVICE: "FETCH_ALL_SERVICE",
	CLEAR_SERVICE_FETCH: "CLEAR_SERVICE_FETCH",
	FETCH_ONE_SERVICE: "FETCH_ONE_SERVICE",
	CLEAR_FETCH_ONE_SERVICE: "CLEAR_FETCH_ONE_SERVICE",
	UPDATE_SERVICE: "UPDATE_SERVICE",
	UPDATE_CLEAR_SERVICE: "UPDATE_CLEAR_SERVICE",
	CREATE_SERVICE: "CREATE_SERVICE",
	CLEAR_CREATE_SERVICE: "CLEAR_CREATE_SERVICE",
	DELETE_SERVICE: "DELETE_SERVICE",
	CLEAR_SERVICE_DELETE: "CLEAR_SERVICE_DELETE",
	FETCH_ALL_SERVICE_FOR_POLE: "FETCH_ALL_SERVICE_FOR_POLE",
	CLEAR_FETCH_ALL_SERVICE_FOR_POLE: "CLEAR_FETCH_ALL_SERVICE_FOR_POLE",

	//BLOCTYPE
	FETCH_ALL_BLOCTYPE: "FETCH_ALL_BLOCTYPE",
	CLEAR_BLOCTYPE_FETCH: "CLEAR_BLOCTYPE_FETCH",
	FETCH_ONE_BLOCTYPE: "FETCH_ONE_BLOCTYPE",
	CLEAR_FETCH_ONE_BLOCTYPE: "CLEAR_FETCH_ONE_BLOCTYPE",
	UPDATE_BLOCTYPE: "UPDATE_BLOCTYPE",
	UPDATE_CLEAR_BLOCTYPE: "UPDATE_CLEAR_BLOCTYPE",
	CREATE_BLOCTYPE: "CREATE_BLOCTYPE",
	CREATE_CLEAR_BLOCTYPE: "CREATE_CLEAR_BLOCTYPE",
	DELETE_BLOCTYPE: "DELETE_BLOCTYPE",
	CLEAR_BLOCTYPE_DELETE: "CLEAR_BLOCTYPE_DELETE",

	//ADMIN PERSONNEL
	FETCH_ALL_PERSONNEL_TEMPLATES: "FETCH_ALL_PERSONNEL_TEMPLATES",
	CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES: "CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES",
	FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL: "FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL",
	CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL: "CLEAR_FETCH_ALL_PERSONNEL_TEMPLATES_MINIMAL",
	FETCH_ONE_PERSONNEL_TEMPLATE: "FETCH_ONE_PERSONNEL_TEMPLATE",
	CLEAR_FETCH_ONE_PERSONNEL_TEMPLATE: "CLEAR_FETCH_ONE_PERSONNEL_TEMPLATE",
	CREATE_PERSONNEL_TEMPLATE: "CREATE_PERSONNEL_TEMPLATE",
	CLEAR_CREATE_PERSONNEL_TEMPLATE: "CLEAR_CREATE_PERSONNEL_TEMPLATE",
	UPDATE_PERSONNEL_TEMPLATE: "UPDATE_PERSONNEL_TEMPLATE",
	CLEAR_UPDATE_PERSONNEL_TEMPLATE: "CLEAR_UPDATE_PERSONNEL_TEMPLATE",
	DELETE_PERSONNEL_TEMPLATE: "DELETE_PERSONNEL_TEMPLATE",
	CLEAR_DELETE_PERSONNEL_TEMPLATE: "CLEAR_DELETE_PERSONNEL_TEMPLATE",
	FETCH_ALL_TEMPLATE_DETAILS: "FETCH_ALL_TEMPLATE_DETAILS",
	CLEAR_FETCH_ALL_TEMPLATE_DETAILS: "CLEAR_FETCH_ALL_TEMPLATE_DETAILS",
	FETCH_ONE_TEMPLATE_DETAILS: "FETCH_ONE_TEMPLATE_DETAILS",
	CLEAR_FETCH_ONE_TEMPLATE_DETAILS: "CLEAR_FETCH_ONE_TEMPLATE_DETAILS",
	CREATE_TEMPLATE_DETAILS: "CREATE_TEMPLATE_DETAILS",
	CLEAR_CREATE_TEMPLATE_DETAILS: "CLEAR_CREATE_TEMPLATE_DETAILS",
	UPDATE_TEMPLATE_DETAILS: "UPDATE_TEMPLATE_DETAILS",
	CLEAR_UPDATE_TEMPLATE_DETAILS: "CLEAR_UPDATE_TEMPLATE_DETAILS",
	DELETE_TEMPLATE_DETAILS: "DELETE_TEMPLATE_DETAILS",
	CLEAR_DELETE_TEMPLATE_DETAILS: "CLEAR_DELETE_TEMPLATE_DETAILS",
	UPDATE_STAFF_RANK: "UPDATE_STAFF_RANK",
	CLEAR_UPDATE_STAFF_RANK: "CLEAR_UPDATE_STAFF_RANK",
	FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES: "FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES",
	CLEAR_FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES: "CLEAR_FETCH_ALL_APPROVED_PERSONNEL_TEMPLATES",
	SAVE_AND_APPROVE_PERSONNEL_TEMPLATES: "SAVE_AND_APPROVE_PERSONNEL_TEMPLATES",
	SAVE_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED: "SAVE_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED",
	ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES: "ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES",
	ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED: "ASSIGN_AND_APPROVE_PERSONNEL_TEMPLATES_COMPLETED",
	FETCH_ALL_NEW_TEMPLATES: "FETCH_ALL_NEW_TEMPLATES",
	CLEAR_FETCH_ALL_NEW_TEMPLATES: "CLEAR_FETCH_ALL_NEW_TEMPLATES",
	FETCH_ALL_EXISTING_TEMPLATES: "FETCH_ALL_EXISTING_TEMPLATES",
	CLEAR_FETCH_ALL_EXISTING_TEMPLATES: "CLEAR_FETCH_ALL_EXISTING_TEMPLATES",
	SAVE_AND_APPROVE_TEMPLATES: "SAVE_AND_APPROVE_TEMPLATES",
	SAVE_AND_APPROVE_TEMPLATES_COMPLETED: "SAVE_AND_APPROVE_TEMPLATES_COMPLETED",
	ASSIGN_AND_APPROVE_TEMPLATES: "ASSIGN_AND_APPROVE_TEMPLATES",
	ASSIGN_AND_APPROVE_TEMPLATES_COMPLETED: "ASSIGN_AND_APPROVE_TEMPLATES_COMPLETED",

	//ADMIN CLINICAL PATHWAY
	FETCH_ALL_CLINICAL_PATHWAY_PROBLEM: "FETCH_ALL_CLINICAL_PATHWAY_PROBLEM",
	CLEAR_FETCH_ALL_CLINICAL_PATHWAY_PROBLEM: "CLEAR_FETCH_ALL_CLINICAL_PATHWAY_PROBLEM",
	FETCH_ALL_CLINICAL_PATHWAY_STEP: "FETCH_ALL_CLINICAL_PATHWAY_STEP",
	CLEAR_FETCH_ALL_CLINICAL_PATHWAY_STEP: "CLEAR_FETCH_ALL_CLINICAL_PATHWAY_STEP",
	FETCH_ONE_CLINICAL_PATHWAY_PROBLEM: "FETCH_ONE_CLINICAL_PATHWAY_PROBLEM",
	CLEAR_FETCH_ONE_CLINICAL_PATHWAY_PROBLEM: "CLEAR_FETCH_ONE_CLINICAL_PATHWAY_PROBLEM",
	FETCH_ONE_CLINICAL_PATHWAY_STEP: "FETCH_ONE_CLINICAL_PATHWAY_STEP",
	CLEAR_FETCH_ONE_CLINICAL_PATHWAY_STEP: "CLEAR_FETCH_ONE_CLINICAL_PATHWAY_STEP",
	CREATE_CLINICAL_PATHWAY_PROBLEM: "CREATE_CLINICAL_PATHWAY_PROBLEM",
	CLEAR_CREATE_CLINICAL_PATHWAY_PROBLEM: "CLEAR_CREATE_CLINICAL_PATHWAY_PROBLEM",
	CREATE_CLINICAL_PATHWAY_STEP: "CREATE_CLINICAL_PATHWAY_STEP",
	CLEAR_CREATE_CLINICAL_PATHWAY_STEP: "CLEAR_CREATE_CLINICAL_PATHWAY_STEP",
	UPDATE_CLINICAL_PATHWAY_PROBLEM: "UPDATE_CLINICAL_PATHWAY_PROBLEM",
	CLEAR_UPDATE_CLINICAL_PATHWAY_PROBLEM: "CLEAR_UPDATE_CLINICAL_PATHWAY_PROBLEM",
	UPDATE_CLINICAL_PATHWAY_STEP: "UPDATE_CLINICAL_PATHWAY_STEP",
	CLEAR_UPDATE_CLINICAL_PATHWAY_STEP: "CLEAR_UPDATE_CLINICAL_PATHWAY_STEP",
	DELETE_CLINICAL_PATHWAY_PROBLEM: "DELETE_CLINICAL_PATHWAY_PROBLEM",
	CLEAR_DELETE_CLINICAL_PATHWAY_PROBLEM: "CLEAR_DELETE_CLINICAL_PATHWAY_PROBLEM",
	DELETE_CLINICAL_PATHWAY_STEP: "DELETE_CLINICAL_PATHWAY_STEP",
	CLEAR_DELETE_CLINICAL_PATHWAY_STEP: "CLEAR_DELETE_CLINICAL_PATHWAY_STEP",
	FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP: "FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP",
	CLEAR_FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP: "CLEAR_FETCH_ALL_CLINICAL_PATHWAY_SUB_STEP",
	FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP: "FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP",
	CLEAR_FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP: "CLEAR_FETCH_ONE_CLINICAL_PATHWAY_SUB_STEP",
	CREATE_CLINICAL_PATHWAY_SUB_STEP: "CREATE_CLINICAL_PATHWAY_SUB_STEP",
	CLEAR_CREATE_CLINICAL_PATHWAY_SUB_STEP: "CLEAR_CREATE_CLINICAL_PATHWAY_SUB_STEP",
	UPDATE_CLINICAL_PATHWAY_SUB_STEP: "UPDATE_CLINICAL_PATHWAY_SUB_STEP",
	CLEAR_UPDATE_CLINICAL_PATHWAY_SUB_STEP: "CLEAR_UPDATE_CLINICAL_PATHWAY_SUB_STEP",
	DELETE_CLINICAL_PATHWAY_SUB_STEP: "DELETE_CLINICAL_PATHWAY_SUB_STEP",
	CLEAR_DELETE_CLINICAL_PATHWAY_SUB_STEP: "CLEAR_DELETE_CLINICAL_PATHWAY_SUB_STEP",
	FETCH_ALL_CLINICAL_PATHWAY_ITEMS: "FETCH_ALL_CLINICAL_PATHWAY_ITEMS",
	CLEAR_FETCH_ALL_CLINICAL_PATHWAY_ITEMS: "CLEAR_FETCH_ALL_CLINICAL_PATHWAY_ITEMS",
	FETCH_ALL_MINIMAL_ITEM: "FETCH_ALL_MINIMAL_ITEM",
	CLEAR_FETCH_ALL_MINIMAL_ITEM: "CLEAR_FETCH_ALL_MINIMAL_ITEM",
	FETCH_ONE_CLINICAL_PATHWAY_ITEMS: "FETCH_ONE_CLINICAL_PATHWAY_ITEMS",
	CLEAR_FETCH_ONE_CLINICAL_PATHWAY_ITEMS: "CLEAR_FETCH_ONE_CLINICAL_PATHWAY_ITEMS",
	CREATE_CLINICAL_PATHWAY_ITEMS: "CREATE_CLINICAL_PATHWAY_ITEMS",
	CLEAR_CREATE_CLINICAL_PATHWAY_ITEMS: "CLEAR_CREATE_CLINICAL_PATHWAY_ITEMS",
	UPDATE_CLINICAL_PATHWAY_ITEMS: "UPDATE_CLINICAL_PATHWAY_ITEMS",
	CLEAR_UPDATE_CLINICAL_PATHWAY_ITEMS: "CLEAR_UPDATE_CLINICAL_PATHWAY_ITEMS",
	DELETE_CLINICAL_PATHWAY_ITEMS: "DELETE_CLINICAL_PATHWAY_ITEMS",
	CLEAR_DELETE_CLINICAL_PATHWAY_ITEMS: "CLEAR_DELETE_CLINICAL_PATHWAY_ITEMS",
	FETCH_ALL_MINIMAL_STEP: "FETCH_ALL_MINIMAL_STEP",
	CLEAR_FETCH_ALL_MINIMAL_STEP: "CLEAT_FETCH_ALL_MINIMAL_STEP",
	FETCH_ALL_MINIMAL_SUB_STEP: "FETCH_ALL_MINIMAL_SUB_STEP",
	CLEAR_FETCH_ALL_MINIMAL_SUB_STEP: "CLEAR_FETCH_ALL_MINIMAL_SUB_STEP",
	STEP_RANK_UPDATE: "STEP_RANK_UPDATE",
	CLEAR_STEP_RANK_UPDATE: "CLEAR_STEP_RANK_UPDATE",
	SUB_STEP_RANK_UPDATE: "SUB_STEP_RANK_UPDATE",
	CLEAR_SUB_STEP_RANK_UPDATE: "CLEAR_SUB_STEP_RANK_UPDATE",
	REFRESH_FILTERS: "REFRESH_FILTERS",
	CLEAR_REFRESH_FILTERS: "CLEAR_REFRESH_FILTERS",
	REFRESH_SUB_STEP: "REFRESH_SUB_STEP",
	CLEAR_REFRESH_SUB_STEP: "CLEAR_REFRESH_SUB_STEP",
	SAVE_AND_APPROVE_STEP: "SAVE_AND_APPROVE_STEP",
	SAVE_AND_APPROVE_STEP_COMPLETED: "SAVE_AND_APPROVE_STEP_COMPLETED",
	ASSIGN_AND_APPROVE_STEP: "ASSIGN_AND_APPROVE_STEP",
	ASSIGN_AND_APPROVE_STEP_COMPLETED: "ASSIGN_AND_APPROVE_STEP_COMPLETED",
	SAVE_AND_APPROVE_SUB_STEP: "SAVE_AND_APPROVE_SUB_STEP",
	SAVE_AND_APPROVE_SUB_STEP_COMPLETED: "SAVE_AND_APPROVE_SUB_STEP_COMPLETED",
	ASSIGN_AND_APPROVE_SUB_STEP: "ASSIGN_AND_APPROVE_SUB_STEP",
	ASSIGN_AND_APPROVE_SUB_STEP_COMPLETED: "ASSIGN_AND_APPROVE_SUB_STEP_COMPLETED",
	SAVE_AND_APPROVE_ITEM: "SAVE_AND_APPROVE_ITEM",
	SAVE_AND_APPROVE_ITEM_COMPLETED: "SAVE_AND_APPROVE_ITEM_COMPLETED",
	ASSIGN_AND_APPROVE_ITEM: "ASSIGN_AND_APPROVE_ITEM",
	ASSIGN_AND_APPROVE_ITEM_COMPLETED: "ASSIGN_AND_APPROVE_ITEM_COMPLETED",
	IS_STEP_IS_USED_STATUS: "IS_STEP_IS_USED_STATUS",
	CLEAR_IS_STEP_IS_USED_STATUS: "CLEAR_IS_STEP_IS_USED_STATUS",

	//CATEGORY PERSONNEL
	FETCH_ALL_CATEGORY_PERSONNEL: "FETCH_ALL_CATEGORY_PERSONNEL",
	CLEAR_FETCH_ALL_CATEGORY_PERSONNEL: "CLEAR_FETCH_ALL_CATEGORY_PERSONNEL",
	FETCH_ONE_CATEGORY_PERSONNEL: "FETCH_ONE_CATEGORY_PERSONNEL",
	CLEAR_FETCH_ONE_CATEGORY_PERSONNEL: "CLEAR_FETCH_ONE_CATEGORY_PERSONNEL",
	CREATE_CATEGORY_PERSONNEL: "CREATE_CATEGORY_PERSONNEL",
	CLEAR_CREATE_CATEGORY_PERSONNEL: "CLEAR_CREATE_CATEGORY_PERSONNEL",
	UPDATE_CATEGORY_PERSONNEL: "UPDATE_CATEGORY_PERSONNEL",
	CLEAR_UPDATE_CATEGORY_PERSONNEL: "CLEAR_UPDATE_CATEGORY_PERSONNEL",
	DELETE_CATEGORY_PERSONNEL: "DELETE_CATEGORY_PERSONNEL",
	CLEAR_DELETE_CATEGORY_PERSONNEL: "CLEAR_DELETE_CATEGORY_PERSONNEL",
	FETCH_ALL_APPROVED_CATEGORY_PERSONNEL: "FETCH_ALL_APPROVED_CATEGORY_PERSONNEL",
	CLEAR_FETCH_ALL_APPROVED_CATEGORY_PERSONNEL: "CLEAR_FETCH_ALL_APPROVED_CATEGORY_PERSONNEL",
	SAVE_AND_APPROVE_CATEGORY_PERSONNEL: "SAVE_AND_APPROVE_CATEGORY_PERSONNEL",
	SAVE_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED: "SAVE_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED",
	ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL: "ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL",
	ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED: "ASSIGN_AND_APPROVE_CATEGORY_PERSONNEL_COMPLETED",

	//CONSUMABLE DEFINITION
	FETCH_ALL_CONSUMABLE_DEFINITION: "FETCH_ALL_CONSUMABLE_DEFINITION",
	CLEAR_FETCH_ALL_CONSUMABLE_DEFINITION: "CLEAR_FETCH_ALL_CONSUMABLE_DEFINITION",
	FETCH_ONE_CONSUMABLE_DEFINITION: "FETCH_ONE_CONSUMABLE_DEFINITION",
	CLEAR_FETCH_ONE_CONSUMABLE_DEFINITION: "CLEAR_FETCH_ONE_CONSUMABLE_DEFINITION",
	UPDATE_CONSUMABLE_DEFINITION: "UPDATE_CONSUMABLE_DEFINITION",
	CLEAR_UPDATE_CONSUMABLE_DEFINITION: "CLEAR_UPDATE_CONSUMABLE_DEFINITION",
	DELETE_CONSUMABLE_DEFINITION: "DELETE_CONSUMABLE_DEFINITION",
	CLEAR_DELETE_CONSUMABLE_DEFINITION: "CLEAR_DELETE_CONSUMABLE_DEFINITION",
	UPDATE_CONSUMABLE_DEFINITION_MINIMAL: "UPDATE_CONSUMABLE_DEFINITION_MINIMAL",
	CLEAR_UPDATE_CONSUMABLE_DEFINITION_MINIMAL: "CLEAR_UPDATE_CONSUMABLE_DEFINITION_MINIMAL",
	COPY_PAST_STERILIZATION: "COPY_PAST_STERILIZATION",
	COPY_PAST_STERILIZATION_COMPLETED: "COPY_PAST_STERILIZATION_COMPLETED",
	ASSIGN_STERILIZATION_CLASS_LIST: "ASSIGN_STERILIZATION_CLASS_LIST",
	ASSIGN_STERILIZATION_CLASS_LIST_COMLETED: "ASSIGN_STERILIZATION_CLASS_LIST_COMLETED",
	ASSIGN_STERILIZATION_CLASS: "ASSIGN_STERILIZATION_CLASS",
	ASSIGN_STERILIZATION_CLASS_COMPLETED: "ASSIGN_STERILIZATION_CLASS_COMPLETED",

	//CONSUMABLE DCI
	FETCH_ALL_CONSUMABLE_DCI: "FETCH_ALL_CONSUMABLE_DCI",
	CLEAR_FETCH_ALL_CONSUMABLE_DCI: "CLEAR_FETCH_ALL_CONSUMABLE_DCI",
	FETCH_ONE_CONSUMABLE_DCI: "FETCH_ONE_CONSUMABLE_DCI",
	CLEAR_FETCH_ONE_CONSUMABLE_DCI: "CLEAR_FETCH_ONE_CONSUMABLE_DCI",
	UPDATE_CONSUMABLE_DCI: "UPDATE_CONSUMABLE_DCI",
	CLEAR_UPDATE_CONSUMABLE_DCI: "CLEAR_UPDATE_CONSUMABLE_DCI",
	DELETE_CONSUMABLE_DCI: "DELETE_CONSUMABLE_DCI",
	CLEAR_DELETE_CONSUMABLE_DCI: "CLEAR_DELETE_CONSUMABLE_DCI",
	FETCH_ALL_DCI_LIST: "FETCH_ALL_DCI_LIST",
	CLEAR_FETCH_ALL_DCI_LIST: "CLEAR_FETCH_ALL_DCI_LIST",
	UPDATE_DCI_ELEMENT: "UPDATE_DCI_ELEMENT",
	CLEAR_UPDATE_DCI_ELEMENT: "CLEAR_UPDATE_DCI_ELEMENT",
	FETCH_ALL_DCI_PREPARATION_PERFUSION: "FETCH_ALL_DCI_PREPARATION_PERFUSION",
	CLEAR_FETCH_ALL_DCI_PREPARATION_PERFUSION: "CLEAR_FETCH_ALL_DCI_PREPARATION_PERFUSION",
	GET_ONE_DCI: "GET_ONE_DCI",
	CLEAR_GET_ONE_DCI: "CLEAR_GET_ONE_DCI",
	CREATE_DCI_PERFUSION_PREPARATION: "CREATE_DCI_PERFUSION_PREPARATION",
	CLEAR_CREATE_DCI_PERFUSION_PREPARATION: "CLEAR_CREATE_DCI_PERFUSION_PREPARATION",
	DELETE_DCI_PREPARATION_PERFUSION: "DELETE_DCI_PREPARATION_PERFUSION",
	CLEAR_DELETE_DCI_PREPARATION_PERFUSION: "CLEAR_DELETE_DCI_PREPARATION_PERFUSION",
	FETCH_ALL_ELEMENTS_FOR_DCI: "FETCH_ALL_ELEMENTS_FOR_DCI",
	CLEAR_FETCH_ALL_ELEMENTS_FOR_DCI: "CLEAR_FETCH_ALL_ELEMENTS_FOR_DCI",
	GET_ONE_PREPARATION_PERFUSION_FOR_DCI: "GET_ONE_PREPARATION_PERFUSION_FOR_DCI",
	CLEAR_GET_ONE_PREPARATION_PERFUSION_FOR_DCI: "CLEAR_GET_ONE_PREPARATION_PERFUSION_FOR_DCI",
	UPDATE_PREPARATION_PERFUSION_FOR_DCI: "UPDATE_PREPARATION_PERFUSION_FOR_DCI",
	CLEAR_UPDATE_PREPARATION_PERFUSION_FOR_DCI: "CLEAR_UPDATE_PREPARATION_PERFUSION_FOR_DCI",
	PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI: "PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI",
	CLEAR_PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI: "CLEAR_PREPARATION_PERFUSION_RANK_UPDATE_FOR_DCI",
	TREATMENT_LIST_FOR_DCI: "TREATMENT_LIST_FOR_DCI",
	CLEAR_TREATMENT_LIST_FOR_DCI: "CLEAR_TREATMENT_LIST_FOR_DCI",
	FETCH_ALL_DCI_ELEMENT: "FETCH_ALL_DCI_ELEMENT",
	CLEAR_FETCH_ALL_DCI_ELEMENT: "CLEAR_FETCH_ALL_DCI_ELEMENT",
	FETCH_ONE_CONSUMABLE_CLASS: "FETCH_ONE_CONSUMABLE_CLASS",
	CLEAR_FETCH_ONE_CONSUMABLE_CLASS: "CLEAR_FETCH_ONE_CONSUMABLE_CLASS",
	UPDATE_CONSUMABLE_CLASS: "UPDATE_CONSUMABLE_CLASS",
	CLEAR_UPDATE_CONSUMABLE_CLASS: "CLEAR_UPDATE_CONSUMABLE_CLASS",
	FILTER_CHANGE_CONSUMABLE: "FILTER_CHANGE_CONSUMABLE",
	SAVE_AND_APPROVE_CONSUMABLE_CLASS: "SAVE_AND_APPROVE_CONSUMABLE_CLASS",
	SAVE_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED: "SAVE_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED",
	ASSIGN_AND_APPROVE_CONSUMABLE_CLASS: "ASSIGN_AND_APPROVE_CONSUMABLE_CLASS",
	ASSIGN_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED: "ASSIGN_AND_APPROVE_CONSUMABLE_CLASS_COMPLETED",

	//STERILIZATION 

	FETCH_ALL_STERILIZATION: "FETCH_ALL_STERILIZATION",
	CLEAR_FETCH_ALL_STERILIZATION: "CLEAR_FETCH_ALL_STERILIZATION",
	FETCH_ONE_STERILIZATION: "FETCH_ONE_STERILIZATION",
	CLEAR_FETCH_ONE_STERILIZATION: "CLEAR_FETCH_ONE_STERILIZATION",
	CREATE_STERILIZATION: "CREATE_STERILIZATION",
	CLEAR_CREATE_STERILIZATION: "CLEAR_CREATE_STERILIZATION",
	UPDATE_STERILIZATION: "UPDATE_STERILIZATION",
	CLEAR_UPDATE_STERILIZATION: "CLEAR_UPDATE_STERILIZATION",
	DELETE_STERILIZATION: "DELETE_STERILIZATION",
	CLEAR_DELETE_STERILIZATION: "CLEAR_DELETE_STERILIZATION",
	FETCH_FAMILY_FOR_STERILIZATION: "FETCH_FAMILY_FOR_STERILIZATION",
	CLEAR_FETCH_FAMILY_FOR_STERILIZATION: "CLEAR_FETCH_FAMILY_FOR_STERILIZATION",

	FETCH_ALL_CLASS_STERILIZATION: "FETCH_ALL_CLASS_STERILIZATION",
	CLEAR_FETCH_ALL_CLASS_STERILIZATION: "CLEAR_FETCH_ALL_CLASS_STERILIZATION",
	CREATE_CLASS_STERILIZATION: "CREATE_CLASS_STERILIZATION",
	CLEAR_CREATE_CLASS_STERILIZATION: "CLEAR_CREATE_CLASS_STERILIZATION",
	UPDATE_CLASS_STERILIZATION: "UPDATE_CLASS_STERILIZATION",
	CLEAR_UPDATE_CLASS_STERILIZATION: "CLEAR_UPDATE_CLASS_STERILIZATION",
	DELETE_CLASS_STERILIZATION: "DELETE_CLASS_STERILIZATION:",
	CLEAR_DELETE_CLASS_STERILIZATION: "CLEAR_DELETE_CLASS_STERILIZATION",
	FETCH_ONE_CLASS_STERILIZATION: "FETCH_ONE_CLASS_STERILIZATION",
	CLEAR_FETCH_ONE_CLASS_STERILIZATION: "CLEAR_FETCH_ONE_CLASS_STERILIZATION",
	GET_CLASS_LIST: "GET_CLASS_LIST",
	CLEAR_CLASS_LIST: "CLEAR_CLASS_LIST",
	GET_CONDITIONMENT_LIST: "GET_CONDITIONMENT_LIST",
	CLEAR_CONDITIONMENT_LIST: "CLEAR_CONDITIONMENT_LIST",
	//TEXTELEMENTS
	CLEAR_TEXTELEMENTS_GET_ALL: "CLEAR_TEXTELEMENTS_GET_ALL",
	TEXTELEMENTS_GET_ALL: "TEXTELEMENTS_GET_ALL",
	TEXTELEMENTS_ADD: "TEXTELEMENTS_ADD",
	TEXTELEMENTS_GET: "TEXTELEMENTS_GET",
	CLEAR_TEXTELEMENTS_ADD: "CLEAR_TEXTELEMENTS_ADD",
	TEXTELEMENTS_DELETE: "TEXTELEMENTS_DELETE",
	CLEAR_TEXTELEMENTS_DELETE: "CLEAR_TEXTELEMENTS_DELETE",
	CLEAR_TEXTELEMENTS_GET: "CLEAR_TEXTELEMENTS_GET",
	UPLOAD_PIC: "UPLOAD_PIC",
	TEXTELEMENTS_IMAGE_ADD: "TEXTELEMENTS_IMAGE_ADD",
	CLEAR_UPLOAD_PIC: "CLEAR_UPLOAD_PIC",
	CLEAR_TEXTELEMENTS_IMAGE_ADD: "CLEAR_TEXTELEMENTS_IMAGE_ADD",
	TEXTELEMENTS_GET_ALL_IMAGES: "TEXTELEMENTS_GET_ALL_IMAGES",
	UPDATE_TEXT_ELEMENT: "UPDATE_TEXT_ELEMENT",
	CLEAR_UPDATE_TEXT_ELEMENT: "CLEAR_UPDATE_TEXT_ELEMENT",
	ELIGIBILITY_GET: "ELIGIBILITY_GET",
	CLEAR_ELIGIBILITY_GET: "CLEAR_ELIGIBILITY_GET",
	ELIGIBILITY_GET_ONE: "ELIGIBILITY_GET_ONE",
	CLEAR_ELIGIBILITY_GET_ONE: "CLEAR_ELIGIBILITY_GET_ONE",
	UPDATE_ELIGIBILITY: "UPDATE_ELIGIBILITY",
	CLEAR_UPDATE_ELIGIBILITY: "CLEAR_UPDATE_ELIGIBILITY",
	HEALTH_PATHWAY_DEFINITION_GET_ONE: "HEALTH_PATHWAY_DEFINITION_GET_ONE",
	CLEAR_HEALTH_PATHWAY_DEFINITION_GET_ONE: "CLEAR_HEALTH_PATHWAY_DEFINITION_GET_ONE",
	UPDATE_HEALTH_PATHWAY_DEFINITION: "UPDATE_HEALTH_PATHWAY_DEFINITION",
	CLEAR_UPDATE_HEALTH_PATHWAY_DEFINITION: "CLEAR_UPDATE_HEALTH_PATHWAY_DEFINITION",
	HEALTH_PATHWAY_REMARKS_GET_ONE: "HEALTH_PATHWAY_REMARKS_GET_ONE",
	CLEAR_HEALTH_PATHWAY_REMARKS_GET_ONE: "CLEAR_HEALTH_PATHWAY_REMARKS_GET_ONE",
	UPDATE_HEALTH_PATHWAY_REMARKS: "UPDATE_HEALTH_PATHWAY_REMARKS",
	CLEAR_UPDATE_HEALTH_PATHWAY_REMARKS: "CLEAR_UPDATE_HEALTH_PATHWAY_REMARKS",
	// REGROUPMENT

	GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT: "GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT",
	CLEAR_GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT: "CLEAR_GET_ALL_DETAILS_HEALTH_PATH_WAY_RE_GROUPMENT",
	GET_LINKED_GROUP_TASK_LIST_DROP_DOWN: "GET_LINKED_GROUP_TASK_LIST_DROP_DOWN",
	CLEAR_GET_LINKED_GROUP_TASK_LIST_DROP_DOWN: "CLEAR_GET_LINKED_GROUP_TASK_LIST_DROP_DOWN",
	//ELEMENTS
	CLEAR_ELEMENTS_GET_ALL: "CLEAR_ELEMENTS_GET_ALL",
	ELEMENTS_GET_ALL: "ELEMENTS_GET_ALL",
	ELEMENTS_ADD: "ELEMENTS_ADD",
	ELEMENTS_GET: "ELEMENTS_GET",
	CLEAR_ELEMENTS_ADD: "CLEAR_ELEMENTS_ADD",
	ELEMENTS_DELETE: "ELEMENTS_DELETE",
	CLEAR_ELEMENTS_DELETE: "CLEAR_ELEMENTS_DELETE",
	CLEAR_ELEMENTS_GET: "CLEAR_ELEMENTS_GET",
	ELEMENTS_UPDATE: "ELEMENTS_UPDATE",
	CLEAR_ELEMENTS_UPDATE: "CLEAR_ELEMENTS_UPDATE",
	ELEMENTS_GET_ONE: "ELEMENTS_GET_ONE",
	STAFF_ELEMENTS_GET_ALL: "STAFF_ELEMENTS_GET_ALL",
	STAFF_ELEMENTS_UPDATE: "STAFF_ELEMENTS_UPDATE",
	CLEAR_STAFF_ELEMENTS_GET_ALL: "CLEAR_STAFF_ELEMENTS_GET_ALL",
	CLEAR_STAFF_ELEMENTS_UPDATE: "CLEAR_STAFF_ELEMENTS_UPDATE",
	STAFF_ELEMENTS_GET_ONE: "STAFF_ELEMENTS_GET_ONE",
	CLEAR_STAFF_ELEMENTS_GETONE: "CLEAR_STAFF_ELEMENTS_GETONE",
	RELOAD_STAFF: "RELOAD_STAFF",
	CLEAR_RELOAD_STAFF: "CLEAR_RELOAD_STAFF",
	TEXTELEMENTS_IMAGE_DELETE: "TEXTELEMENTS_IMAGE_DELETE",
	CLEAR_TEXTELEMENTS_IMAGE_DELETE: "CLEAR_TEXTELEMENTS_IMAGE_DELETE",
	SWITCH_TEMPLATE: "SWITCH_TEMPLATE",
	CLEAR_SWITCH_TEMPLATE: "CLEAR_SWITCH_TEMPLATE",
	TRANSFER_TEMPLATE_INFO: "TRANSFER_TEMPLATE_INFO",
	GET_ALL_EXISTING_CONSUMABLE: "GET_ALL_EXISTING_CONSUMABLE",
	CLEAR_GET_ALL_EXISTING_CONSUMABLE: "CLEAR_GET_ALL_EXISTING_CONSUMABLE",
	GET_ALL_NEW_CONSUMABLE: "GET_ALL_NEW_CONSUMABLE",
	CLEAR_GET_ALL_NEW_CONSUMABLE: "CLEAR_GET_ALL_NEW_CONSUMABLE",
	GET_ALL_EXISTING_STERLIZATION_CONSUMABLE: "GET_ALL_EXISTING_STERLIZATION_CONSUMABLE",
	CLEAR_GET_ALL_EXISTING_STERLIZATION_CONSUMABLE: "CLEAR_GET_ALL_EXISTING_STERLIZATION_CONSUMABLE",
	GET_ALL_NEW_STERLIZATION_CONSUMABLE: "GET_ALL_NEW_STERLIZATION_CONSUMABLE",
	CLEAR_GET_ALL_NEW_STERLIZATION_CONSUMABLE: "CLEAR_GET_ALL_NEW_STERLIZATION_CONSUMABLE",

	//ANESTHESIA
	ANESTHESIA_GET_ALL: "ANESTHESIA_GET_ALL",
	ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL: "ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL",
	CLEAR_ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL: "CLEAR_ANESTHESIA_SELECT_DROP_DOWN_LIST_GET_ALL",
	ANESTHESIA_ADD: "ANESTHESIA_ADD",
	ANESTHESIA_GET: "ANESTHESIA_GET",
	ANESTHESIA_DELETE: "ANESTHESIA_DELETE",
	CLEAR_ANESTHESIA_DELETE: "CLEAR_ANESTHESIA_DELETE",
	CLEAR_ANESTHESIA_GET_ALL: "CLEAR_ANESTHESIA_GET_ALL",
	CLEAR_ANESTHESIA_ADD: "CLEAR_ANESTHESIA_ADD",
	CLEAR_ANESTHESIA_GET: "CLEAR_ANESTHESIA_GET",
	GET_ALL_ANAESTHESIA: "GET_ALL_ANAESTHESIA",
	CLEAR_GET_ALL_ANAESTHESIA: "CLEAR_GET_ALL_ANAESTHESIA",
	CREATE_ANAESTHESIA: "CREATE_ANAESTHESIA",
	CLEAR_CREATE_ANAESTHESIA: "CLEAR_CREATE_ANAESTHESIA",
	FETCH_ONE_ANAESTHESIA: "FETCH_ONE_ANAESTHESIA",
	CLEAR_FETCH_ONE_ANAESTHESIA: "CLEAR_FETCH_ONE_ANAESTHESIA",
	COPY_ANAESTHESIA: "COPY_ANAESTHESIA",
	CLEAR_COPY_ANAESTHESIA: "CLEAR_COPY_ANAESTHESIA",
	ANAESTHESIA_STAFF_GET: "ANAESTHESIA_STAFF_GET",
	CLEAR_ANAESTHESIA_STAFF_GET: "CLEAR_ANAESTHESIA_STAFF_GET",
	ANAESTHESIA_MEDICAL_DEVICES_GET: "ANAESTHESIA_MEDICAL_DEVICES_GET",
	CLEAR_ANAESTHESIA_MEDICAL_DEVICES_GET: "CLEAR_ANAESTHESIA_MEDICAL_DEVICES_GET",
	ANAESTHESIA_PHARMACY_GET: "ANAESTHESIA_PHARMACY_GET",
	CLEAR_ANAESTHESIA_PHARMACY_GET: "CLEAR_ANAESTHESIA_PHARMACY_GET",
	UPDATE_ANAESTHESIA: "UPDATE_ANAESTHESIA",
	CLEAR_UPDATE_ANAESTHESIA: "CLEAR_UPDATE_ANAESTHESIA",
	FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS: "FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS",
	CLEAR_FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS: "CLEAR_FETCH_ALL_STAFF_FOR_ANAESTHESIA_DETAILS",
	FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS: "FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS",
	CLEAR_FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS: "CLEAR_FETCH_SCANNED_STAFF_ANAESTHESIA_DETAILS",
	ANESTHESIA_REMOVE: "ANESTHESIA_REMOVE",
	ANESTHESIA_REMOVE_COMPLETED: "ANESTHESIA_REMOVE_COMPLETED",
	SAVE_AND_APPROVE_ANESTHESIA: "SAVE_AND_APPROVE_ANESTHESIA",
	CLEAR_SAVE_AND_APPROVE_ANESTHESIA: "CLEAR_SAVE_AND_APPROVE_ANESTHESIA",
	ASSIGN_AND_APPROVE_ANESTHESIA: "ASSIGN_AND_APPROVE_ANESTHESIA",
	CLEAR_ASSIGN_AND_APPROVE_ANESTHESIA: "CLEAR_ASSIGN_AND_APPROVE_ANESTHESIA",
	GET_APPROVED_ANESTHESIA: "GET_APPROVED_ANESTHESIA",
	GET_LINKED_ITEMS_FOR_ANESTHESIA: "GET_LINKED_ITEMS_FOR_ANESTHESIA",
	GET_LINKED_ITEMS_FOR_ANESTHESIA_COMPLETED: "GET_LINKED_ITEMS_FOR_ANESTHESIA_COMPLETED",
	GET_APPROVED_ANESTHESIA_COMPLETED: "GET_APPROVED_ANESTHESIA_COMPLETED",
	SAVE_AND_APPROVE_ELEMENT: "SAVE_AND_APPROVE_ELEMENT",
	CLEAR_SAVE_AND_APPROVE_ELEMENT: "CLEAR_SAVE_AND_APPROVE_ELEMENT",
	ASSIGN_AND_APPROVE_ELEMENT: "ASSIGN_AND_APPROVE_ELEMENT",
	CLEAR_ASSIGN_AND_APPROVE_ELEMENT: "CLEAR_ASSIGN_AND_APPROVE_ELEMENT",
	SUBTREATMENT_GET_ONE: "SUBTREATMENT_GET_ONE",
	CLEAR_SUBTREATMENT_GET_ONE: "CLEAR_SUBTREATMENT_GET_ONE",
	GET_ALL_EXISTING_ANESTHESIA: "GET_ALL_EXISTING_ANESTHESIA",
	GET_ALL_NEW_ANESTHESIA: "GET_ALL_NEW_ANESTHESIA",
	CLEAR_GET_ALL_EXISTING_ANESTHESIA: "CLEAR_GET_ALL_EXISTING_ANESTHESIA",
	CLEAR_GET_ALL_NEW_ANESTHESIA: "CLEAR_GET_ALL_NEW_ANESTHESIA",
	SAVE_AND_APPROVE: "SAVE_AND_APPROVE",
	CLEAR_SAVE_AND_APPROVE: "CLEAR_SAVE_AND_APPROVE",
	ASSIGN_AND_APPROVE: "ASSIGN_AND_APPROVE",
	CLEAR_ASSIGN_AND_APPROVE: "CLEAR_ASSIGN_AND_APPROVE",




	//INTERVENTIONDETAILS

	PREPARATION_GET: "PREPARATION_GET",
	CLEAR_INTERVENTIONDETAILS_GET: "CLEAR_INTERVENTIONDETAILS_GET",
	POSITION_GET: "POSITION_GET",
	INSTALLATION_GET: "INSTALLATION_GET",
	STAFF_GET: "STAFF_GET",
	CLEAR_STAFF_GET: "CLEAR_STAFF_GET",
	MEDICAL_DEVICES_GET: "MEDICAL_DEVICES_GET",
	PHARMACY_GET: "PHARMACY_GET",
	INSTALLATION_GET_ALL_IMAGES: "INSTALLATION_GET_ALL_IMAGES",
	POSITION_GET_ALL_IMAGES: "POSITION_GET_ALL_IMAGES",
	CONSUMABLES_GET: "CONSUMABLES_GET",
	DEFINITION_GET_ALL_IMAGES: "DEFINITION_GET_ALL_IMAGES",
	CLEAR_DEFINITION_GET_ALL_IMAGES: "CLEAR_DEFINITION_GET_ALL_IMAGES",
	DEFINITION_GET: "DEFINITION_GET",
	CLEAR_DEFINITION_GET: "CLEAR_DEFINITION_GET",
	DESCRIPTION_GET: "DESCRIPTION_GET",
	CLEAR_DESCRIPTION_GET: "CLEAR_DESCRIPTION_GET",
	DESCRIPTION_GET_ALL_IMAGES: "DESCRIPTION_GET_ALL_IMAGES",
	CLEAR_DESCRIPTION_GET_ALL_IMAGES: "CLEAR_DESCRIPTION_GET_ALL_IMAGES",
	REMARKS_GET: "REMARKS_GET",
	CLEAR_REMARKS_GET: "CLEAR_REMARKS_GET",
	REMARKS_GET_ALL_IMAGES: "REMARKS_GET_ALL_IMAGES",
	CLEAR_REMARKS_GET_ALL_IMAGES: "CLEAR_REMARKS_GET_ALL_IMAGES",
	FETCH_ALL_EXTENDED_ELEMENTS: "FETCH_ALL_EXTENDED_ELEMENTS",
	FETCH_ALL_ELEMENTS: "FETCH_ALL_ELEMENTS",

	// CLINICAL_PATHWAT_DETAILS

	FETCH_ALL_EXTENDED_CLINICAL_PATHWAY: "FETCH_ALL_EXTENDED_CLINICAL_PATHWAY",
	FETCH_ALL_EXTENDED_CLINICAL_PATHWAY_COMPLETED: "FETCH_ALL_EXTENDED_CLINICAL_PATHWAY_COMPLETED",
	FETCH_SIDE_BAR: "FETCH_SIDE_BAR",
	CLEAR_FETCH_SIDE_BAR: "CLEAR_FETCH_SIDE_BAR",
	CLINICAL_PATHWAY_DETAILS_UPDATE: "CLINICAL_PATHWAY_DETAILS_UPDATE",
	CLEAR_CLINICAL_PATHWAY_DETAILS_UPDATE: "CLEAR_CLINICAL_PATHWAY_DETAILS_UPDATE",
	FETCH_DEFAULT_STEP: "FETCH_DEFAULT_STEP",
	CLEAR_FETCH_DEFAULT_STEP: "CLEAR_FETCH_DEFAULT_STEP",
	FETCH_REFERENCE_ATTACHMENT: "FETCH_REFERENCE_ATTACHMENT",
	FETCH_REFERENCE_ATTACHMENT_COMPLETED: "FETCH_REFERENCE_ATTACHMENT_COMPLETED",
	FETCH_BACK_GROUND_COLOR: "FETCH_BACK_GROUND_COLOR",
	FETCH_BACK_GROUND_COLOR_COMPLETED: "FETCH_BACK_GROUND_COLOR_COMPLETED",
	CREATE_ITEM: "CREATE_ITEM",
	CLEAR_CREATE_ITEM: "CLEAR_CREATE_ITEM",
	UPDATE_ITEM: "UPDATE_ITEM",
	CLEAR_UPDATE_ITEM: "CLEAR_UPDATE_ITEM",
	DUPLICATE_ITEM_MAPPING_ONLY: "DUPLICATE_ITEM_MAPPING_ONLY",
	CLEAR_DUPLICATE_ITEM_MAPPING_ONLY: "CLEAR_DUPLICATE_ITEM_MAPPING_ONLY",
	DUPLICATE_MAPPING: "DUPLICATE_MAPPING",
	CLEAR_DUPLICATE_MAPPING: "CLEAR_DUPLICATE_MAPPING",
	EXT_CLINICAL_PATHWAY_QUICK_EDIT: "EXT_CLINICAL_PATHWAY_QUICK_EDIT",
	CLEAR_EXT_CLINICAL_PATHWAY_QUICK_EDIT: "CLEAR_EXT_CLINICAL_PATHWAY_QUICK_EDIT",
	EXT_CLP_ITEM_ONLY_QUICK_EDIT: "EXT_CLP_ITEM_ONLY_QUICK_EDIT",
	CLEAR_EXT_CLP_ITEM_ONLY_QUICK_EDIT: "CLEAR_EXT_CLP_ITEM_ONLY_QUICK_EDIT",
	EXT_CLP_RANK_UPDATE: "EXT_CLP_RANK_UPDATE",
	EXT_CLP_RANK_UPDATE_COMPLETED: "EXT_CLP_RANK_UPDATE_COMPLETED",
	GET_STEP_ITEM_COUNT: "GET_STEP_ITEM_COUNT",
	GET_STEP_ITEM_COUNT_COMPLETED: "GET_STEP_ITEM_COUNT_COMPLETED",
	BULK_DELETE_OF_CLP_ITEMS: "BULK_DELETE_OF_CLP_ITEMS",
	BULK_DELETE_OF_CLP_ITEMS_COMPLETED: "BULK_DELETE_OF_CLP_ITEMS_COMPLETED",

	//ELEMENTCLASS
	CREATE_ELEMENT_CLASS: "CREATE_ELEMENT_CLASS",
	CLEAR_CREATE_ELEMENT_CLASS: "CLEAR_CREATE_ELEMENT_CLASS",
	ELEMENT_CLASS_GET_ALL: "ELEMENT_CLASS_GET_ALL",
	CLEAR_ELEMENT_CLASS_FETCH: "CLEAR_ELEMENT_CLASS_FETCH",
	DELETE_ELEMENT_CLASS: "DELETE_ELEMENT_CLASS",
	CLEAR_DELETE_ELEMENT_CLASS: "CLEAR_DELETE_ELEMENT_CLASS",
	GET_INTERVENTION_LIST_FOR_ELEMENT: "GET_INTERVENTION_LIST_FOR_ELEMENT",
	CLEAR_GET_INTERVENTION_LIST_FOR_ELEMENT: "CLEAR_GET_INTERVENTION_LIST_FOR_ELEMENT",
	FILTER_CHANGE_ELEMENT_CLASS: "FILTER_CHANGE_ELEMENT_CLASS",
	ASSIGN_ELEMENT_CLASS_LIST: "ASSIGN_ELEMENT_CLASS_LIST",
	ASSIGN_ELEMENT_CLASS_LIST_COMPLETED: "ASSIGN_ELEMENT_CLASS_LIST_COMPLETED",
	ASSIGN_ELEMENT_CLASS: "ASSIGN_ELEMENT_CLASS",
	ASSIGN_ELEMENT_CLASS_COMPLETED: "ASSIGN_ELEMENT_CLASS_COMPLETED",
	CONSUMABLE_CLASS_GET_ALL: "CONSUMABLE_CLASS_GET_ALL",
	CLEAR_CONSUMABLE_CLASS_FETCH: "CLEAR_CONSUMABLE_CLASS_FETCH",
	//BENCHMARK
	INTERVENTION_BENCHMARK_GET_ALL: "INTERVENTION_BENCHMARK_GET_ALL",
	CLEAR_INTERVENTION_BENCHMARK_GET_ALL: "CLEAR_INTERVENTION_BENCHMARK_GET_ALL",
	INTERVENTION_BENCHMARK_GET_ONE: "INTERVENTION_BENCHMARK_GET_ONE",
	CLEAR_INTERVENTION_BENCHMARK_GET_ONE: "CLEAR_INTERVENTION_BENCHMARK_GET_ONE",

	//BREADCRUMB
	BREADCRUMB_DATA: "BREADCRUMB_DATA",
	BREADCRUMB_ITEMS: "BREADCRUMB_ITEMS",
	BADGE_SCREEN_ID: "BADGE_SCREEN_ID",

	//SESSION TYPE
	FETCH_ALL_SESSION_TYPE: "FETCH_ALL_SESSION_TYPE",
	CLEAR_SESSION_TYPE_FETCH: "CLEAR_SESSION_TYPE_FETCH",
	FETCH_ONE_SESSION_TYPE: "FETCH_ONE_SESSION_TYPE",
	CLEAR_FETCH_ONE_SESSION_TYPE: "CLEAR_FETCH_ONE_SESSION_TYPE",
	UPDATE_SESSION_TYPE: "UPDATE_SESSION_TYPE",
	UPDATE_CLEAR_SESSION_TYPE: "UPDATE_CLEAR_SESSION_TYPE",
	CREATE_SESSION_TYPE: "CREATE_SESSION_TYPE",
	CREATE_CLEAR_SESSION_TYPE: "CREATE_CLEAR_SESSION_TYPE",
	DELETE_SESSION_TYPE: "DELETE_SESSION_TYPE",
	CLEAR_SESSION_TYPE_DELETE: "CLEAR_SESSION_TYPE_DELETE",
	FETCH_UNASSIGNED_SESSION_TYPE: "FETCH_UNASSIGNED_SESSION_TYPE",
	CLEAR_FETCH_UNASSIGNED_SESSION_TYPE: "CLEAR_FETCH_UNASSIGNED_SESSION_TYPE",
	ASSING_SESSION_TYPE: "ASSING_SESSION_TYPE",
	ASSING_SESSION_TYPE_COMPLETED: "ASSING_SESSION_TYPE_COMPLETED",
	UNASSING_SESSION_TYPE: "UNASSING_SESSION_TYPE",
	UNASSING_SESSION_TYPE_COMPLETED: "UNASSING_SESSION_TYPE_COMPLETED",
	//SESSION TYPE TEAMS
	FETCH_ALL_SESSION_TYPE_TEAMS: "FETCH_ALL_SESSION_TYPE_TEAMS",
	CLEAR_SESSION_TYPE_TEAMS_FETCH: "CLEAR_SESSION_TYPE_TEAMS_FETCH",
	FETCH_ONE_SESSION_TYPE_TEAMS: "FETCH_ONE_SESSION_TYPE_TEAMS",
	CLEAR_FETCH_ONE_SESSION_TYPE_TEAMS: "CLEAR_FETCH_ONE_SESSION_TYPE_TEAMS",
	UPDATE_SESSION_TYPE_TEAMS: "UPDATE_SESSION_TYPE_TEAMS",
	UPDATE_CLEAR_SESSION_TYPE_TEAMS: "UPDATE_CLEAR_SESSION_TYPE_TEAMS",
	CREATE_SESSION_TYPE_TEAMS: "CREATE_SESSION_TYPE_TEAMS",
	CREATE_CLEAR_SESSION_TYPE_TEAMS: "CREATE_CLEAR_SESSION_TYPE_TEAMS",
	DELETE_SESSION_TYPE_TEAMS: "DELETE_SESSION_TYPE_TEAMS",
	CLEAR_SESSION_TYPE_TEAMS_DELETE: "CLEAR_SESSION_TYPE_TEAMS_DELETE",
	FETCH_ALL_TEMPLATE: "FETCH_ALL_TEMPLATE",
	CLEAR_FETCH_ALL_TEMPLATE: "CLEAR_FETCH_ALL_TEMPLATE",
	UPDATE_TYPE_SESSION_TEAMS_RANK: "UPDATE_TYPE_SESSION_TEAMS_RANK",
	CLEAR_UPDATE_TYPE_SESSION_TEAMS_RANK: "CLEAR_UPDATE_TYPE_SESSION_TEAMS_RANK",

	//DOCTOR
	FETCH_ALL_DOCTOR: "FETCH_ALL_DOCTOR",
	CLEAR_FETCH_ALL_DOCTOR: "CLEAR_FETCH_ALL_DOCTOR",
	GET_ALL_DOCTOR: "GET_ALL_DOCTOR",
	CLEAR_GET_ALL_DOCTOR: "CLEAR_GET_ALL_DOCTOR",
	FETCH_ONE_DOCTOR: "FETCH_ONE_DOCTOR",
	CLEAR_FETCH_ONE_DOCTOR: "CLEAR_FETCH_ONE_DOCTOR",
	CREATE_DOCTOR: "CREATE_DOCTOR",
	CLEAR_CREATE_DOCTOR: "CLEAR_CREATE_DOCTOR",
	UPDATE_DOCTOR: "UPDATE_DOCTOR",
	CLEAR_UPDATE_DOCTOR: "CLEAR_UPDATE_DOCTOR",
	DELETE_DOCTOR: "DELETE_DOCTOR",
	CLEAR_DELETE_DOCTOR: "CLEAR_DELETE_DOCTOR",

	//EQUIPMENT
	FETCH_ALL_EQUIPMENT: "FETCH_ALL_EQUIPMENT",
	CLEAR_FETCH_ALL_EQUIPMENT: "CLEAR_FETCH_ALL_EQUIPMENT",
	FETCH_ALL_EQUIPMENT_FOR_TREATMENTS: "FETCH_ALL_EQUIPMENT_FOR_TREATMENTS",
	CLEAR_FETCH_ALL_EQUIPMENT_FOR_TREATMENTS: "CLEAR_FETCH_ALL_EQUIPMENT_FOR_TREATMENTS",
	FETCH_ONE_EQUIPMENT: "FETCH_ONE_EQUIPMENT",
	CLEAR_FETCH_ONE_EQUIPMENT: "CLEAR_FETCH_ONE_EQUIPMENT",
	CREATE_EQUIPMENT: "CREATE_EQUIPMENT",
	CLEAR_CREATE_EQUIPMENT: "CLEAR_CREATE_EQUIPMENT",
	UPDATE_EQUIPMENT: "UPDATE_EQUIPMENT",
	CLEAR_UPDATE_EQUIPMENT: "CLEAR_UPDATE_EQUIPMENT",
	DELETE_EQUIPMENT: "DELETE_EQUIPMENT",
	CLEAR_DELETE_EQUIPMENT: "CLEAR_DELETE_EQUIPMENT",

	// EQUIPMENT_COST 
	FETCH_ALL_EQUIPMENT_COST: "FETCH_ALL_EQUIPMENT_COST",
	CLEAR_FETCH_ALL_EQUIPMENT_COST: "CLEAR_FETCH_ALL_EQUIPMENT_COST",
	FETCH_ONE_EQUIPMENT_COST: "FETCH_ONE_EQUIPMENT_COST",
	CLEAR_FETCH_ONE_EQUIPMENT_COST: "CLEAR_FETCH_ONE_EQUIPMENT_COST",
	CREATE_EQUIPMENT_COST: "CREATE_EQUIPMENT_COST",
	CLEAR_CREATE_EQUIPMENT_COST: "CLEAR_CREATE_EQUIPMENT_COST",
	UPDATE_EQUIPMENT_COST: "UPDATE_EQUIPMENT_COST",
	CLEAR_UPDATE_EQUIPMENT_COST: "CLEAR_UPDATE_EQUIPMENT_COST",
	DELETE_EQUIPMENT_COST: "DELETE_EQUIPMENT_COST",
	CLEAR_DELETE_EQUIPMENT_COST: "CLEAR_DELETE_EQUIPMENT_COST",


	//PARAMETERS
	FETCH_ALL_PARAMETERS: "FETCH_ALL_PARAMETERS",
	CLEAR_PARAMETERS_FETCH: "CLEAR_PARAMETERS_FETCH",
	UPDATE_PARAMETERS: "UPDATE_PARAMETERS",
	UPDATE_CLEAR_PARAMETERS: "UPDATE_CLEAR_PARAMETERS",

	//EXT-ELEMENTS
	FETCH_ALL_EXT_ELEMENT_DATA: "FETCH_ALL_EXT_ELEMENT_DATA",
	CLEAR_FETCH_ALL_EXT_ELEMENT_DATA: "CLEAR_FETCH_ALL_EXT_ELEMENT_DATA",
	DELETE_EXT_ELEMENT: "DELETE_EXT_ELEMENT",
	CLEAR_DELETE_EXT_ELEMENT: "CLEAR_DELETE_EXT_ELEMENT",
	UPDATE_EXT_ELEMENT: "UPDATE_EXT_ELEMENT",
	CLEAR_UPDATE_EXT_ELEMENT: "CLEAR_UPDATE_EXT_ELEMENT",
	FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE: "FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE",
	CLEAR_FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE: "CLEAR_FETCH_ALL_EXT_ELEMENT_DATA_FOR_TYPE",
	CREATE_EXT_ELEMENT: "CREATE_EXT_ELEMENT",
	CLEAR_CREATE_EXT_ELEMENT: "CLEAR_CREATE_EXT_ELEMENT",
	RANK_UPDATE: "RANK_UPDATE",
	CLEAR_RANK_UPDATE: "CLEAR_RANK_UPDATE",
	FETCH_ONE_EXT_ELEMENT: "FETCH_ONE_EXT_ELEMENT",
	CLEAR_FETCH_ONE_EXT_ELEMENT: "CLEAR_FETCH_ONE_EXT_ELEMENT",
	TRIGGER_LIST_UPDATE: "TRIGGER_LIST_UPDATE",
	CLEAR_TRIGGER_LIST_UPDATE: "CLEAR_TRIGGER_LIST_UPDATE",
	FETCH_ALL_DOCTORS_OF_EXT_ELEMENT: "FETCH_ALL_DOCTORS_OF_EXT_ELEMENT",
	CLEAR_FETCH_ALL_DOCTORS_OF_EXT_ELEMENT: "CLEAR_FETCH_ALL_DOCTORS_OF_EXT_ELEMENT",
	UPDATE_DOCTORS_OF_EXT_ELEMENT: "UPDATE_DOCTORS_OF_EXT_ELEMENT",
	CLEAR_UPDATE_DOCTORS_OF_EXT_ELEMENT: "CLEAR_UPDATE_DOCTORS_OF_EXT_ELEMENT",

	//EXT-CLINICAL-PATHWAY
	FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA: "FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA",
	CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA: "CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA",
	DELETE_EXT_CLINICAL_PATHWAY: "DELETE_EXT_CLINICAL_PATHWAY",
	CLEAR_DELETE_EXT_CLINICAL_PATHWAY: "CLEAR_DELETE_EXT_CLINICAL_PATHWAY",
	UPDATE_EXT_CLINICAL_PATHWAY: "UPDATE_EXT_CLINICAL_PATHWAY",
	CLEAR_UPDATE_EXT_CLINICAL_PATHWAY: "CLEAR_UPDATE_EXT_CLINICAL_PATHWAY",
	FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE: "FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE",
	CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE: "CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_DATA_FOR_TYPE",
	CREATE_EXT_CLINICAL_PATHWAY: "CREATE_EXT_CLINICAL_PATHWAY",
	CLEAR_CREATE_EXT_CLINICAL_PATHWAY: "CLEAR_CREATE_EXT_CLINICAL_PATHWAY",
	FETCH_ONE_EXT_CLINICAL_PATHWAY: "FETCH_ONE_EXT_CLINICAL_PATHWAY",
	CLEAR_FETCH_ONE_EXT_CLINICAL_PATHWAY: "CLEAR_FETCH_ONE_EXT_CLINICAL_PATHWAY",

	//ASSIGN STEPS
	FETCH_ALL_ELEMENT_MAPPING: "FETCH_ALL_ELEMENT_MAPPING",
	CLEAR_FETCH_ALL_ELEMENT_MAPPING: "CLEAR_FETCH_ALL_ELEMENT_MAPPING",
	SUBMIT_ELEMENT_MAPPING: "SUBMIT_ELEMENT_MAPPING",
	CLEAR_SUBMIT_ELEMENT_MAPPING: "CLEAR_SUBMIT_ELEMENT_MAPPING",

	//SETTINGS
	GET_ALL_SETTINGS: "GET_ALL_SETTINGS",
	CLEAR_GET_ALL_SETTINGS: "CLEAR_GET_ALL_SETTINGS",
	UPDATE_SETTINGS: "UPDATE_SETTINGS",
	CLEAR_UPDATE_SETTINGS: "CLEAR_UPDATE_SETTINGS",

	//SCOPE
	FETCH_ALL_SCOPE: "FETCH_ALL_SCOPE",
	CLEAR_FETCH_ALL_SCOPE: "CLEAR_FETCH_ALL_SCOPE",
	UPDATE_SCOPE: "UPDATE_SCOPE",
	USER_SCOPE_FETCH: "USER_SCOPE_FETCH",
	CLEAR_UPDATE_SCOPE: "CLEAR_UPDATE_SCOPE",
	DELETE_SCOPE: "DELETE_SCOPE",
	CLEAR_DELETE_SCOPE: "CLEAR_DELETE_SCOPE",

	//SUPPLIER
	FETCH_ALL_SUPPLIER: "FETCH_ALL_SUPPLIER",
	CLEAR_SUPPLIER_FETCH: "CLEAR_SUPPLIER_FETCH",
	FETCH_MASTER_SUPPLIER: "FETCH_MASTER_SUPPLIER",
	CLEAR_MASTER_SUPPLIER_FETCH: "CLEAR_MASTER_SUPPLIER_FETCH",
	FETCH_ONE_SUPPLIER: "FETCH_ONE_SUPPLIER",
	SET_SUPPLIER_NAME: "SET_SUPPLIER_NAME",
	CLEAR_FETCH_ONE_SUPPLIER: "CLEAR_FETCH_ONE_SUPPLIER",
	UPDATE_SUPPLIER: "UPDATE_SUPPLIER",
	UPDATE_CLEAR_SUPPLIER: "UPDATE_CLEAR_SUPPLIER",
	CREATE_SUPPLIER: "CREATE_SUPPLIER",
	CREATE_CLEAR_SUPPLIER: "CREATE_CLEAR_SUPPLIER",
	DELETE_SUPPLIER: "DELETE_SUPPLIER",
	CLEAR_SUPPLIER_DELETE: "CLEAR_SUPPLIER_DELETE",

	//COSTCONSTANT
	FETCH_ALL_CONSTANTS: "FETCH_ALL_CONSTANTS",
	CLEAR_FETCH_ALL_CONSTANTS: "CLEAR_FETCH_ALL_CONSTANTS",
	UPDATE_CONSTANT: "UPDATE_CONSTANT",
	CLEAR_UPDATE_CONSTANT: "CLEAR_UPDATE_CONSTANT",
	CREATE_STAY_CONSTANT: "CREATE_STAY_CONSTANT",
	CLEAR_CREATE_STAY_CONSTANT: "CLEAR_CREATE_STAY_CONSTANT",

	//ASSIGN TO TREATMENTS
	FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY: "FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY",
	CLEAR_FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY: "CLEAR_FETCH_ALL_TREATMENTS_FOR_TYPEACTIVITY",
	ASSIGN_TO_TREATMENTS: "ASSIGN_TO_TREATMENTS",
	CLEAR_ASSIGN_TO_TREATMENTS: "CLEAR_ASSIGN_TO_TREATMENTS",

	//WARNING
	FETCH_ALL_WARNING: "FETCH_ALL_WARNING",
	CLEAR_FETCH_ALL_WARNING: "CLEAR_FETCH_ALL_WARNING",
	GET_QUICKLANCH: "GET_QUICKLANCH",
	CLEAR_QUICKLANCH: "CLEAR_QUICKLANCH",
	//TYPE PARCOURSE
	CREATE_EPISODE_TYPE: "CREATE_EPISODE_TYPE",
	CREATE_EPISODE_TYPE_COMPLETED: "CREATE_EPISODE_TYPE_COMPLETED",

	EDIT_EPISODE_TYPE: "EDIT_EPISODE_TYPE",
	EDIT_EPISODE_TYPE_COMPLETED: "EDIT_EPISODE_TYPE_COMPLETED",

	DELETE_EPISODE_TYPE: "DELETE_EPISODE_TYPE",
	DELETE_EPISODE_TYPE_COMPLETED: "DELETE_EPISODE_TYPE_COMPLETED",

	GET_EPISODE_TYPE: "GET_EPISODE_TYPE",
	GET_EPISODE_TYPE_COMPLETED: "GET_EPISODE_TYPE_COMPLETED",
	GET_EPISODE_TYPE_ONE: "GET_EPISODE_TYPE_ONE",
	GET_EPISODE_TYPE_ONE_COMPLETED: "GET_EPISODE_TYPE_ONE_COMPLETED",
	GET_COURSETEMPLATE: "GET_COURSETEMPLATE",
	GET_COURSETEMPLATE_COMPLETED: "GET_COURSETEMPLATE_COMPLETED",
	CREATE_COURSETEMPLATE: "CREATE_COURSETEMPLATE",
	CREATE_COURSETEMPLATE_COMPLETED: "CREATE_COURSETEMPLATE_COMPLETED",
	GET_COURSETEMPLATE_ONE: "GET_COURSETEMPLATE_ONE",
	GET_COURSETEMPLATE_ONE_COMPLETED: "GET_COURSETEMPLATE_ONE_COMPLETED",
	EDIT_COURSETEMPLATE: "EDIT_COURSETEMPLATE",
	EDIT_COURSETEMPLATE_COMPLETED: "EDIT_COURSETEMPLATE_COMPLETED",
	DELETE_COURSETEMPLATE: "DELETE_COURSETEMPLATE",
	DELETE_COURSETEMPLATE_COMPLETED: "DELETE_COURSETEMPLATE_COMPLETED",

	//courseTemplateMapping
	GET_COURSE_TEMPLATE_MAPPING: "GET_COURSE_TEMPLATE_MAPPING",
	GET_COURSE_TEMPLATE_MAPPING_COMPLETED: "GET_COURSE_TEMPLATE_MAPPING_COMPLETED",

	CREATE_COURSE_TEMPLATE_MAPPING: "CREATE_COURSE_TEMPLATE_MAPPING",
	CREATE_COURSE_TEMPLATE_MAPPING_COMPLETED: "CREATE_COURSE_TEMPLATE_MAPPING_COMPLETED",

	GET_COURSE_TEMPLATE_MAPPING_ONE: "GET_COURSE_TEMPLATE_MAPPING_ONE",
	GET_COURSE_TEMPLATE_MAPPING_ONE_COMPLETED: "GET_COURSE_TEMPLATE_MAPPING_ONE_COMPLETED",
	EDIT_COURSE_TEMPLATE_MAPPING: "EDIT_COURSE_TEMPLATE_MAPPING",
	EDIT_COURSE_TEMPLATE_MAPPING_COMPLETED: "EDIT_COURSE_TEMPLATE_MAPPING_COMPLETED",
	DELETE_COURSE_TEMPLATE_MAPPING: "DELETE_COURSE_TEMPLATE_MAPPING",
	DELETE_COURSE_TEMPLATE_MAPPING_COMPLETED: "DELETE_COURSE_TEMPLATE_MAPPING_COMPLETED",

	SET_COURSE_TEMPLATE_TO_COURSE_STEP: "SET_COURSE_TEMPLATE_TO_COURSE_STEP",
	SET_COURSE_TEMPLATE_TO_COURSE_STEP_COMPLETED: "SET_COURSE_TEMPLATE_TO_COURSE_STEP_COMPLETED",
	GET_COURSE_TEMPLATE_TO_COURSE_STEP: "GET_COURSE_TEMPLATE_TO_COURSE_STEP",
	GET_COURSE_TEMPLATE_TO_COURSE_STEP_COMPLETED: "GET_COURSE_TEMPLATE_TO_COURSE_STEP_COMPLETED",

	DELETE_TEMPLATE_ONLY: "DELETE_TEMPLATE_ONLY",
	DELETE_TEMPLATE_ONLY_COMPLETED: "DELETE_TEMPLATE_ONLY_COMPLETED",
	DELETE_TEMPLATE_AND_DATA: "DELETE_TEMPLATE_AND_DATA",
	DELETE_TEMPLATE_AND_DATA_COMPLETED: "DELETE_TEMPLATE_AND_DATA_COMPLETED",

	UPDATE_COURSE_RANK: "UPDATE_COURSE_RANK",
	UPDATE_COURSE_RANK_COMPLETED: "UPDATE_COURSE_RANK_COMPLETED",

	GET_APP_SCANNER: "GET_APP_SCANNER",
	GET_APP_SCANNER_COMPLETED: "GET_APP_SCANNER_COMPLETED",

	GET_NAVIGATION_HELP: "GET_NAVIGATION_HELP",
	CLEAR_NAVIGATION_HELP: "CLEAR_NAVIGATION_HELP",
	CREATE_NAVIGATION_HELP: "CREATE_NAVIGATION_HELP",
	CLEAR_CREATE_NAVIGATION_HELP: "CLEAR_CREATE_NAVIGATION_HELP",
	GET_ONE_NAVIGATION_HELP: "GET_ONE_NAVIGATION_HELP",
	CLEAR_GET_ONE_NAVIGATION_HELP: "CLEAR_GET_ONE_NAVIGATION_HELP",

	UPDATE_NAVIGATION_HELP: "UPDATE_NAVIGATION_HELP",
	CLEAR_UPDATE_NAVIGATION_HELP: "CLEAR_UPDATE_NAVIGATION_HELP",
	DELETE_NAVIGATION_HELP: "DELETE_NAVIGATION_HELP",
	CLEAR_DELETE_NAVIGATION_HELP: "CLEAR_DELETE_NAVIGATION_HELP",

	SYNC_DATA: "SYNC_DATA",
	SYNC_DATA_COMPLETED: "SYNC_DATA_COMPLETED",
	GET_LAST_SYNC_DATE: "GET_LAST_SYNC_DATE",
	GET_LAST_SYNC_DATE_COMPLETED: "GET_LAST_SYNC_DATE_COMPLETED",


	//Sterilization
	GET_CONSUMABLE_SPECIALITY: "GET_CONSUMABLE_SPECIALITY",
	CLEAR_CONSUMABLE_SPECIALITY: "CLEAR_CONSUMABLE_SPECIALITY",
	CREATE_CONSUMABLE_SPECIALITY: "CREATE_CONSUMABLE_SPECIALITY",
	CREATE_CONSUMABLE_SPECIALITY_COMPLETED: "CREATE_CONSUMABLE_SPECIALITY_COMPLETED",

	GET_ONE_CONSOMABLE_SPECIALITY: "GET_ONE_CONSOMABLE",
	GET_ONE_CONSOMABLE_SPECIALITY_COMPLETED: "GET_ONE_CONSOMABLE_COMPLETED",
	UPDATE_CONSOMABLE_SPECIALTY: "UPDATE_CONSOMABLE_SPECIALTY",
	CLEAR_UPDATE_CONSOMABLE_SPECIALTY: "CLEAR_UPDATE_CONSOMABLE_SPECIALTY",

	DELETE_CONSOMABLE_SPECIALTY: "DELETE_CONSOMABLE_SPECIALTY",
	DELETE_CONSOMABLE_SPECIALTY_COMPLETED: "DELETE_CONSOMABLE_SPECIALTY_COMPLETED",

	FETCH_ONE_CONSOMABLE_SPECIALITY: "FETCH_ONE_CONSOMABLE_SPECIALITY",
	FETCH_ONE_CONSOMABLE_SPECIALITY_COMPLETED: "FETCH_ONE_CONSOMABLE_SPECIALITY_COMPLETED",

	GET_ALL_STERILIZATION_SPECIALITY: "GET_ALL_STERILIZATION_SPECIALITY",
	GET_ALL_STERILIZATION_SPECIALITY_COMPLETED: "GET_ALL_STERILIZATION_SPECIALITY_COMPLETED",

	SAVE_AND_APPROVE_STERILIZATION_SPECIALITY: "SAVE_AND_APPROVE_STERILIZATION_SPECIALITY",
	SAVE_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED: "SAVE_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED",

	ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY: "ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY",
	ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED: "ASSIGN_AND_APPROVE_STERILIZATION_SPECIALITY_COMPLETED",

	SAVE_AND_APPROVE_STERILIZATION_CLASS: "SAVE_AND_APPROVE_STERILIZATION_CLASS",
	SAVE_AND_APPROVE_STERILIZATION_CLASS_COMPLETED: "SAVE_AND_APPROVE_STERILIZATION_CLASS_COMPLETED",
	ASSIGN_AND_APPROVE_STERILIZATION_CLASS: "ASSIGN_AND_APPROVE_STERILIZATION_CLASS",
	ASSIGN_AND_APPROVE_STERILIZATION_CLASS_COMPLETED: "ASSIGN_AND_APPROVE_STERILIZATION_CLASS_COMPLETED",
	GET_ONE_CLASS_STERILIZATION: "GET_ONE_CLASS_STERILIZATION",
	CLEAR_GET_ONE_CLASS_STERILIZATION: "CLEAR_GET_ONE_CLASS_STERILIZATION",

	//CLINICAL_PATHWAY

	CLINICAL_PATHWAY_GET_ALL: "CLINICAL_PATHWAY_GET_ALL",
	CLEAR_CLINICAL_PATHWAY_GET_ALL: "CLEAR_CLINICAL_PATHWAY_GET_ALL",
	CLINICAL_PATHWAY_GET_ONE: "CLINICAL_PATHWAY_GET_ONE",
	CLEAR_CLINICAL_PATHWAY_GET_ONE: "CLEAR_CLINICAL_PATHWAY_GET_ONE",
	CLINICAL_PATHWAY_UPDATE: "CLINICAL_PATHWAY_UPDATE",
	CLINICAL_PATHWAY_CREATE: "CLINICAL_PATHWAY_CREATE",
	CLEAR_CLINICAL_PATHWAY_CREATE: "CLEAR_CLINICAL_PATHWAY_CREATE",
	CLEAR_CLINICAL_PATHWAY_UPDATE: "CLEAR_CLINICAL_PATHWAY_UPDATE",
	CLINICAL_PATHWAY_SUB_TREATMENT_GET: "CLINICAL_PATHWAY_SUB_TREATMENT_GET",
	CLEAR_CLINICAL_PATHWAY_SUB_TREATMENT_GET: "CLEAR_CLINICAL_PATHWAY_SUB_TREATMENT_GET",
	GET_INTERVENTION: "GET_INTERVENTION",
	CLEAR_GET_INTERVENTION: "CLEAR_GET_INTERVENTION",
	GET_ONE_EXT_CLP: "GET_ONE_EXT_CLP",
	CLEAR_GET_ONE_EXT_CLP: "CLEAR_GET_ONE_EXT_CLP",
	UPDATE_SUB_STEP_NAME: "UPDATE_SUB_STEP_NAME",
	CLEAR_UPDATE_SUB_STEP_NAME: "CLEAR_UPDATE_SUB_STEP_NAME",
	GET_ONE_SUB_STEP_DETAILS: "GET_ONE_SUB_STEP_DETAILS",
	CLEAR_GET_ONE_SUB_STEP_DETAILS: "CLEAR_GET_ONE_SUB_STEP_DETAILS",
	EXT_CLINICAL_PATHWAY_RANK_UPDATE: "EXT_CLINICAL_PATHWAY_RANK_UPDATE",
	CLEAR_EXT_CLINICAL_PATHWAY_RANK_UPDATE: "CLEAR_EXT_CLINICAL_PATHWAY_RANK_UPDATE",
	FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE: "FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE",
	CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE: "CLEAR_FETCH_ALL_EXT_CLINICAL_PATHWAY_RESPONSIBLE",
	TRIGGER_RESPONSIBLE_LIST_UPDATE: "TRIGGER_RESPONSIBLE_LIST_UPDATE",
	CLEAR_TRIGGER_RESPONSIBLE_LIST_UPDATE: "CLEAR_TRIGGER_RESPONSIBLE_LIST_UPDATE",
	UPLOAD_REFERENCE_FILE: "UPLOAD_REFERENCE_FILE",
	CLEAR_UPLOAD_REFERENCE_FILE: "CLEAR_UPLOAD_REFERENCE_FILE",
	DELETE_REFERENCE_FILE: "DELETE_REFERENCE_FILE",
	CLEAR_DELETE_REFERENCE_FILE: "CLEAR_DELETE_REFERENCE_FILE",
	CREATE_REFERENCE_FILE_DETAILS: "CREATE_REFERENCE_FILE_DETAILS",
	CLEAR_CREATE_REFERENCE_FILE_DETAILS: "CLEAR_CREATE_REFERENCE_FILE_DETAILS",
	SELECTED_COLUMN: "SELECTED_COLUMN",
	MAPPING_DATA: "MAPPING_DATA",
	CLEAR_MAPPING_DATA: "CLEAR_MAPPING_DATA",
	CLEAR_SELECTED_COLUMN: "CLEAR_SELECTED_COLUMN",
	SELECTED_LIST: "SELECTED_LIST",
	CLEAR_SELECTED_LIST: "CLEAR_SELECTED_LIST",
	SAVE_MINIMAL_SUB_STEP: "SAVE_MINIMAL_SUB_STEP",
	CLEAR_SAVE_MINIMAL_SUB_STEP: "CLEAR_SAVE_MINIMAL_SUB_STEP",
	FETCH_COPY_FROM_CLP_LIST: "FETCH_COPY_FROM_CLP_LIST",
	CLEAR_FETCH_COPY_FROM_CLP_LIST: "CLEAR_FETCH_COPY_FROM_CLP_LIST",
	COPY_FROM_CLINICAL_PATHWAY: "COPY_FROM_CLINICAL_PATHWAY",
	CLEAR_COPY_FROM_CLINICAL_PATHWAY: "CLEAR_COPY_FROM_CLINICAL_PATHWAY",
	CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS: "CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS",
	CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS_COMPLETED: "CHECK_COPY_FROM_CLINICAL_PATHWAY_STATUS_COMPLETED",
	CLINICAL_PATHWAY_EXPORT: "CLINICAL_PATHWAY_EXPORT",
	CLINICAL_PATHWAY_EXPORT_COMPLETED: "CLINICAL_PATHWAY_EXPORT_COMPLETED",
	CHECK_CLINICAL_PATHWAY_REPORT_STATUS: "CHECK_CLINICAL_PATHWAY_REPORT_STATUS",
	CHECK_CLINICAL_PATHWAY_REPORT_STATUS_COMPLETED: "CHECK_CLINICAL_PATHWAY_REPORT_STATUS_COMPLETED",
	DELETE_CLINICAL_PATHWAY: "DELETE_CLINICAL_PATHWAY",
	DELETE_CLINICAL_PATHWAY_COMPLETED: "DELETE_CLINICAL_PATHWAY_COMPLETED",
	CREATE_CLINICAL_PATHWAY_SERVICE: "CREATE_CLINICAL_PATHWAY_SERVICE",
	CREATE_CLINICAL_PATHWAY_SERVICE_COMPLETED: "CREATE_CLINICAL_PATHWAY_SERVICE_COMPLETED",
	GET_AUTHORS_AND_ACTIVITIES: "GET_AUTHORS_AND_ACTIVITIES",
	CLEAR_GET_AUTHORS_AND_ACTIVITIES: "CLEAR_GET_AUTHORS_AND_ACTIVITIES",
	DELETE_AUTHOR_AND_ACTIVITY: "DELETE_AUTHOR_AND_ACTIVITY",
	DELETE_AUTHOR_AND_ACTIVITY_COMPLETED: "DELETE_AUTHOR_AND_ACTIVITY_COMPLETED",
	UPDATE_AUTHOR_AND_ACTIVITY: "UPDATE_AUTHOR_AND_ACTIVITY",
	UPDATE_AUTHOR_AND_ACTIVITY_COMPLETED: "UPDATE_AUTHOR_AND_ACTIVITY_COMPLETED",
	GET_ONE_AUTHORS_AND_ACTIVITIES: "GET_ONE_AUTHORS_AND_ACTIVITIES",
	CLEAR_GET_ONE_AUTHORS_AND_ACTIVITIES: "CLEAR_GET_ONE_AUTHORS_AND_ACTIVITIES",
	SIDE_BAR_POSITION: "SIDE_BAR_POSITION",
	CLEAR_SIDE_BAR_POSITION: "CLEAR_SIDE_BAR_POSITION",
	GET_HOSPITAL_FOR_CLP: "GET_HOSPITAL_FOR_CLP",
	GET_HOSPITAL_FOR_CLP_COMPLETED: "GET_HOSPITAL_FOR_CLP_COMPLETED",
	CLP_GET_ONE: "CLP_GET_ONE",
	CLEAR_CLP_GET_ONE: "CLEAR_CLP_GET_ONE",
	COPY_ALL_TO_CLINICAL_PATHWAY_MASTER: "COPY_ALL_TO_CLINICAL_PATHWAY_MASTER",
	CLEAR_COPY_ALL_TO_CLINICAL_PATHWAY_MASTER: "CLEAR_COPY_ALL_TO_CLINICAL_PATHWAY_MASTER",
	GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY: "GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY",
	CLEAR_GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY: "CLEAR_GET_AUTHORS_AND_ACTIVITIES_FOR_SELECTED_COPY",
	FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY: "FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY",
	CLEAR_FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY: "CLEAR_FETCH_ALL_EXTENDED_CLP_FOR_SELECTED_COPY",
	GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY: "GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY",
	CLEAR_GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY: "CLEAR_GET_CLP_SUB_TREATMENT_FOR_SELECTED_COPY",
	COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP: "COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP",
	CLEAR_COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP: "CLEAR_COPY_SELECTED_TO_CLINICAL_PATHWAY_MASTER_CLP",
	GET_CLINICAL_PATHWAY: "GET_CLINICAL_PATHWAY",
	GET_CLINICAL_PATHWAY_COMPLETED: "GET_CLINICAL_PATHWAY_COMPLETED",
	SAVE_AND_APPROVE_CLINICAL_PATHWAY: "SAVE_AND_APPROVE_CLINICAL_PATHWAY",
	SAVE_AND_APPROVE_CLINICAL_PATHWAY_COMPLETED: "SAVE_AND_APPROVE_CLINICAL_PATHWAY_COMPLETED",
	DELETE_CLP: "DELETE_CLP",
	DELETE_CLP_COMPLETED: "DELETE_CLP_COMPLETED",
	FETCH_COPY_TO_SUB_STEP_LIST: "FETCH_COPY_TO_SUB_STEP_LIST",
	CLEAR_FETCH_COPY_TO_SUB_STEP_LIST: "CLEAR_FETCH_COPY_TO_SUB_STEP_LIST",
	SUB_STEP_COPY: "SUB_STEP_COPY",
	SUB_STEP_COPY_COMPLETED: "SUB_STEP_COPY_COMPLETED",
	CHECK_SUB_STEP_COPY_STATUS: "CHECK_SUB_STEP_COPY_STATUS",
	CHECK_SUB_STEP_COPY_STATUS_COMPLETED: "CHECK_SUB_STEP_COPY_STATUS_COMPLETED",
	GET_DAY_INFORMATION: "GET_DAY_INFORMATION",
	GET_DAY_INFORMATION_COMPLETED: "GET_DAY_INFORMATION_COMPLETED",
	FETCH_TOTAL_ITEM_COUNT: "FETCH_TOTAL_ITEM_COUNT",
	CLEAR_FETCH_TOTAL_ITEM_COUNT: "CLEAR_FETCH_TOTAL_ITEM_COUNT",
	COMMUNITY_SHARE: "COMMUNITY_SHARE",
	COMMUNITY_SHARE_COMPLETED: "COMMUNITY_SHARE_COMPLETED",
	CLINICAL_PATHWAY_LIST_EXPORT: "CLINICAL_PATHWAY_LIST_EXPORT",
	CLINICAL_PATHWAY_EXPORT_LIST_COMPLETED: "CLINICAL_PATHWAY_EXPORT_LIST_COMPLETED",
	CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS: "CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS",
	CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS_COMPLETED: "CHECK_CLINICAL_PATHWAY_REPORT_EXPORT_STATUS_COMPLETED",
	ATTACH_SELECTED_MODEL_WITH_CLP: "ATTACH_SELECTED_MODEL_WITH_CLP",
	CLEAR_ATTACH_SELECTED_MODEL_WITH_CLP: "CLEAR_ATTACH_SELECTED_MODEL_WITH_CLP",
	CLINICAL_PATHWAY_GET_ALL_MODEL: "CLINICAL_PATHWAY_GET_ALL_MODEL",
	CLEAR_CLINICAL_PATHWAY_GET_ALL_MODEL: "CLEAR_CLINICAL_PATHWAY_GET_ALL_MODEL",
	APPLY_MODEL_CHANGES_WITH_CLP: "APPLY_MODEL_CHANGES_WITH_CLP",
	CLEAR_APPLY_MODEL_CHANGES_WITH_CLP: "CLEAR_APPLY_MODEL_CHANGES_WITH_CLP",
	MODEL_OBSERVER_CLP: "MODEL_OBSERVER_CLP",
	CLEAR_MODEL_OBSERVER_CLP: "CLEAR_MODEL_OBSERVER_CLP",
	APPLY_MODEL_CHANGES_DETAILS: "APPLY_MODEL_CHANGES_DETAILS",
	CLEAR_APPLY_MODEL_CHANGES_DETAILS: "CLEAR_APPLY_MODEL_CHANGES_DETAILS",
	GET_ALL_CLPS_FOR_COPY: "GET_ALL_CLPS_FOR_COPY",
	CLEAR_GET_ALL_CLPS_FOR_COPY: "CLEAR_GET_ALL_CLPS_FOR_COPY",

	//CLINICAL-PATHWAY_STD_CARE_CHART

	GET_ALL_STD_CARE_CHART: "GET_ALL_STD_CARE_CHART",
	CLEAR_GET_ALL_STD_CARE_CHART: "CLEAR_GET_ALL_STD_CARE_CHART",
	STD_CARE_CHART_UPDATE: "STD_CARE_CHART_UPDATE",
	STD_CARE_CHART_UPDATE_COMPLETED: "STD_CARE_CHART_UPDATE_COMPLETED",
	STD_CARE_CHART_RANK_UPDATE: "STD_CARE_CHART_RANK_UPDATE",
	STD_CARE_CHART_RANK_UPDATE_COMPLETED: "STD_CARE_CHART_RANK_UPDATE_COMPLETED",
	TOGGLE_STD_CARE_CHART_UPDATION_MODAL: "TOGGLE_STD_CARE_CHART_UPDATION_MODAL",
	STD_CARE_CHART_SETTINGS_UPDATE: "STD_CARE_CHART_SETTINGS_UPDATE",
	STD_CARE_CHART_SETTINGS_UPDATE_COMPLETED: "STD_CARE_CHART_SETTINGS_UPDATE_COMPLETED",
	FETCH_STD_CARE_CHART_SETTINGS: "FETCH_STD_CARE_CHART_SETTINGS",
	CLEAR_FETCH_STD_CARE_CHART_SETTINGS: "CLEAR_FETCH_STD_CARE_CHART_SETTINGS",
	CHECKLIST_STD_CARE_CHART_UPDATE: "CHECKLIST_STD_CARE_CHART_UPDATE",
	CHECKLIST_STD_CARE_CHART_UPDATE_COMPLETED: "CHECKLIST_STD_CARE_CHART_UPDATE_COMPLETED",
	STD_CARE_CHART_DURATION_UPDATE: "STD_CARE_CHART_DURATION_UPDATE",
	STD_CARE_CHART_DURATION_UPDATE_COMPLETED: "STD_CARE_CHART_DURATION_UPDATE_COMPLETED",

	//CLINICAL-PATHWAY_PROBLEMS
	GET_ALL_CLP_PROBLEM: "GET_ALL_CLP_PROBLEM",
	CLEAR_GET_ALL_CLP_PROBLEM: "CLEAR_GET_ALL_CLP_PROBLEM",
	GET_ONE_CLP_PROBLEM: "GET_ONE_CLP_PROBLEM",
	CLEAR_GET_ONE_CLP_PROBLEM: "CLEAR_GET_ONE_CLP_PROBLEM",
	CLP_PROBLEM_CREATE: "CLP_PROBLEM_CREATE",
	CLEAR_CLP_PROBLEM_CREATE: "CLEAR_CLP_PROBLEM_CREATE",
	CLP_PROBLEM_UPDATE: "CLP_PROBLEM_UPDATE",
	CLEAR_CLP_PROBLEM_UPDATE: "CLEAR_CLP_PROBLEM_UPDATE",
	DELETE_CLP_PROBLEM: "DELETE_CLP_PROBLEM",
	DELETE_CLP_PROBLEM_COMPLETED: "DELETE_CLP_PROBLEM_COMPLETED",
	GET_ALL_CLP_SYNTHESIS: "GET_ALL_CLP_SYNTHESIS",
	CLEAR_GET_ALL_CLP_SYNTHESIS: "CLEAR_GET_ALL_CLP_SYNTHESIS",
	GET_ALL_MINIMAL_CLP_PROBLEM: "GET_ALL_MINIMAL_CLP_PROBLEM",
	CLEAR_GET_ALL_MINIMAL_CLP_PROBLEM: "CLEAR_GET_ALL_MINIMAL_CLP_PROBLEM",
	ADDITIONAL_INFO_CREATE: "ADDITIONAL_INFO_CREATE",
	CLEAR_ADDITIONAL_INFO_CREATE: "CLEAR_ADDITIONAL_INFO_CREATE",
	GET_ONE_ADDITIONAL_INFO: "GET_ONE_ADDITIONAL_INFO",
	CLEAR_GET_ONE_ADDITIONAL_INFO: "CLEAR_GET_ONE_ADDITIONAL_INFO",
	DELETE_ADDITIONAL_INFO: "DELETE_ADDITIONAL_INFO",
	DELETE_ADDITIONAL_INFO_COMPLETED: "DELETE_ADDITIONAL_INFO_COMPLETED",
	UPDATE_ADDITIONAL_INFO: "UPDATE_ADDITIONAL_INFO",
	CLEAR_UPDATE_ADDITIONAL_INFO: "CLEAR_UPDATE_ADDITIONAL_INFO",
	UPDATE_CLP_PROBLEMS_RANK: "UPDATE_CLP_PROBLEMS_RANK",
	CLEAR_UPDATE_CLP_PROBLEMS_RANK: "CLEAR_UPDATE_CLP_PROBLEMS_RANK",
	GET_ALL_STEPS_AND_SUB_STEPS: "GET_ALL_STEPS_AND_SUB_STEPS",
	CLEAR_GET_ALL_STEPS_AND_SUB_STEPS: "CLEAR_GET_ALL_STEPS_AND_SUB_STEPS",
	GET_ALL_PROBLEM_TYPS: "GET_ALL_PROBLEM_TYPS",
	CLEAR_GET_ALL_PROBLEM_TYPS: "CLEAR_GET_ALL_PROBLEM_TYPS",
	GET_ALL_CLP_PROBLEM_COPY: "GET_ALL_CLP_PROBLEM_COPY",
	CLEAR_GET_ALL_CLP_PROBLEM_COPY: "CLEAR_GET_ALL_CLP_PROBLEM_COPY",
	COPY_FROM_CLINICAL_PATHWAY_PROBLEMS: "COPY_FROM_CLINICAL_PATHWAY_PROBLEMS",
	CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS: "CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS",
	COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS: "COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS",
	CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS: "CLEAR_COPY_FROM_CLINICAL_PATHWAY_PROBLEMS_STATUS",
	CLNC_PATHWAY_PROBLEM_ORDER: "CLNC_PATHWAY_PROBLEM_ORDER",
	CLEAR_CLNC_PATHWAY_PROBLEM_ORDER: "CLEAR_CLNC_PATHWAY_PROBLEM_ORDER",
	GET_ALL_CLP_PROBLEM_REORDER: "GET_ALL_CLP_PROBLEM_REORDER",
	CLEAR_GET_ALL_CLP_PROBLEM_REORDER: "CLEAR_GET_ALL_CLP_PROBLEM_REORDER",
	LINK_CHECK_LIST: "LINK_CHECK_LIST",
	CLEAR_LINK_CHECK_LIST: "CLEAR_LINK_CHECK_LIST",
	GET_ALL_CHECK_LIST: "GET_ALL_CHECK_LIST",
	CLEAR_GET_ALL_CHECK_LIST: "CLEAR_GET_ALL_CHECK_LIST",
	UNLINK_CHECK_LIST: "UNLINK_CHECK_LIST",
	UNLINK_CHECK_LIST_COMPLETED: "UNLINK_CHECK_LIST_COMPLETED",
	REPLACED_BY_CHECK_LIST: "REPLACED_BY_CHECK_LIST",
	CLEAR_REPLACED_BY_CHECK_LIST: "CLEAR_REPLACED_BY_CHECK_LIST",
	DELETE_MODEL_PROBLEM_OBSERVER: "DELETE_MODEL_PROBLEM_OBSERVER",
	CLEAR_DELETE_MODEL_PROBLEM_OBSERVER: "CLEAR_DELETE_MODEL_PROBLEM_OBSERVER",
	GET_CLP_PROBLEM_LIST_FOR_COMPARISON: "GET_CLP_PROBLEM_LIST_FOR_COMPARISON",
	CLEAR_GET_CLP_PROBLEM_LIST_FOR_COMPARISON: "CLEAR_GET_CLP_PROBLEM_LIST_FOR_COMPARISON",
	UPDATE_LINKED_MODEL_RANK: "UPDATE_LINKED_MODEL_RANK",
	UPDATE_LINKED_MODEL_RANK_COMPLETED: "UPDATE_LINKED_MODEL_RANK_COMPLETED",
	UPDATE_LINKED_MODEL_RANK_STATUS: "UPDATE_LINKED_MODEL_RANK_STATUS",
	UPDATE_LINKED_MODEL_RANK_STATUS_COMPLETED: "UPDATE_LINKED_MODEL_RANK_STATUS_COMPLETED",
	// HEALTH-PATHWAY
	HEALTH_PATHWAY_GET_ALL: "HEALTH_PATHWAY_GET_ALL",
	CLEAR_HEALTH_PATHWAY_GET_ALL: "CLEAR_HEALTH_PATHWAY_GET_ALL",
	HEALTH_PATHWAY_GET_ONE: "HEALTH_PATHWAY_GET_ONE",
	CLEAR_HEALTH_PATHWAY_GET_ONE: "CLEAR_HEALTH_PATHWAY_GET_ONE",

	HEALTH_PATHWAY_GET_UPDATED_AT: "HEALTH_PATHWAY_GET_UPDATED_AT",
	CLEAR_HEALTH_PATHWAY_GET_UPDATED_AT: "CLEAR_HEALTH_PATHWAY_GET_UPDATED_AT",

	HEALTH_PATHWAY_UPDATE: "HEALTH_PATHWAY_UPDATE",
	CLEAR_HEALTH_PATHWAY_UPDATE: "CLEAR_HEALTH_PATHWAY_UPDATE",
	HEALTH_PATHWAY_CREATE: "HEALTH_PATHWAY_CREATE",
	CLEAR_HEALTH_PATHWAY_CREATE: "CLEAR_HEALTH_PATHWAY_CREATE",
	DELETE_HEALTH_PATHWAY: "DELETE_HEALTH_PATHWAY",
	DELETE_HEALTH_PATHWAY_COMPLETED: "DELETE_HEALTH_PATHWAY_COMPLETED",
	GET_ONE_HEALTH_PATHWAY_HOSPITAL: "GET_ONE_HEALTH_PATHWAY_HOSPITAL",
	CLEAR_GET_ONE_HEALTH_PATHWAY_HOSPITAL: "CLEAR_GET_ONE_HEALTH_PATHWAY_HOSPITAL",
	CHECK_HEALTH_PATHWAY_REPORT_STATUS: "CHECK_HEALTH_PATHWAY_REPORT_STATUS",
	CHECK_HEALTH_PATHWAY_REPORT_STATUS_COMPLETED: "CHECK_HEALTH_PATHWAY_REPORT_STATUS_COMPLETED",
	HEALTH_PATHWAY_EXPORT: "HEALTH_PATHWAY_EXPORT",
	HEALTH_PATHWAY_EXPORT_COMPLETED: "HEALTH_PATHWAY_EXPORT_COMPLETED",
	GET_LINKED_DATA: "GET_LINKED_DATA",
	CLEAR_GET_LINKED_DATA: "CLEAR_GET_LINKED_DATA",
	GENERATE_DIAGRAM_EXPORT_EXCEL: "GENERATE_DIAGRAM_EXPORT_EXCEL",
	CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL: "CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL",
	GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS: "GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS",
	CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS: "CLEAR_GENERATE_DIAGRAM_EXPORT_EXCEL_STATUS",
	GET_ALL_DIAGRAM_ADDITIONAL_INFO: "GET_ALL_DIAGRAM_ADDITIONAL_INFO",
	CLEAR_GET_ALL_DIAGRAM_ADDITIONAL_INFO: "CLEAR_GET_ALL_DIAGRAM_ADDITIONAL_INFO",
	GET_DIAGRAM_INFO: "GET_DIAGRAM_INFO",
	HEALTH_PATHWAY_GET_ONE_BY_CODE: "HEALTH_PATHWAY_GET_ONE_BY_CODE",
	CLEAR_HEALTH_PATHWAY_GET_ONE_BY_CODE: "CLEAR_HEALTH_PATHWAY_GET_ONE_BY_CODE",
	GET_ALL_DIAGRAM_SORTABLE_INFO: "GET_ALL_DIAGRAM_SORTABLE_INFO",
	CLEAR_GET_ALL_DIAGRAM_SORTABLE_INFO: "CLEAR_GET_ALL_DIAGRAM_SORTABLE_INFO",
	SAVE_ALL_DIAGRAM_SORTABLE_INFO: "SAVE_ALL_DIAGRAM_SORTABLE_INFO",
	CLEAR_SAVE_ALL_DIAGRAM_SORTABLE_INFO: "CLEAR_SAVE_ALL_DIAGRAM_SORTABLE_INFO",
	GET_ALL_DIAGRAM_SORTABLE_TASK_INFO: "GET_ALL_DIAGRAM_SORTABLE_TASK_INFO",
	CLEAR_GET_ALL_DIAGRAM_SORTABLE_TASK_INFO: "CLEAR_GET_ALL_DIAGRAM_SORTABLE_TASK_INFO",
	SAVE_SORTABLE_TASK_LIST: "SAVE_SORTABLE_TASK_LIST", 
	CLEAR_SAVE_SORTABLE_TASK_LIST: "CLEAR_SAVE_SORTABLE_TASK_LIST",
	GET_DIAGRAM_VERSIONS: "GET_DIAGRAM_VERSIONS",
	CLEAR_GET_DIAGRAM_VERSIONS: "CLEAR_GET_DIAGRAM_VERSIONS",
	GET_DIAGRAM_DETAILS: "GET_DIAGRAM_DETAILS",
	CLEAR_GET_DIAGRAM_DETAILS: "CLEAR_GET_DIAGRAM_DETAILS",
	DELETE_DIAGRAM_SUB_VERSIONS: "DELETE_DIAGRAM_SUB_VERSIONS",
	CLEAR_DELETE_DIAGRAM_SUB_VERSIONS: "CLEAR_DELETE_DIAGRAM_SUB_VERSIONS",
	CLEAR_DIAGRAM_SOFRT_UPDATED_FLAG: "CLEAR_DIAGRAM_SOFRT_UPDATED_FLAG",
	SET_SELECTED_TRAJECTORY: "SET_SELECTED_TRAJECTORY",
	// HEALTH-PATHWAY-DETAILS

	FILE_UPLOAD: "FILE_UPLOAD",
	CLEAR_FILE_UPLOAD: "CLEAR_FILE_UPLOAD",
	HEALTH_PATHWAY_REMARKS_GET: "HEALTH_PATHWAY_REMARKS_GET",
	CLEAR_HEALTH_PATHWAY_REMARKS_GET: "CLEAR_HEALTH_PATHWAY_REMARKS_GET",
	HEALTH_PATHWAY_DEFINITION_GET: "HEALTH_PATHWAY_DEFINITION_GET",
	CLEAR_HEALTH_PATHWAY_DEFINITION_GET: "CLEAR_HEALTH_PATHWAY_DEFINITION_GET",
	COPY_TO_HEALTH_PATHWAYS: "COPY_TO_HEALTH_PATHWAYS",
	CLEAR_COPY_TO_HEALTH_PATHWAYS: "CLEAR_COPY_TO_HEALTH_PATHWAYS",
	CHECK_COPY_TO_HEALTH_PATHWAY_STATUS: "CHECK_COPY_TO_HEALTH_PATHWAY_STATUS",
	CHECK_COPY_TO_HEALTH_PATHWAY_STATUS_COMPLETED: "CHECK_COPY_TO_HEALTH_PATHWAY_STATUS_COMPLETED",
	COPY_FROM_HEALTH_PATHWAY: "COPY_FROM_HEALTH_PATHWAY",
	CLEAR_COPY_FROM_HEALTH_PATHWAY: "CLEAR_COPY_FROM_HEALTH_PATHWAY",

	GET_DESCRIPTION: "GET_DESCRIPTION",
	CLEAR_GET_DESCRIPTION: "CLEAR_GET_DESCRIPTION",
	SAVE_DESCRIPTION: "SAVE_DESCRIPTION",
	CLEAR_SAVE_DESCRIPTION: "CLEAR_SAVE_DESCRIPTION",
	GET_DIAGRAM_DATA: "GET_DIAGRAM_DATA",
	GET_DIAGRAM_LOCK_VALIDATION: "GET_DIAGRAM_LOCK_VALIDATION",
	CLEAR_GET_DIAGRAM_LOCK_VALIDATION: "CLEAR_GET_DIAGRAM_LOCK_VALIDATION",
	GET_DIAGRAM_LOCK_REMOVE: "GET_DIAGRAM_LOCK_REMOVE",
	CLEAR_GET_DIAGRAM_DATA: "CLEAR_GET_DIAGRAM_DATA",
	SAVE_DIAGRAM_DATA: "SAVE_DIAGRAM_DATA",
	CLEAR_SAVE_DIAGRAM_DATA: "CLEAR_SAVE_DIAGRAM_DATA",
	SAVE_DIAGRAM_AS_IMAGE: "SAVE_DIAGRAM_AS_IMAGE_DATA",
	CLEAR_SAVE_DIAGRAM_AS_IMAGE: "CLEAR_SAVE_DIAGRAM_AS_IMAGE_DATA",
	SAVE_DIAGRAM_SETTINGS: "SAVE_DIAGRAM_SETTINGS",
	CLEAR_SAVE_DIAGRAM_SETTINGS: "CLEAR_SAVE_DIAGRAM_SETTINGS",
	GET_META_DIAGRAM_DATA: "GET_META_DIAGRAM_DATA",
	CLEAR_META_GET_DIAGRAM_DATA: "CLEAR_META_GET_DIAGRAM_DATA",
	COMMUNITY_SHARE_HEALTH_PATHWAY: "COMMUNITY_SHARE_HEALTH_PATHWAY",
	COMMUNITY_SHARE_HEALTH_PATHWAY_COMPLETED: "COMMUNITY_SHARE_HEALTH_PATHWAY_COMPLETED",
	GET_TRAJECTORY_INFORMATION: "GET_TRAJECTORY_INFORMATION",
	CLEAR_GET_TRAJECTORY_INFORMATION: "CLEAR_GET_TRAJECTORY_INFORMATION",
	GET_DIAGRAM_COPY_VALIDATION: "GET_DIAGRAM_COPY_VALIDATION",
	CLEAR_GET_DIAGRAM_COPY_VALIDATION: "CLEAR_GET_DIAGRAM_COPY_VALIDATION",

	//COMMUNITY-SECTION
	CREATE_COMMUNITY_SECTION: "CREATE_COMMUNITY_SECTION",
	CLEAR_CREATE_COMMUNITY_SECTION: "CLEAR_CREATE_COMMUNITY_SECTION",
	COMMUNITY_SECTION_GET_ALL: "COMMUNITY_SECTION_GET_ALL",
	CLEAR_COMMUNITY_SECTION_FETCH: "CLEAR_COMMUNITY_SECTION_FETCH",
	DELETE_COMMUNITY_SECTION: "DELETE_COMMUNITY_SECTION",
	CLEAR_DELETE_COMMUNITY_SECTION: "CLEAR_DELETE_COMMUNITY_SECTION",
	COMMUNITY_SECTION_GET_ONE: "COMMUNITY_SECTION_GET_ONE",
	CLEAR_COMMUNITY_SECTION_GET_ONE: "CLEAR_COMMUNITY_SECTION_GET_ONE",
	UPDATE_COMMUNITY_SECTION: "UPDATE_COMMUNITY_SECTION",
	UPDATE_COMMUNITY_SECTION_COMPLETED: "UPDATE_COMMUNITY_SECTION_COMPLETED",
	FILTER_CHANGE_COMMUNITY_SECTION: "FILTER_CHANGE_COMMUNITY_SECTION",

	//COMMUNITY-MEMBERS
	CREATE_COMMUNITY_MEMBERS: "CREATE_COMMUNITY_MEMBERS",
	CLEAR_CREATE_COMMUNITY_MEMBERS: "CLEAR_CREATE_COMMUNITY_MEMBERS",
	COMMUNITY_MEMBERS_GET_ALL: "COMMUNITY_MEMBERS_GET_ALL",
	CLEAR_COMMUNITY_MEMBERS_FETCH: "CLEAR_COMMUNITY_MEMBERS_FETCH",
	DELETE_COMMUNITY_MEMBERS: "DELETE_COMMUNITY_MEMBERS",
	CLEAR_DELETE_COMMUNITY_MEMBERS: "CLEAR_DELETE_COMMUNITY_MEMBERS",
	COMMUNITY_MEMBERS_GET_ONE: "COMMUNITY_MEMBERS_GET_ONE",
	CLEAR_COMMUNITY_MEMBERS_GET_ONE: "CLEAR_COMMUNITY_MEMBERS_GET_ONE",
	UPDATE_COMMUNITY_MEMBERS: "UPDATE_COMMUNITY_MEMBERS",
	UPDATE_COMMUNITY_MEMBERS_COMPLETED: "UPDATE_COMMUNITY_MEMBERS_COMPLETED",
	FILTER_CHANGE_COMMUNITY_MEMBERS: "FILTER_CHANGE_COMMUNITY_MEMBERS",

	//COMMUNITY-SITES
	CREATE_COMMUNITY_SITES: "CREATE_COMMUNITY_SITES",
	CLEAR_CREATE_COMMUNITY_SITES: "CLEAR_CREATE_COMMUNITY_SITES",
	COMMUNITY_SITES_GET_ALL: "COMMUNITY_SITES_GET_ALL",
	CLEAR_COMMUNITY_SITES_FETCH: "CLEAR_COMMUNITY_SITES_FETCH",
	DELETE_COMMUNITY_SITES: "DELETE_COMMUNITY_SITES",
	CLEAR_DELETE_COMMUNITY_SITES: "CLEAR_DELETE_COMMUNITY_SITES",
	COMMUNITY_SITES_GET_ONE: "COMMUNITY_SITES_GET_ONE",
	CLEAR_COMMUNITY_SITES_GET_ONE: "CLEAR_COMMUNITY_SITES_GET_ONE",
	UPDATE_COMMUNITY_SITES: "UPDATE_COMMUNITY_SITES",
	UPDATE_COMMUNITY_SITES_COMPLETED: "UPDATE_COMMUNITY_SITES_COMPLETED",
	FILTER_CHANGE_COMMUNITY_SITES: "FILTER_CHANGE_COMMUNITY_SITES",

	//CLP_BACKUP
	GENERATE_CLP_BACKUP: "GENERATE_CLP_BACKUP",
	GENERATE_CLP_BACKUP_COMPLETED: "GENERATE_CLP_BACKUP_COMPLETED"
};
