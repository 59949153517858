import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import I18, { i18Get }  from "../../../../../../../i18";
import { Dropdown, Menu, Button, Modal } from "antd";
import PropTypes from "prop-types";
import { CaretDownOutlined, CopyOutlined, DeploymentUnitOutlined, ShareAltOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import CommunityShare from "../community-share";
import CommunityInfo from "../community-info";
import locations from "../../../../../../../router/locations";
import utils  from "../../../../../../../utils/utils";

const COPY_EXPORT_TABS = ["Decision  Tree", "Pathways and cost", "Tasks"];

class TopDetailsSection extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isSettings: false,
			communityShare: false,
			communityInfo: false,
			isItSharedHealthPathway: false,
			tab: "Decision  Tree"
		};
	}

	formatText(value, text, isFirst) {
		return <span>{value && !isFirst ? ",  " : ""}{value ? <I18 tkey={text} /> : ""}{value ? ": " + value + "  " : ""}</span>;
	}

	copyToHealthPathways = () => {
		this.props.copyToHealthPathways();
	};

	onClickCommunityInfo = () => {
		this.setState({ communityInfo: true });
	};

	handleCancel() {
		const prevState = this.state;
		prevState.communityShare = false;
		prevState.communityInfo = false;
		this.setState(prevState);
	}
	onClickCommunityShare = () => {
		this.setState({ communityShare: true });
	};
	communityShareMenu = () => (
		<Menu>
			<Menu.Item onClick={() => this.onClickCommunityShare()} key="2">
				<span className="margin-right-10">
					<DeploymentUnitOutlined />
					<I18 tkey='Share to community' />
				</span>
			</Menu.Item>
		</Menu>
	);

	copyMenu = (selectedHospital) => (

		<Menu>
			{/* <Menu.Item onClick={() => this.copyToHealthPathways()} key="1">
				<span className="margin-right-10">
					<CopyOutlined />
					<I18 tkey='Copy To Health Pathways' />
				</span>
			</Menu.Item> */}
			<Menu.Item onClick={() => this.props.copyFromHealthPathway()} key="2">
				<span className="margin-right-10">
					<CopyOutlined />
					<I18 tkey='Copy From Health Pathway' />
				</span>
			</Menu.Item>
		</Menu>
	);

	exportMenu = () => (
		<Menu>
			{this.props.healthPathwayLinkedItems?.hpTab === "DECISION TREE" && <Menu.Item onClick={() => this.props.toggleExportExcel()} key="1">
				<span className="margin-right-10">
					<CopyOutlined />
					<I18 tkey='EXPORT EXCEL' />
				</span>
			</Menu.Item>}
			{!this.props.exportPdf ? <Menu.Item onClick={() => this.props.printDocument() } key="2">
				<span className="margin-right-10">
					<CopyOutlined />
					<I18 tkey='EXPORT PDF' />
				</span>
			</Menu.Item> : "" }
		</Menu>
	);

	visibleActivities = () => {

		if (!this.props.exportPdf && 
			((COPY_EXPORT_TABS.includes(this.state.tab) && 
			this.props.healthPathwayLinkedItems?.hpTab === "DECISION TREE" ) ||
			this.props.healthPathwayLinkedItems?.hpTab !== "DECISION TREE")) {
			return true;
		}

		return false;
	};

	visibleActivitiesForExport = () => {

		if (!this.props.exportPdf && 
			(COPY_EXPORT_TABS.includes(this.state.tab) && 
			this.props.healthPathwayLinkedItems?.hpTab === "DECISION TREE" )) {
			return true;
		}

		return false;
	};

	render() {
		if (this.state.isItSharedHealthPathway !== this.props.healthPathway.sharedCommunityCount)
		{
			this.setState({ isItSharedHealthPathway: this.props.healthPathway.sharedCommunityCount });
		}
		if (localStorage.getItem("HEALTHPATHWAY_TAB") && localStorage.getItem("HEALTHPATHWAY_TAB") !== this.state.tab) {
			this.setState({ tab: (localStorage.getItem("HEALTHPATHWAY_TAB")) });
		}
		return (
			<div className="col-md-12 padding-right-0">
				<div className="w_100 ellipsis d-flex justify-content-between">
					<div>
						<span className='intervention_name'>
							{this.props.healthPathway && this.props.healthPathway.name ?  ` ${this.props.healthPathway.hospitalCode ? this.props.healthPathway.hospitalCode : "MOM"} ${this.props.healthPathway.name}` : ""}
						</span>
						{/* <span className='intervention_speciality_name'>
							{this.props.healthPathway && this.props.healthPathway.specialityName && this.props.healthPathway.familyName ?
								`(${this.props.healthPathway.specialityName}, ${this.props.healthPathway.familyName})${this.props.doctorName ? `(${this.props.doctorName})` : ""}` : ""}
						</span> */}
						<div className="d-flex align-items-center">
							<div className="">
								<I18 tkey='Code' />:&nbsp;
								{this.props.healthPathway && this.props.healthPathway.code ? this.props.healthPathway.code : ""}&nbsp;
							</div>
							<span className='intervention_speciality_name font_12'>
								{this.props.healthPathway && this.props.healthPathway.specialityName && this.props.healthPathway.familyName ?
									`(${this.props.healthPathway.specialityName}, ${this.props.healthPathway.familyName}, ${this.props.healthPathway.clinicalPathway === "MEDICINE" ? i18Get("MEDICINE WITH ACTE", utils.getLanguage()) : this.props.healthPathway.clinicalPathway.replace(/_/g, " ")})${this.props.doctorName ? `(${this.props.doctorName})` : ""}`
									: ""}
							</span>
						</div>
					</div>
				</div>
				<div className="top_section_activity_bar">
					<div className="d-flex align-items-center">
						{ this.props.healthPathway.hospitalId && this.props.healthPathway.siteId ?
							<div className="mb-10 d-flex pblm_btn_container float_left margin-top-8">
								<div className="tool_tip margin-bottom-10" onClick={this.onClickCommunityInfo.bind(this)}>
									<InfoCircleTwoTone />
								</div>
							</div>
							: ""
						}
						<div className="d-flex align-items-center ">
							<div className='color_red'>
								<label >
									{`${i18Get("Server", utils.getLanguage())}: ${this.props.selectedHospital && this.props.selectedHospital.label ? this.props.selectedHospital.label : "Master"}`}
								</label>
							</div>
						</div>
						{/* <div >
							<I18 tkey='Health Pathway Code' />:&nbsp;
							{this.props.healthPathway && this.props.healthPathway.code ? this.props.healthPathway.code : ""}&nbsp;
						</div> */}
						{/* {!this.props.exportPdf && this.props.healthPathway.hospitalId ?
							<div className='pathway_hospital z_index_10_select' >
								<HospitalSelect
									className=""
									data={this.props.selectedHospital}
									hospitalChanged={this.props.hospitalChanged}
								/>
							</div> : ""} */}
						{!this.props.match.params.organization === !locations.tabLocal && this.props.healthPathway.hospitalId &&
						<div className={`d-flex align-items-center margin-left-10 ${!this.state.isItSharedHealthPathway ? "btn-group" : ""}`}>
							<Dropdown overlay={this.communityShareMenu()} trigger={["click"]} >
								<div className="mb-10 di float_left min-height-36">
									<Button type="button" className={`single-button with_border uppercase min_width_90 min-height-36 ${this.state.isItSharedHealthPathway ? "shared_community" : ""}`} loading={this.state.loading} disabled={false}>
										<span className={`color_blue ${this.state.isItSharedHealthPathway ? "color_green" : ""} font_size_12px share_pathway`}>
											<ShareAltOutlined />
											<span className='share_to_text'><I18 tkey={this.state.isItSharedHealthPathway ? "Share Again" : "Share To"} /></span>
										</span>
										<CaretDownOutlined />
									</Button>
								</div>
							</Dropdown>
						</div>
						}
					</div>
					<div>
						{this.visibleActivities() ?
							<Dropdown overlay={this.copyMenu(this.props.selectedHospital)} trigger={["click"]}>
								<div className="mb-10 di float_left">
									<Button type="button" className=" btn btn-outline-primary" disabled={!this.props.isReadOnly}
										loading={this.state.loading}>
										<I18 tkey="COPY" />{this.props.isReadOnly }
										<CaretDownOutlined />
									</Button>
								</div>
							</Dropdown> : ""
						}

						{this.visibleActivitiesForExport() ? <Dropdown overlay={this.exportMenu()} trigger={["click"]}>
							<div className="mb-10 ml-10 di float_left">
								<Button type="button" className=" btn btn-outline-primary"
									loading={this.state.loading}>
									<I18 tkey="EXPORT" />
									<CaretDownOutlined />
								</Button>
							</div>
						</Dropdown> : " "}  
					</div>
				</div>
				
				{this.state.communityShare ?
					<Modal
						visible={this.state.communityShare}
						className="cost_modal re_assign_modal"
						title={i18Get("Community Share", utils.getLanguage())}
						onCancel={this.handleCancel.bind(this)}
						wrapClassName="drag_modal"
						footer={null}
					>
						<CommunityShare reloadDetails= {this.props.reloadDetails} healthPathwayData={this.props.healthPathway} closeCommunityShare={this.handleCancel.bind(this)} />
					</Modal> : ""}

				{this.state.communityInfo ?
					<Modal
						centered
						visible={this.state.communityInfo}
						className=" re_assign_modal"
						title={i18Get(("Community Info"), utils.getLanguage())}
						onCancel={this.handleCancel.bind(this)}
						wrapClassName="drag_modal"
						footer={null}
						width={500}
					>
						<CommunityInfo
							healthPathwayData={this.props.healthPathway}
							closeCommunityShare={this.handleCancel.bind(this)}
							hospitalName={this.props.healthPathway.hospitalName}
						/>
					</Modal> : ""}
			</div>
		);
	}
}

TopDetailsSection.propTypes = {
	healthPathwayLinkedItems: PropTypes.object.isRequired
};

export default withRouter(connect(store => ({
	healthPathwayLinkedItems: store.healthPathwayLinkedItems
}))(TopDetailsSection));